.pagetop {
  position: fixed;
  bottom: rem(100);
  right: rem(40);
  opacity: 0;
  visibility: hidden;
  transition: $TRANSITION_BASE;
  width: rem(48);
  height: rem(56);
  padding: rem(8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: $Z_PAGETOP;
  background: rgba($COLOR_WHITE, 0.3);

  img {
    width: 100%;
  }

  &.is-active {
    opacity: 1;
    visibility: visible;

    @include pc {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.footer {
  background: $COLOR_WHITE;
  padding-bottom: rem(30);

  &-main {
    padding: rem(100) 0;

    &__row {
      display: flex;
    }

    &__col1 {
      width: rem(195);
      flex-shrink: 0;
    }

    &__col2 {
      flex-grow: 1;
    }
  }

  &-logo {
    max-width: 100%;
  }

  &-nav {
    text-align: center;
    margin-top: rem(30);

    &__item {
      margin-bottom: rem(10);
      line-height: 1;
    }

    a {
      display: inline-flex;
      font-family: $FONT_EN;
      @include fz(15, 24);
      font-weight: 700;
      letter-spacing: 0.1em;
      position: relative;
      padding: 0 rem(14);

      &::before {
        position: absolute;
        z-index: 1;
        bottom: -0.2rem;
        left: rem(14);
        transform: scaleX(0);
        transform-origin: right;
        content: "";
        width: calc(100% - 2.8rem);
        background: $COLOR_BLACK;
        height: 1px;
        transition: transform 0.3s ease;
      }

      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: rem(5);
        height: rem(8);
        content: "";
        z-index: 1;
        background: url("../img/common/ico_arrow_menu_black.svg") no-repeat left center / cover;
      }

      @include pc {
        &:hover {
          &::before {
            transform: scaleY(1);
            transform-origin: left;
          }
        }
      }
    }
  }

  &-copyright {
    padding: 0 rem(50);
    text-align: right;
    letter-spacing: 0.05em;
    @include fz(12, 16);

    p+p {
      margin-top: rem(10);
    }
  }
}

@include laptop {
  .pagetop {
    right: rem(20);
  }

  .footer-copyright {
    padding: 0 rem(35);
  }
}

@include tablet {
  .pagetop {
    right: rem(20);
  }

  .footer {
    &-main {
      &__row {
        // flex-wrap: wrap;
      }
    }

    .service-nav__item {
      width: rem(172);
      padding-left: rem(15);
    }

    &-copyright {
      padding: 0 rem(35);
      @include fz(10);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .footer {
    &-main {
      &__row {
        justify-content: center;
        gap: rem(50);
      }

      &__col2 {
        max-width: rem(364);
      }
    }

    .service-nav {
      &__list {
        row-gap: rem(30);
      }

      &__item {
        width: 50%;
        max-width: 50%;
      }
    }
  }
}

@include sp {
  .pagetop {
    bottom: rem(40);
    right: rem(40);
    width: rem(56);
    height: rem(60);
  }

  .footer {
    padding-bottom: rem(120);

    &-main {
      padding: rem(150) 0 rem(100);

      &__row {
        justify-content: center;
      }

      &__col1 {
        width: rem(317);
      }

      &__col2 {
        display: none;
      }
    }

    &-nav {
      margin-top: rem(50);

      &__item {
        margin-bottom: rem(20);
      }

      a {
        @include fz(24.4, 42.7);
        padding: 0 rem(22);

        &::after {
          width: rem(10);
          height: rem(16);
        }
      }
    }

    &-copyright {
      padding: 0 rem(50);
      text-align: left;
      @include fz(20, 34);

      p+p {
        margin-top: rem(10);
      }
    }
  }
}

@include sp_lg {
  .pagetop {
    bottom: rem(30);
    right: rem(30);
    width: rem(36);
    height: rem(42);
  }

  .footer {
    text-align: center;
    padding-bottom: rem(60);

    &-main {
      padding: rem(100) 0 rem(60);

      &__col1 {
        width: rem(280);
      }
    }

    &-nav {
      margin-top: rem(30);

      &__item {
        margin-bottom: rem(10);
      }

      a {
        @include fz(22);
        padding: 0 rem(16);

        &::after {
          width: rem(7);
          height: rem(11.2);
        }
      }
    }

    &-copyright {
      padding: 0 rem(30);
      @include fz(13.6);
      display: inline-block;
      text-align: left;
    }
  }
}
