.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: $Z_HEADER;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $COLOR_WHITE;
    opacity: 0;
    transform: translateY(-101%);
    transition: 0.2s;
    z-index: 1;
  }

  &__inner {
    padding: 0 rem(60);
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-logo {
    width: max-content;
    flex-shrink: 0;
    height: rem(100);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(30);
    position: relative;
    z-index: $Z_HEADER;

    &__link {
      display: inline-flex;
      width: rem(195);
    }

    &__slg {
      @include fz(12, 21);
      font-weight: 400;
      letter-spacing: 0.05em;
    }
  }

  &-main {
    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(50);
    }
  }

  &-nav {
    text-align: center;
    position: relative;
    z-index: 99;

    &__list {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(50);
      gap: rem(46);
    }

    &__item {
      line-height: 1;

      &.is-service {
        padding-right: rem(16);
        position: relative;

        &::before {
          position: absolute;
          content: "";
          z-index: 3;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: rem(11.2);
          height: rem(7.2);
          background: url("../img/common/ico_arrow_down01.svg") no-repeat center center / 100% 100%;
        }
      }
    }

    &-sub {
      .service-nav {
        padding-right: rem(32);
      }

      @media screen and (min-width: 1101px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        visibility: hidden;
        opacity: 0;
        transition: $TRANSITION_BASE;
        z-index: 1;
        padding: rem(100) rem(60) rem(60);

        &::before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $COLOR_WHITE;
          transition: $TRANSITION_BASE;
          opacity: 0;
          visibility: hidden;
          z-index: 1;
          transform: translateY(-101%);
        }

        &__inner {
          position: relative;
          transition: $TRANSITION_BASE;
          transition-delay: 0.2s;
          opacity: 0;
          visibility: hidden;
          z-index: 2;
        }

        &.is-open {
          visibility: visible;
          opacity: 1;

          &::before {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
          }

          .header-nav-sub__inner {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    &__link {
      position: relative;
      z-index: 3;
      display: inline-flex;
      font-family: $FONT_EN;
      @include fz(15, 24);
      font-weight: 700;
      letter-spacing: 0.1em;

      &::before {
        position: absolute;
        z-index: 1;
        bottom: -0.2rem;
        left: 0;
        transform: scaleX(0);
        transform-origin: right;
        content: "";
        width: 100%;
        background: $COLOR_BLACK;
        height: 1px;
        transition: transform 0.3s ease;
      }

      @include pc {
        &:hover {
          &::before {
            transform: scaleY(1);
            transform-origin: left;
          }
        }
      }
    }
  }

  &-tel {
    font-family: $FONT_EN;
    @include fz(22, 24);
    font-weight: 700;
    letter-spacing: 0.05em;
    position: relative;
    z-index: $Z_HEADER;

    &--tablet {
      display: none;
    }
  }

  .hamburger-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: rem(45);
    height: rem(36);
    cursor: pointer;
    position: fixed;
    z-index: $Z_HDG;
    top: rem(32);
    right: rem(30);
    display: none;

    span {
      background: $COLOR_BLACK;
      width: 100%;
      height: rem(4);
      height: rem(5);
      transition: 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
      transition: $TRANSITION_BASE;

      &:nth-child(3) {
        width: rem(24);
        width: 54%;
      }
    }

    &.is-active {
      span {
        background: $COLOR_WHITE;

        &:nth-child(1) {
          transform: rotateZ(45deg) translate(#{rem(11)}, #{rem(11)});
        }

        &:nth-child(2) {
          transform: rotateZ(-45deg);
        }

        &:nth-child(3) {
          opacity: 0;
          transform: translateX(100%);
        }
      }
    }
  }

  &.is-sticky {
    &::before {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

@include laptop {
  .header {
    &__inner {
      padding: 0 vw_md(40) 0;
    }

    &-logo {
      gap: vw_md(30);
    }

    &-main {
      &__inner {
        gap: vw_md(50);
      }
    }

    &-nav {
      &__list {
        gap: vw_md(40);
      }

      &-sub {
        padding-right: vw_md(40);

        .service-nav {
          padding-right: 0;
        }
      }
    }
  }
}

@include laptop_sm {
  .header {
    &__inner {
      padding: 0 vw_md(28) 0;
    }

    &-logo {
      gap: vw_md(20);
    }

    &-main {
      &__inner {
        gap: vw_md(30);
      }
    }

    &-nav {
      &__list {
        gap: vw_md(20);
      }

      &-sub {
        padding-right: vw_md(30);
      }
    }
  }
}

@include tablet_lg_max {
  .header {
    &__inner {
      padding: 0 vw_sm(30);
    }

    &__row {
      gap: rem(15);
    }

    &-logo {
      gap: rem(30);

      &__link {
        width: rem(195);
      }

      &__slg {
        @include fz(12, 21);
      }
    }

    &-main-sp {
      display: block;
    }

    &-tel {
      &--tablet {
        display: block;
        margin-right: rem(80);
      }
    }

    &-main {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100dvh;
      height: calc(var(--vh, 1vh) * 100);
      max-height: 100dvh;
      max-height: calc(var(--vh, 1vh) * 100);
      gap: rem(50);
      background: $COLOR_BLACK;
      color: $COLOR_WHITE;
      z-index: $Z_HEADER_MODAL;
      padding: rem(100) 0 rem(60);
      overflow-y: auto;
      visibility: hidden;
      opacity: 0;
      transform: scale(1.4);
      transition: $TRANSITION_BASE;

      &__inner {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
      }

      .header-tel {
        display: none;
      }
    }

    &-nav {
      text-align: left;
      width: 100%;
      max-width: rem(709);

      &__list {
        display: block;
      }

      &__item {
        padding-bottom: rem(10);

        &.is-service {
          padding-right: 0;

          &::before {
            content: none;
          }
        }
      }

      &-sub {
        padding: rem(30) 0 rem(40);

        &::before {
          content: none;
        }

        &__inner {
          margin-left: rem(-27);
        }

        .service-nav {
          &__head {
            a {
              background: $COLOR_WHITE;
              color: $COLOR_BLACK;

              &::after {
                background-image: url("../img/common/ico_arrow_menu_black.svg");
              }
            }
          }
        }
      }

      &__link {
        @include fz(30, 51);
        padding-right: rem(60);
        position: relative;

        &::after {
          position: absolute;
          content: "";
          z-index: 1;
          background: url("../img/common/ico_arrow_btn.svg") no-repeat bottom right / 100% 100%;
          width: rem(45);
          height: rem(12);
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }

        @media screen and (min-width: 768px) {
          &:hover {
            &::after {
              animation-name: arrow-btn;
              animation-duration: 0.4s;
              animation-timing-function: $TRANSITION_CB;
            }
          }
        }
      }
    }

    .hamburger-btn {
      display: flex;
    }

    &.is-open {
      .header-main {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
  .header {
    &-main {
      padding-top: rem(40);

      .header-tel {
        display: block;
        width: 100%;
        max-width: rem(709);

        a {
          font-size: 140%;
        }
      }
    }

    &-tel {
      &--tablet {
        display: none;
      }
    }
  }
}

@include sp {
  .header {
    &__inner {
      padding: 0 vw(30);
    }

    &-logo {
      height: vw(120);
      gap: vw(20);

      &__link {
        width: vw(264);
      }

      &__slg {
        @include fz(18, 24);
      }
    }

    &-main {
      padding: rem(30) 0 rem(60);

      .header-tel {
        display: block;
        width: 100%;
        max-width: vw(594);
      }
    }

    &-nav {
      max-width: vw(594);

      &__item {
        padding-bottom: rem(22);
      }

      &-sub {
        padding: vw(50) 0 vw(50);

        .service-nav {
          padding-right: 0;
        }

        &__inner {
          margin-left: 0;
        }
      }

      &__link {
        @include fz(40, 70);
        padding-right: rem(79);

        &::after {
          width: rem(61);
          height: rem(16);
        }
      }
    }

    &-tel {
      @include fz(46, 72);

      &--tablet {
        display: none;
      }
    }

    .hamburger-btn {
      width: vw(62);
      height: vw(50);
      top: vw(35);
      right: vw(34);

      span {
        height: vw(6);

        &:nth-child(3) {
          width: vw(34);
        }
      }

      &.is-active {
        span {
          &:nth-child(1) {
            transform: rotateZ(45deg) translate(#{vw(15.5)}, #{vw(15.5)});
          }
        }
      }
    }
  }
}

@include sp_lg {
  .header {
    &__inner {
      padding: 0 vw(30);
    }

    &-logo {
      height: vw(90);
      gap: vw(20);

      &__link {
        width: vw(220);
      }

      &__slg {
        @include fz(16);
      }
    }

    &-main {
      padding: rem(18) 0 rem(30);

      &__inner {
        gap: vw(30);
      }
    }

    &-nav {
      padding-right: rem(30);

      &__item {
        padding-bottom: rem(12);
      }

      &-sub {
        padding: vw(25) 0;
      }

      &__link {
        @include fz(27);
        padding-right: rem(55);

        &::after {
          width: rem(40);
          height: rem(10.5);
        }
      }
    }

    &-tel {
      @include fz(30);
    }

    .hamburger-btn {
      width: vw(50);
      height: vw(38);
      top: vw(25);
      right: vw(30);

      span {
        height: vw(4);

        &:nth-child(3) {
          width: vw(28);
        }
      }

      &.is-active {
        span {
          &:nth-child(1) {
            transform: rotateZ(45deg) translate(#{vw(12)}, #{vw(12)});
          }
        }
      }
    }
  }
}