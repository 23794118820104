.p-company-name {
  margin-top: rem(25);
  width: rem(205);
  margin-left: auto;

  @include sp {
    margin-top: rem(50);
    width: rem(330);
  }

  @include sp_lg {
    margin-top: rem(30);
    width: rem(220);
  }
}

.p-company-lead {
  text-align: left;
}

.p-company-info {
  margin: rem(50) 0;

  dl {
    display: flex;
    margin-top: rem(5);
    @include fz(14, 28);

    dt {
      background: #ececec;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: rem(217);
      font-weight: 700;
      padding-top: rem(4);
      padding-bottom: rem(4);
    }

    dd {
      padding-top: rem(4);
      padding-bottom: rem(4);
      padding-left: rem(35);
      flex-grow: 1;
      display: flex;
      align-items: center;
      font-weight: 400;
    }
  }

  @include sp {
    margin: rem(80) 0;

    dl {
      flex-direction: column;
      margin-top: rem(20);
      @include fz(24, 40);

      dt {
        display: block;
        width: 100%;
        padding: rem(10) rem(25);
      }

      dd {
        padding: rem(20) rem(25);
        display: block;
      }
    }
  }

  @include sp_lg {
    margin: rem(50) 0;

    dl {
      margin-top: rem(10);
      @include fz(16, 28);

      dt {
        padding: rem(5) rem(15);
      }

      dd {
        padding: rem(10) rem(15);
      }
    }
  }
}