// Spacing
@for $i from 0 through 20 {
  .mt#{$i * 5} {
    margin-top: 0.5rem * $i !important;
  }

  .mr#{$i * 5} {
    margin-right: 0.5rem * $i !important;
  }

  .mb#{$i * 5} {
    margin-bottom: 0.5rem * $i !important;
  }

  .ml#{$i * 5} {
    margin-left: 0.5rem * $i !important;
  }

  .pt#{$i * 5} {
    padding-top: 0.5rem * $i !important;
  }

  .pr#{$i * 5} {
    padding-right: 0.5rem * $i !important;
  }

  .pb#{$i * 5} {
    padding-bottom: 0.5rem * $i !important;
  }

  .pl#{$i * 5} {
    padding-left: 0.5rem * $i !important;
  }
}

@include pc {
  @for $i from 0 through 20 {
    .pc-mt#{$i * 5} {
      margin-top: 0.5rem * $i !important;
    }

    .pc-mr#{$i * 5} {
      margin-right: 0.5rem * $i !important;
    }

    .pc-mb#{$i * 5} {
      margin-bottom: 0.5rem * $i !important;
    }

    .pc-ml#{$i * 5} {
      margin-left: 0.5rem * $i !important;
    }

    .pc-pt#{$i * 5} {
      padding-top: 0.5rem * $i !important;
    }

    .pc-pr#{$i * 5} {
      padding-right: 0.5rem * $i !important;
    }

    .pc-pb#{$i * 5} {
      padding-bottom: 0.5rem * $i !important;
    }

    .pc-pl#{$i * 5} {
      padding-left: 0.5rem * $i !important;
    }
  }
}

@include sp {
  @for $i from 0 through 20 {
    .sp-mt#{$i * 5} {
      margin-top: 0.5rem * $i !important;
    }

    .sp-mr#{$i * 5} {
      margin-right: 0.5rem * $i !important;
    }

    .sp-mb#{$i * 5} {
      margin-bottom: 0.5rem * $i !important;
    }

    .sp-ml#{$i * 5} {
      margin-left: 0.5rem * $i !important;
    }

    .sp-pt#{$i * 5} {
      padding-top: 0.5rem * $i !important;
    }

    .sp-pr#{$i * 5} {
      padding-right: 0.5rem * $i !important;
    }

    .sp-pb#{$i * 5} {
      padding-bottom: 0.5rem * $i !important;
    }

    .sp-pl#{$i * 5} {
      padding-left: 0.5rem * $i !important;
    }
  }
}

@for $i from 0 through 100 {
  .col-#{$i}p {
    width: 1% * $i;
  }
}

@include sp() {
  @for $i from 0 through 100 {
    .col-#{$i}p-sp {
      width: 1% * $i;
    }
  }
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

@include pc {
  .align-center-pc {
    text-align: center;
  }

  .align-right-pc {
    text-align: right;
  }

  .align-left-pc {
    text-align: left;
  }
}

@include sp {
  .align-center-sp {
    text-align: center;
  }

  .align-right-sp {
    text-align: right;
  }

  .align-left-sp {
    text-align: left;
  }
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.fw-black {
  font-weight: 900;
}

.fw-bold,
.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-normal,
.fw-400 {
  font-weight: 400;
}

.fw-light,
.fw-300 {
  font-weight: 300;
}

.img-fit {
  font-family: "object-fit: cover;";
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.d-ib {
  display: inline-block;
}

.d-if {
  display: inline-flex;
}

.d-b {
  display: block;
}

.d-f {
  display: flex;
}