.c-content-page {
  padding-bottom: rem(140);

  @include sp {
    padding-bottom: rem(200);
  }

  @include sp_lg {
    padding-bottom: rem(140);
  }
}

.section-area {
  max-width: rem(980);
  margin: 0 auto;
  padding-top: rem(80);

  &__ttl {
    padding: rem(2) rem(25);
    @include fz(16, 26);
    color: #fff;
    font-weight: 700;
    margin-bottom: rem(30);
    background: #000;
  }

  &__ctn {
    padding: 0 rem(15);
  }

  @include sp {
    padding-top: rem(160);

    &__ttl {
      padding: rem(10) rem(25);
      @include fz(31, 40);
      margin-bottom: rem(40);
    }

    &__ctn {
      padding: 0;
    }
  }

  @include sp_lg {
    padding-top: rem(110);

    &__ttl {
      padding: rem(4) rem(15);
      @include fz(18, 30);
      margin-bottom: rem(30);
    }

    &__ctn {
      padding: 0;
    }
  }
}
