.c-btn {
  position: relative;
  display: inline-flex;
  min-width: rem(290);
  background: $COLOR_BLACK;
  background: linear-gradient(to right, $COLOR_BLACK 0%, $COLOR_BLACK2 100%);
  color: $COLOR_WHITE;
  font-weight: 700;
  @include fz(17, 29);
  padding: rem(10) rem(30) rem(10) rem(35);
  transition: $TRANSITION_BASE;

  &__txt {
    position: relative;
    padding-right: rem(45);
    display: block;
    width: 100%;
    text-align: left;

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      content: "";
      width: rem(37);
      height: rem(10);
      z-index: 2;
      background: url("../img/common/ico_arrow_btn.svg") no-repeat right bottom / auto 100%;
      transform: translateY(-50%);

      @include pc {
        a:hover & {
          animation-name: arrow-btn;
          animation-duration: 0.4s;
          animation-timing-function: $TRANSITION_CB;
        }
      }
    }
  }

  @include pc {

    &:hover,
    a:hover & {
      opacity: 1;
    }
  }
}

@include sp {
  .c-btn {
    min-width: rem(480);
    @include fz(28, 41);
    padding: rem(20) rem(50) rem(20) rem(58);

    &__txt {
      padding-right: rem(65);

      &::after {
        width: rem(61);
        height: rem(16);
      }
    }
  }
}

@include sp_lg {
  .c-btn {
    min-width: rem(310);
    @include fz(18);
    padding: rem(15) rem(35) rem(15) rem(40);

    &__txt {
      padding-right: rem(54);

      &::after {
        width: rem(40);
        height: rem(10.5);
      }
    }
  }
}
