.p-contact {
  .contact-tel {
    margin: rem(50) 0 rem(60);

    dl {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      gap: 1.5em;

      dt {
        @include fz(18, 40);
        color: $COLOR_BLACK3;
      }

      dd {
        @include fz(31, 40);
        font-family: $FONT_EN;
        letter-spacing: 0.05em;
      }
    }
  }

  .contact-copy {
    font-weight: 700;
  }

  .contact-form {
    --bg-label: #000;
    --color-label: #fff;
    --bg-input: #fff;
    --border-input: #878787;
    margin-top: rem(30);

    .entry-form__error {
      padding: 2rem;
      background: #fff;
      color: #E50011;
      font-weight: 500;
      border: 2px solid #E50011;
      margin-bottom: rem(40);

      .error-ttl {
        font-size: 120%;
        margin-left: -0.5em;
        margin-bottom: rem(10);
      }

      .error-box {
        ul {
          margin-left: 1.2em;
        }

        li {
          padding: rem(5) 0;
          list-style: disc;
          font-size: 100%;
          font-weight: 500;
          line-height: 1.5;

          em {
            font-style: normal;
          }
        }
      }
    }

    .form-ctrl {
      display: flex;
      gap: rem(6);
      margin-top: rem(5);

      .ipt {
        width: 100%;
        padding: rem(6) rem(15);
        border: 1px solid var(--border-input);
        background: var(--bg-input);
        color: $COLOR_BLACK3;
        border-radius: 0 !important;
        @include fz(14, 26);

        &.min-size {
          width: rem(100);
          text-align: center;
        }

        &.ipt-textarea {
          min-height: rem(222);
        }
      }

      textarea {
        display: block;
        resize: none;
      }

      .error {
        color: #E50011;
        font-size: 75%;
        margin-top: rem(3);
      }

      span.ipt {
        display: inline-block;
      }

      &__lbl {
        width: rem(217);
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-label);
        background: var(--bg-label);
        @include fz(14, 24.5);
        font-weight: 700;
        min-height: rem(40);
        gap: rem(3);

        .u-requỉed {
          vertical-align: text-top;
          font-size: 71%;
          margin-top: rem(-8);
        }
      }

      &__ctn {
        flex-grow: 1;

        &.is-error .ipt {
          border-color: #E50011;
        }
      }
    }

    &__btn {
      margin-top: rem(80);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(50);

      &>div {
        width: rem(360);
        max-width: calc(50% - 2.5rem);
      }
    }

    #contact-confirm {
      --bg-label: #dbdbdb;
      --color-label: #000;
      --bg-input: #ededed;
      --border-input: #ededed;
    }
  }

  .contact-btn {
    width: 100%;
    max-width: rem(360);
    color: #fff;
    background: #000;
    text-align: center;
    display: block;
    font-weight: 700;
    @include fz(19, 36);
    padding: rem(10) rem(15);
    border: 2px solid #000;
    transition: $TRANSITION_BASE;

    &.btn-back {
      background: #898989;
      border-color: #898989;
    }

    @include pc {
      &:hover {
        color: #000;
        background: #fff;
      }
    }
  }

  .contact-thanks {
    max-width: rem(670);
    margin: 0 auto;
  }

  .thanks-ttl {
    @include fz(28, 40);
    font-weight: 700;
    margin-bottom: rem(30);
  }

  @include sp {
    .contact-tel {
      margin: rem(100) 0 rem(120);

      dl {
        flex-direction: column;
        gap: 0;

        dt {
          @include fz(31);
        }

        dd {
          @include fz(56);
        }
      }
    }

    .contact-copy {
      color: $COLOR_BLACK3;
    }

    .contact-form {
      margin-top: rem(45);

      .entry-form__error {
        font-size: rem(22);
        padding: rem(30);
        border-width: 1px;
        margin-bottom: rem(40);

        .error-ttl {
          margin-bottom: rem(15);
        }
      }

      .form-ctrl {
        flex-direction: column;
        gap: rem(16);
        margin-top: rem(40);

        .ipt {
          width: 100%;
          padding: rem(15) rem(25);
          @include fz(24);

          &.min-size {
            width: rem(150);
          }

          &.ipt-textarea {
            min-height: rem(195);
          }
        }

        .error {
          margin-top: rem(6);
        }

        &__lbl {
          display: block;
          padding: rem(15) rem(25);
          width: 100%;
          @include fz(24);
          min-height: rem(72);

          .u-requỉed {
            display: inline-block;
          }
        }
      }

      &__btn {
        margin-top: rem(90);
        gap: rem(26);

        &>div {
          width: rem(580);
          max-width: calc(50% - 1.3rem);
        }
      }
    }

    .contact-btn {
      width: 100%;
      max-width: rem(580);
      @include fz(30, 56);
      padding: rem(25);
    }

    .thanks-ttl {
      @include fz(42, 60);
      margin-bottom: rem(50);
    }
  }

  @include sp_lg {
    .contact-tel {
      margin: rem(50) 0 rem(60);

      dl {
        dt {
          @include fz(17);
        }

        dd {
          @include fz(31);
        }
      }
    }

    .contact-form {
      margin-top: rem(30);

      .entry-form__error {
        font-size: rem(16);
        padding: rem(20);
        margin-bottom: rem(30);

        .error-ttl {
          margin-bottom: rem(10);
        }
      }

      .form-ctrl {
        gap: rem(10);
        margin-top: rem(20);

        .ipt {
          padding: rem(15) rem(20);
          @include fz(18);

          &.min-size {
            width: rem(120);
          }
        }

        .error {
          margin-top: rem(6);
        }

        &__lbl {
          padding: rem(15) rem(20);
          @include fz(18);
          min-height: rem(50);
        }
      }

      &__btn {
        margin-top: rem(80);
        gap: rem(26);
      }
    }

    .contact-btn {
      max-width: rem(420);
      @include fz(24);
      padding: rem(15);
    }

    .thanks-ttl {
      @include fz(32);
      margin-bottom: rem(30);
    }
  }
}