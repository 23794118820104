.p-works-ttl {
  font-family: $FONT_EN;
  text-align: center;
  margin-bottom: rem(60);
  @include fz(53, 60);
  font-weight: 700;
  letter-spacing: 0.1em;

  @include sp {
    margin-bottom: rem(80);
  }

  @include sp_lg {
    margin-bottom: rem(60);
  }
}

.p-works-list {
  margin-top: rem(50);
  display: grid;
  gap: rem(50) rem(28);
  grid-template-columns: repeat(2, 1fr);

  .thumb {
    display: block;
    padding: rem(7);
    background: $COLOR_GRAY;

    img {
      object-fit: cover;
      object-position: top center;
      width: 100%;
      height: 100%;
    }
  }

  &.list-01 {
    .thumb {
      aspect-ratio: 321/233;
    }
  }

  &.list-02 {
    .thumb {
      aspect-ratio: 321/448;
    }
  }

  .desc {
    display: block;
    margin-top: rem(6);
    @include fz(22, 38.5);
    font-weight: 400;

    h4 {
      font-weight: 700;
    }
  }

  @include sp_lg {
    gap: rem(40) rem(26);
    margin-top: rem(35);

    .desc {
      @include fz(18);
    }
  }

  @include pc {
    gap: rem(36) rem(16);
    margin-top: rem(35);
    margin-left: rem(-15);
    margin-right: rem(-15);

    .thumb {
      padding: rem(4);
      transition: $TRANSITION_BASE;

      &:hover {
        background: $COLOR_BLACK3;
        opacity: 1;
      }
    }

    &.list-01 {
      grid-template-columns: repeat(4, 1fr);
    }

    &.list-02 {
      grid-template-columns: repeat(5, 1fr);
    }

    .desc {
      margin-top: rem(10);
      @include fz(13, 22.75);

      a {
        &:hover {
          text-decoration: underline;
          opacity: 0.85;
        }
      }
    }
  }

  @include tablet {
    &.list-01 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.list-02 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
