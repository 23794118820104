@charset "UTF-8";
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-Bold.woff2") format("woff2"), url("../fonts/DINPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
:root {
  --el-delay: 0s;
  --animate-delay: 0s;
  --animate-duration: 1.5s;
}

@keyframes arrow-btn {
  0%, to {
    transform: translate(0, -50%);
    opacity: 1;
  }
  59% {
    opacity: 0;
  }
  49.9% {
    transform: translate(50%, -50%);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, -50%);
    opacity: 0;
  }
}
@keyframes marquee {
  0%, 100% {
    opacity: 1;
    transform: translateX(0%);
  }
  99.995% {
    opacity: 1;
    transform: translateX(-100%);
  }
  99.996% {
    opacity: 0;
    transform: translateX(-100%);
  }
}
@keyframes loader-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes scroll_gallery {
  0% {
    background-position-x: 0;
  }
  to {
    background-position-x: -189rem;
  }
}
@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }
}
@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes slideRightUp {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  30% {
    opacity: 1;
    transform: translate(9rem, -9rem);
  }
  90% {
    opacity: 0;
    transform: translate(27rem, -27rem);
  }
  100% {
    opacity: 0;
    transform: translate(30rem, -30rem);
  }
}
@keyframes slideRightUpH {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  30% {
    opacity: 1;
    transform: translate(4.5rem, -4.5rem);
  }
  90% {
    opacity: 0;
    transform: translate(13.5rem, -13.5rem);
  }
  100% {
    opacity: 0;
    transform: translate(15rem, -15rem);
  }
}
@keyframes slideLeftDown {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  30% {
    opacity: 1;
    transform: translate(-9rem, 9rem);
  }
  90% {
    opacity: 0;
    transform: translate(-27rem, 27rem);
  }
  100% {
    opacity: 0;
    transform: translate(-30rem, 30rem);
  }
}
@keyframes slideLeftDownH {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  30% {
    opacity: 1;
    transform: translate(-4.5rem, 4.5rem);
  }
  90% {
    opacity: 0;
    transform: translate(-13.5rem, 13.5rem);
  }
  100% {
    opacity: 0;
    transform: translate(-15rem, 15rem);
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.mt0 {
  margin-top: 0rem !important;
}

.mr0 {
  margin-right: 0rem !important;
}

.mb0 {
  margin-bottom: 0rem !important;
}

.ml0 {
  margin-left: 0rem !important;
}

.pt0 {
  padding-top: 0rem !important;
}

.pr0 {
  padding-right: 0rem !important;
}

.pb0 {
  padding-bottom: 0rem !important;
}

.pl0 {
  padding-left: 0rem !important;
}

.mt5 {
  margin-top: 0.5rem !important;
}

.mr5 {
  margin-right: 0.5rem !important;
}

.mb5 {
  margin-bottom: 0.5rem !important;
}

.ml5 {
  margin-left: 0.5rem !important;
}

.pt5 {
  padding-top: 0.5rem !important;
}

.pr5 {
  padding-right: 0.5rem !important;
}

.pb5 {
  padding-bottom: 0.5rem !important;
}

.pl5 {
  padding-left: 0.5rem !important;
}

.mt10 {
  margin-top: 1rem !important;
}

.mr10 {
  margin-right: 1rem !important;
}

.mb10 {
  margin-bottom: 1rem !important;
}

.ml10 {
  margin-left: 1rem !important;
}

.pt10 {
  padding-top: 1rem !important;
}

.pr10 {
  padding-right: 1rem !important;
}

.pb10 {
  padding-bottom: 1rem !important;
}

.pl10 {
  padding-left: 1rem !important;
}

.mt15 {
  margin-top: 1.5rem !important;
}

.mr15 {
  margin-right: 1.5rem !important;
}

.mb15 {
  margin-bottom: 1.5rem !important;
}

.ml15 {
  margin-left: 1.5rem !important;
}

.pt15 {
  padding-top: 1.5rem !important;
}

.pr15 {
  padding-right: 1.5rem !important;
}

.pb15 {
  padding-bottom: 1.5rem !important;
}

.pl15 {
  padding-left: 1.5rem !important;
}

.mt20 {
  margin-top: 2rem !important;
}

.mr20 {
  margin-right: 2rem !important;
}

.mb20 {
  margin-bottom: 2rem !important;
}

.ml20 {
  margin-left: 2rem !important;
}

.pt20 {
  padding-top: 2rem !important;
}

.pr20 {
  padding-right: 2rem !important;
}

.pb20 {
  padding-bottom: 2rem !important;
}

.pl20 {
  padding-left: 2rem !important;
}

.mt25 {
  margin-top: 2.5rem !important;
}

.mr25 {
  margin-right: 2.5rem !important;
}

.mb25 {
  margin-bottom: 2.5rem !important;
}

.ml25 {
  margin-left: 2.5rem !important;
}

.pt25 {
  padding-top: 2.5rem !important;
}

.pr25 {
  padding-right: 2.5rem !important;
}

.pb25 {
  padding-bottom: 2.5rem !important;
}

.pl25 {
  padding-left: 2.5rem !important;
}

.mt30 {
  margin-top: 3rem !important;
}

.mr30 {
  margin-right: 3rem !important;
}

.mb30 {
  margin-bottom: 3rem !important;
}

.ml30 {
  margin-left: 3rem !important;
}

.pt30 {
  padding-top: 3rem !important;
}

.pr30 {
  padding-right: 3rem !important;
}

.pb30 {
  padding-bottom: 3rem !important;
}

.pl30 {
  padding-left: 3rem !important;
}

.mt35 {
  margin-top: 3.5rem !important;
}

.mr35 {
  margin-right: 3.5rem !important;
}

.mb35 {
  margin-bottom: 3.5rem !important;
}

.ml35 {
  margin-left: 3.5rem !important;
}

.pt35 {
  padding-top: 3.5rem !important;
}

.pr35 {
  padding-right: 3.5rem !important;
}

.pb35 {
  padding-bottom: 3.5rem !important;
}

.pl35 {
  padding-left: 3.5rem !important;
}

.mt40 {
  margin-top: 4rem !important;
}

.mr40 {
  margin-right: 4rem !important;
}

.mb40 {
  margin-bottom: 4rem !important;
}

.ml40 {
  margin-left: 4rem !important;
}

.pt40 {
  padding-top: 4rem !important;
}

.pr40 {
  padding-right: 4rem !important;
}

.pb40 {
  padding-bottom: 4rem !important;
}

.pl40 {
  padding-left: 4rem !important;
}

.mt45 {
  margin-top: 4.5rem !important;
}

.mr45 {
  margin-right: 4.5rem !important;
}

.mb45 {
  margin-bottom: 4.5rem !important;
}

.ml45 {
  margin-left: 4.5rem !important;
}

.pt45 {
  padding-top: 4.5rem !important;
}

.pr45 {
  padding-right: 4.5rem !important;
}

.pb45 {
  padding-bottom: 4.5rem !important;
}

.pl45 {
  padding-left: 4.5rem !important;
}

.mt50 {
  margin-top: 5rem !important;
}

.mr50 {
  margin-right: 5rem !important;
}

.mb50 {
  margin-bottom: 5rem !important;
}

.ml50 {
  margin-left: 5rem !important;
}

.pt50 {
  padding-top: 5rem !important;
}

.pr50 {
  padding-right: 5rem !important;
}

.pb50 {
  padding-bottom: 5rem !important;
}

.pl50 {
  padding-left: 5rem !important;
}

.mt55 {
  margin-top: 5.5rem !important;
}

.mr55 {
  margin-right: 5.5rem !important;
}

.mb55 {
  margin-bottom: 5.5rem !important;
}

.ml55 {
  margin-left: 5.5rem !important;
}

.pt55 {
  padding-top: 5.5rem !important;
}

.pr55 {
  padding-right: 5.5rem !important;
}

.pb55 {
  padding-bottom: 5.5rem !important;
}

.pl55 {
  padding-left: 5.5rem !important;
}

.mt60 {
  margin-top: 6rem !important;
}

.mr60 {
  margin-right: 6rem !important;
}

.mb60 {
  margin-bottom: 6rem !important;
}

.ml60 {
  margin-left: 6rem !important;
}

.pt60 {
  padding-top: 6rem !important;
}

.pr60 {
  padding-right: 6rem !important;
}

.pb60 {
  padding-bottom: 6rem !important;
}

.pl60 {
  padding-left: 6rem !important;
}

.mt65 {
  margin-top: 6.5rem !important;
}

.mr65 {
  margin-right: 6.5rem !important;
}

.mb65 {
  margin-bottom: 6.5rem !important;
}

.ml65 {
  margin-left: 6.5rem !important;
}

.pt65 {
  padding-top: 6.5rem !important;
}

.pr65 {
  padding-right: 6.5rem !important;
}

.pb65 {
  padding-bottom: 6.5rem !important;
}

.pl65 {
  padding-left: 6.5rem !important;
}

.mt70 {
  margin-top: 7rem !important;
}

.mr70 {
  margin-right: 7rem !important;
}

.mb70 {
  margin-bottom: 7rem !important;
}

.ml70 {
  margin-left: 7rem !important;
}

.pt70 {
  padding-top: 7rem !important;
}

.pr70 {
  padding-right: 7rem !important;
}

.pb70 {
  padding-bottom: 7rem !important;
}

.pl70 {
  padding-left: 7rem !important;
}

.mt75 {
  margin-top: 7.5rem !important;
}

.mr75 {
  margin-right: 7.5rem !important;
}

.mb75 {
  margin-bottom: 7.5rem !important;
}

.ml75 {
  margin-left: 7.5rem !important;
}

.pt75 {
  padding-top: 7.5rem !important;
}

.pr75 {
  padding-right: 7.5rem !important;
}

.pb75 {
  padding-bottom: 7.5rem !important;
}

.pl75 {
  padding-left: 7.5rem !important;
}

.mt80 {
  margin-top: 8rem !important;
}

.mr80 {
  margin-right: 8rem !important;
}

.mb80 {
  margin-bottom: 8rem !important;
}

.ml80 {
  margin-left: 8rem !important;
}

.pt80 {
  padding-top: 8rem !important;
}

.pr80 {
  padding-right: 8rem !important;
}

.pb80 {
  padding-bottom: 8rem !important;
}

.pl80 {
  padding-left: 8rem !important;
}

.mt85 {
  margin-top: 8.5rem !important;
}

.mr85 {
  margin-right: 8.5rem !important;
}

.mb85 {
  margin-bottom: 8.5rem !important;
}

.ml85 {
  margin-left: 8.5rem !important;
}

.pt85 {
  padding-top: 8.5rem !important;
}

.pr85 {
  padding-right: 8.5rem !important;
}

.pb85 {
  padding-bottom: 8.5rem !important;
}

.pl85 {
  padding-left: 8.5rem !important;
}

.mt90 {
  margin-top: 9rem !important;
}

.mr90 {
  margin-right: 9rem !important;
}

.mb90 {
  margin-bottom: 9rem !important;
}

.ml90 {
  margin-left: 9rem !important;
}

.pt90 {
  padding-top: 9rem !important;
}

.pr90 {
  padding-right: 9rem !important;
}

.pb90 {
  padding-bottom: 9rem !important;
}

.pl90 {
  padding-left: 9rem !important;
}

.mt95 {
  margin-top: 9.5rem !important;
}

.mr95 {
  margin-right: 9.5rem !important;
}

.mb95 {
  margin-bottom: 9.5rem !important;
}

.ml95 {
  margin-left: 9.5rem !important;
}

.pt95 {
  padding-top: 9.5rem !important;
}

.pr95 {
  padding-right: 9.5rem !important;
}

.pb95 {
  padding-bottom: 9.5rem !important;
}

.pl95 {
  padding-left: 9.5rem !important;
}

.mt100 {
  margin-top: 10rem !important;
}

.mr100 {
  margin-right: 10rem !important;
}

.mb100 {
  margin-bottom: 10rem !important;
}

.ml100 {
  margin-left: 10rem !important;
}

.pt100 {
  padding-top: 10rem !important;
}

.pr100 {
  padding-right: 10rem !important;
}

.pb100 {
  padding-bottom: 10rem !important;
}

.pl100 {
  padding-left: 10rem !important;
}

@media screen and (min-width: 768px) {
  .pc-mt0 {
    margin-top: 0rem !important;
  }
  .pc-mr0 {
    margin-right: 0rem !important;
  }
  .pc-mb0 {
    margin-bottom: 0rem !important;
  }
  .pc-ml0 {
    margin-left: 0rem !important;
  }
  .pc-pt0 {
    padding-top: 0rem !important;
  }
  .pc-pr0 {
    padding-right: 0rem !important;
  }
  .pc-pb0 {
    padding-bottom: 0rem !important;
  }
  .pc-pl0 {
    padding-left: 0rem !important;
  }
  .pc-mt5 {
    margin-top: 0.5rem !important;
  }
  .pc-mr5 {
    margin-right: 0.5rem !important;
  }
  .pc-mb5 {
    margin-bottom: 0.5rem !important;
  }
  .pc-ml5 {
    margin-left: 0.5rem !important;
  }
  .pc-pt5 {
    padding-top: 0.5rem !important;
  }
  .pc-pr5 {
    padding-right: 0.5rem !important;
  }
  .pc-pb5 {
    padding-bottom: 0.5rem !important;
  }
  .pc-pl5 {
    padding-left: 0.5rem !important;
  }
  .pc-mt10 {
    margin-top: 1rem !important;
  }
  .pc-mr10 {
    margin-right: 1rem !important;
  }
  .pc-mb10 {
    margin-bottom: 1rem !important;
  }
  .pc-ml10 {
    margin-left: 1rem !important;
  }
  .pc-pt10 {
    padding-top: 1rem !important;
  }
  .pc-pr10 {
    padding-right: 1rem !important;
  }
  .pc-pb10 {
    padding-bottom: 1rem !important;
  }
  .pc-pl10 {
    padding-left: 1rem !important;
  }
  .pc-mt15 {
    margin-top: 1.5rem !important;
  }
  .pc-mr15 {
    margin-right: 1.5rem !important;
  }
  .pc-mb15 {
    margin-bottom: 1.5rem !important;
  }
  .pc-ml15 {
    margin-left: 1.5rem !important;
  }
  .pc-pt15 {
    padding-top: 1.5rem !important;
  }
  .pc-pr15 {
    padding-right: 1.5rem !important;
  }
  .pc-pb15 {
    padding-bottom: 1.5rem !important;
  }
  .pc-pl15 {
    padding-left: 1.5rem !important;
  }
  .pc-mt20 {
    margin-top: 2rem !important;
  }
  .pc-mr20 {
    margin-right: 2rem !important;
  }
  .pc-mb20 {
    margin-bottom: 2rem !important;
  }
  .pc-ml20 {
    margin-left: 2rem !important;
  }
  .pc-pt20 {
    padding-top: 2rem !important;
  }
  .pc-pr20 {
    padding-right: 2rem !important;
  }
  .pc-pb20 {
    padding-bottom: 2rem !important;
  }
  .pc-pl20 {
    padding-left: 2rem !important;
  }
  .pc-mt25 {
    margin-top: 2.5rem !important;
  }
  .pc-mr25 {
    margin-right: 2.5rem !important;
  }
  .pc-mb25 {
    margin-bottom: 2.5rem !important;
  }
  .pc-ml25 {
    margin-left: 2.5rem !important;
  }
  .pc-pt25 {
    padding-top: 2.5rem !important;
  }
  .pc-pr25 {
    padding-right: 2.5rem !important;
  }
  .pc-pb25 {
    padding-bottom: 2.5rem !important;
  }
  .pc-pl25 {
    padding-left: 2.5rem !important;
  }
  .pc-mt30 {
    margin-top: 3rem !important;
  }
  .pc-mr30 {
    margin-right: 3rem !important;
  }
  .pc-mb30 {
    margin-bottom: 3rem !important;
  }
  .pc-ml30 {
    margin-left: 3rem !important;
  }
  .pc-pt30 {
    padding-top: 3rem !important;
  }
  .pc-pr30 {
    padding-right: 3rem !important;
  }
  .pc-pb30 {
    padding-bottom: 3rem !important;
  }
  .pc-pl30 {
    padding-left: 3rem !important;
  }
  .pc-mt35 {
    margin-top: 3.5rem !important;
  }
  .pc-mr35 {
    margin-right: 3.5rem !important;
  }
  .pc-mb35 {
    margin-bottom: 3.5rem !important;
  }
  .pc-ml35 {
    margin-left: 3.5rem !important;
  }
  .pc-pt35 {
    padding-top: 3.5rem !important;
  }
  .pc-pr35 {
    padding-right: 3.5rem !important;
  }
  .pc-pb35 {
    padding-bottom: 3.5rem !important;
  }
  .pc-pl35 {
    padding-left: 3.5rem !important;
  }
  .pc-mt40 {
    margin-top: 4rem !important;
  }
  .pc-mr40 {
    margin-right: 4rem !important;
  }
  .pc-mb40 {
    margin-bottom: 4rem !important;
  }
  .pc-ml40 {
    margin-left: 4rem !important;
  }
  .pc-pt40 {
    padding-top: 4rem !important;
  }
  .pc-pr40 {
    padding-right: 4rem !important;
  }
  .pc-pb40 {
    padding-bottom: 4rem !important;
  }
  .pc-pl40 {
    padding-left: 4rem !important;
  }
  .pc-mt45 {
    margin-top: 4.5rem !important;
  }
  .pc-mr45 {
    margin-right: 4.5rem !important;
  }
  .pc-mb45 {
    margin-bottom: 4.5rem !important;
  }
  .pc-ml45 {
    margin-left: 4.5rem !important;
  }
  .pc-pt45 {
    padding-top: 4.5rem !important;
  }
  .pc-pr45 {
    padding-right: 4.5rem !important;
  }
  .pc-pb45 {
    padding-bottom: 4.5rem !important;
  }
  .pc-pl45 {
    padding-left: 4.5rem !important;
  }
  .pc-mt50 {
    margin-top: 5rem !important;
  }
  .pc-mr50 {
    margin-right: 5rem !important;
  }
  .pc-mb50 {
    margin-bottom: 5rem !important;
  }
  .pc-ml50 {
    margin-left: 5rem !important;
  }
  .pc-pt50 {
    padding-top: 5rem !important;
  }
  .pc-pr50 {
    padding-right: 5rem !important;
  }
  .pc-pb50 {
    padding-bottom: 5rem !important;
  }
  .pc-pl50 {
    padding-left: 5rem !important;
  }
  .pc-mt55 {
    margin-top: 5.5rem !important;
  }
  .pc-mr55 {
    margin-right: 5.5rem !important;
  }
  .pc-mb55 {
    margin-bottom: 5.5rem !important;
  }
  .pc-ml55 {
    margin-left: 5.5rem !important;
  }
  .pc-pt55 {
    padding-top: 5.5rem !important;
  }
  .pc-pr55 {
    padding-right: 5.5rem !important;
  }
  .pc-pb55 {
    padding-bottom: 5.5rem !important;
  }
  .pc-pl55 {
    padding-left: 5.5rem !important;
  }
  .pc-mt60 {
    margin-top: 6rem !important;
  }
  .pc-mr60 {
    margin-right: 6rem !important;
  }
  .pc-mb60 {
    margin-bottom: 6rem !important;
  }
  .pc-ml60 {
    margin-left: 6rem !important;
  }
  .pc-pt60 {
    padding-top: 6rem !important;
  }
  .pc-pr60 {
    padding-right: 6rem !important;
  }
  .pc-pb60 {
    padding-bottom: 6rem !important;
  }
  .pc-pl60 {
    padding-left: 6rem !important;
  }
  .pc-mt65 {
    margin-top: 6.5rem !important;
  }
  .pc-mr65 {
    margin-right: 6.5rem !important;
  }
  .pc-mb65 {
    margin-bottom: 6.5rem !important;
  }
  .pc-ml65 {
    margin-left: 6.5rem !important;
  }
  .pc-pt65 {
    padding-top: 6.5rem !important;
  }
  .pc-pr65 {
    padding-right: 6.5rem !important;
  }
  .pc-pb65 {
    padding-bottom: 6.5rem !important;
  }
  .pc-pl65 {
    padding-left: 6.5rem !important;
  }
  .pc-mt70 {
    margin-top: 7rem !important;
  }
  .pc-mr70 {
    margin-right: 7rem !important;
  }
  .pc-mb70 {
    margin-bottom: 7rem !important;
  }
  .pc-ml70 {
    margin-left: 7rem !important;
  }
  .pc-pt70 {
    padding-top: 7rem !important;
  }
  .pc-pr70 {
    padding-right: 7rem !important;
  }
  .pc-pb70 {
    padding-bottom: 7rem !important;
  }
  .pc-pl70 {
    padding-left: 7rem !important;
  }
  .pc-mt75 {
    margin-top: 7.5rem !important;
  }
  .pc-mr75 {
    margin-right: 7.5rem !important;
  }
  .pc-mb75 {
    margin-bottom: 7.5rem !important;
  }
  .pc-ml75 {
    margin-left: 7.5rem !important;
  }
  .pc-pt75 {
    padding-top: 7.5rem !important;
  }
  .pc-pr75 {
    padding-right: 7.5rem !important;
  }
  .pc-pb75 {
    padding-bottom: 7.5rem !important;
  }
  .pc-pl75 {
    padding-left: 7.5rem !important;
  }
  .pc-mt80 {
    margin-top: 8rem !important;
  }
  .pc-mr80 {
    margin-right: 8rem !important;
  }
  .pc-mb80 {
    margin-bottom: 8rem !important;
  }
  .pc-ml80 {
    margin-left: 8rem !important;
  }
  .pc-pt80 {
    padding-top: 8rem !important;
  }
  .pc-pr80 {
    padding-right: 8rem !important;
  }
  .pc-pb80 {
    padding-bottom: 8rem !important;
  }
  .pc-pl80 {
    padding-left: 8rem !important;
  }
  .pc-mt85 {
    margin-top: 8.5rem !important;
  }
  .pc-mr85 {
    margin-right: 8.5rem !important;
  }
  .pc-mb85 {
    margin-bottom: 8.5rem !important;
  }
  .pc-ml85 {
    margin-left: 8.5rem !important;
  }
  .pc-pt85 {
    padding-top: 8.5rem !important;
  }
  .pc-pr85 {
    padding-right: 8.5rem !important;
  }
  .pc-pb85 {
    padding-bottom: 8.5rem !important;
  }
  .pc-pl85 {
    padding-left: 8.5rem !important;
  }
  .pc-mt90 {
    margin-top: 9rem !important;
  }
  .pc-mr90 {
    margin-right: 9rem !important;
  }
  .pc-mb90 {
    margin-bottom: 9rem !important;
  }
  .pc-ml90 {
    margin-left: 9rem !important;
  }
  .pc-pt90 {
    padding-top: 9rem !important;
  }
  .pc-pr90 {
    padding-right: 9rem !important;
  }
  .pc-pb90 {
    padding-bottom: 9rem !important;
  }
  .pc-pl90 {
    padding-left: 9rem !important;
  }
  .pc-mt95 {
    margin-top: 9.5rem !important;
  }
  .pc-mr95 {
    margin-right: 9.5rem !important;
  }
  .pc-mb95 {
    margin-bottom: 9.5rem !important;
  }
  .pc-ml95 {
    margin-left: 9.5rem !important;
  }
  .pc-pt95 {
    padding-top: 9.5rem !important;
  }
  .pc-pr95 {
    padding-right: 9.5rem !important;
  }
  .pc-pb95 {
    padding-bottom: 9.5rem !important;
  }
  .pc-pl95 {
    padding-left: 9.5rem !important;
  }
  .pc-mt100 {
    margin-top: 10rem !important;
  }
  .pc-mr100 {
    margin-right: 10rem !important;
  }
  .pc-mb100 {
    margin-bottom: 10rem !important;
  }
  .pc-ml100 {
    margin-left: 10rem !important;
  }
  .pc-pt100 {
    padding-top: 10rem !important;
  }
  .pc-pr100 {
    padding-right: 10rem !important;
  }
  .pc-pb100 {
    padding-bottom: 10rem !important;
  }
  .pc-pl100 {
    padding-left: 10rem !important;
  }
}
@media screen and (max-width: 767px) {
  .sp-mt0 {
    margin-top: 0rem !important;
  }
  .sp-mr0 {
    margin-right: 0rem !important;
  }
  .sp-mb0 {
    margin-bottom: 0rem !important;
  }
  .sp-ml0 {
    margin-left: 0rem !important;
  }
  .sp-pt0 {
    padding-top: 0rem !important;
  }
  .sp-pr0 {
    padding-right: 0rem !important;
  }
  .sp-pb0 {
    padding-bottom: 0rem !important;
  }
  .sp-pl0 {
    padding-left: 0rem !important;
  }
  .sp-mt5 {
    margin-top: 0.5rem !important;
  }
  .sp-mr5 {
    margin-right: 0.5rem !important;
  }
  .sp-mb5 {
    margin-bottom: 0.5rem !important;
  }
  .sp-ml5 {
    margin-left: 0.5rem !important;
  }
  .sp-pt5 {
    padding-top: 0.5rem !important;
  }
  .sp-pr5 {
    padding-right: 0.5rem !important;
  }
  .sp-pb5 {
    padding-bottom: 0.5rem !important;
  }
  .sp-pl5 {
    padding-left: 0.5rem !important;
  }
  .sp-mt10 {
    margin-top: 1rem !important;
  }
  .sp-mr10 {
    margin-right: 1rem !important;
  }
  .sp-mb10 {
    margin-bottom: 1rem !important;
  }
  .sp-ml10 {
    margin-left: 1rem !important;
  }
  .sp-pt10 {
    padding-top: 1rem !important;
  }
  .sp-pr10 {
    padding-right: 1rem !important;
  }
  .sp-pb10 {
    padding-bottom: 1rem !important;
  }
  .sp-pl10 {
    padding-left: 1rem !important;
  }
  .sp-mt15 {
    margin-top: 1.5rem !important;
  }
  .sp-mr15 {
    margin-right: 1.5rem !important;
  }
  .sp-mb15 {
    margin-bottom: 1.5rem !important;
  }
  .sp-ml15 {
    margin-left: 1.5rem !important;
  }
  .sp-pt15 {
    padding-top: 1.5rem !important;
  }
  .sp-pr15 {
    padding-right: 1.5rem !important;
  }
  .sp-pb15 {
    padding-bottom: 1.5rem !important;
  }
  .sp-pl15 {
    padding-left: 1.5rem !important;
  }
  .sp-mt20 {
    margin-top: 2rem !important;
  }
  .sp-mr20 {
    margin-right: 2rem !important;
  }
  .sp-mb20 {
    margin-bottom: 2rem !important;
  }
  .sp-ml20 {
    margin-left: 2rem !important;
  }
  .sp-pt20 {
    padding-top: 2rem !important;
  }
  .sp-pr20 {
    padding-right: 2rem !important;
  }
  .sp-pb20 {
    padding-bottom: 2rem !important;
  }
  .sp-pl20 {
    padding-left: 2rem !important;
  }
  .sp-mt25 {
    margin-top: 2.5rem !important;
  }
  .sp-mr25 {
    margin-right: 2.5rem !important;
  }
  .sp-mb25 {
    margin-bottom: 2.5rem !important;
  }
  .sp-ml25 {
    margin-left: 2.5rem !important;
  }
  .sp-pt25 {
    padding-top: 2.5rem !important;
  }
  .sp-pr25 {
    padding-right: 2.5rem !important;
  }
  .sp-pb25 {
    padding-bottom: 2.5rem !important;
  }
  .sp-pl25 {
    padding-left: 2.5rem !important;
  }
  .sp-mt30 {
    margin-top: 3rem !important;
  }
  .sp-mr30 {
    margin-right: 3rem !important;
  }
  .sp-mb30 {
    margin-bottom: 3rem !important;
  }
  .sp-ml30 {
    margin-left: 3rem !important;
  }
  .sp-pt30 {
    padding-top: 3rem !important;
  }
  .sp-pr30 {
    padding-right: 3rem !important;
  }
  .sp-pb30 {
    padding-bottom: 3rem !important;
  }
  .sp-pl30 {
    padding-left: 3rem !important;
  }
  .sp-mt35 {
    margin-top: 3.5rem !important;
  }
  .sp-mr35 {
    margin-right: 3.5rem !important;
  }
  .sp-mb35 {
    margin-bottom: 3.5rem !important;
  }
  .sp-ml35 {
    margin-left: 3.5rem !important;
  }
  .sp-pt35 {
    padding-top: 3.5rem !important;
  }
  .sp-pr35 {
    padding-right: 3.5rem !important;
  }
  .sp-pb35 {
    padding-bottom: 3.5rem !important;
  }
  .sp-pl35 {
    padding-left: 3.5rem !important;
  }
  .sp-mt40 {
    margin-top: 4rem !important;
  }
  .sp-mr40 {
    margin-right: 4rem !important;
  }
  .sp-mb40 {
    margin-bottom: 4rem !important;
  }
  .sp-ml40 {
    margin-left: 4rem !important;
  }
  .sp-pt40 {
    padding-top: 4rem !important;
  }
  .sp-pr40 {
    padding-right: 4rem !important;
  }
  .sp-pb40 {
    padding-bottom: 4rem !important;
  }
  .sp-pl40 {
    padding-left: 4rem !important;
  }
  .sp-mt45 {
    margin-top: 4.5rem !important;
  }
  .sp-mr45 {
    margin-right: 4.5rem !important;
  }
  .sp-mb45 {
    margin-bottom: 4.5rem !important;
  }
  .sp-ml45 {
    margin-left: 4.5rem !important;
  }
  .sp-pt45 {
    padding-top: 4.5rem !important;
  }
  .sp-pr45 {
    padding-right: 4.5rem !important;
  }
  .sp-pb45 {
    padding-bottom: 4.5rem !important;
  }
  .sp-pl45 {
    padding-left: 4.5rem !important;
  }
  .sp-mt50 {
    margin-top: 5rem !important;
  }
  .sp-mr50 {
    margin-right: 5rem !important;
  }
  .sp-mb50 {
    margin-bottom: 5rem !important;
  }
  .sp-ml50 {
    margin-left: 5rem !important;
  }
  .sp-pt50 {
    padding-top: 5rem !important;
  }
  .sp-pr50 {
    padding-right: 5rem !important;
  }
  .sp-pb50 {
    padding-bottom: 5rem !important;
  }
  .sp-pl50 {
    padding-left: 5rem !important;
  }
  .sp-mt55 {
    margin-top: 5.5rem !important;
  }
  .sp-mr55 {
    margin-right: 5.5rem !important;
  }
  .sp-mb55 {
    margin-bottom: 5.5rem !important;
  }
  .sp-ml55 {
    margin-left: 5.5rem !important;
  }
  .sp-pt55 {
    padding-top: 5.5rem !important;
  }
  .sp-pr55 {
    padding-right: 5.5rem !important;
  }
  .sp-pb55 {
    padding-bottom: 5.5rem !important;
  }
  .sp-pl55 {
    padding-left: 5.5rem !important;
  }
  .sp-mt60 {
    margin-top: 6rem !important;
  }
  .sp-mr60 {
    margin-right: 6rem !important;
  }
  .sp-mb60 {
    margin-bottom: 6rem !important;
  }
  .sp-ml60 {
    margin-left: 6rem !important;
  }
  .sp-pt60 {
    padding-top: 6rem !important;
  }
  .sp-pr60 {
    padding-right: 6rem !important;
  }
  .sp-pb60 {
    padding-bottom: 6rem !important;
  }
  .sp-pl60 {
    padding-left: 6rem !important;
  }
  .sp-mt65 {
    margin-top: 6.5rem !important;
  }
  .sp-mr65 {
    margin-right: 6.5rem !important;
  }
  .sp-mb65 {
    margin-bottom: 6.5rem !important;
  }
  .sp-ml65 {
    margin-left: 6.5rem !important;
  }
  .sp-pt65 {
    padding-top: 6.5rem !important;
  }
  .sp-pr65 {
    padding-right: 6.5rem !important;
  }
  .sp-pb65 {
    padding-bottom: 6.5rem !important;
  }
  .sp-pl65 {
    padding-left: 6.5rem !important;
  }
  .sp-mt70 {
    margin-top: 7rem !important;
  }
  .sp-mr70 {
    margin-right: 7rem !important;
  }
  .sp-mb70 {
    margin-bottom: 7rem !important;
  }
  .sp-ml70 {
    margin-left: 7rem !important;
  }
  .sp-pt70 {
    padding-top: 7rem !important;
  }
  .sp-pr70 {
    padding-right: 7rem !important;
  }
  .sp-pb70 {
    padding-bottom: 7rem !important;
  }
  .sp-pl70 {
    padding-left: 7rem !important;
  }
  .sp-mt75 {
    margin-top: 7.5rem !important;
  }
  .sp-mr75 {
    margin-right: 7.5rem !important;
  }
  .sp-mb75 {
    margin-bottom: 7.5rem !important;
  }
  .sp-ml75 {
    margin-left: 7.5rem !important;
  }
  .sp-pt75 {
    padding-top: 7.5rem !important;
  }
  .sp-pr75 {
    padding-right: 7.5rem !important;
  }
  .sp-pb75 {
    padding-bottom: 7.5rem !important;
  }
  .sp-pl75 {
    padding-left: 7.5rem !important;
  }
  .sp-mt80 {
    margin-top: 8rem !important;
  }
  .sp-mr80 {
    margin-right: 8rem !important;
  }
  .sp-mb80 {
    margin-bottom: 8rem !important;
  }
  .sp-ml80 {
    margin-left: 8rem !important;
  }
  .sp-pt80 {
    padding-top: 8rem !important;
  }
  .sp-pr80 {
    padding-right: 8rem !important;
  }
  .sp-pb80 {
    padding-bottom: 8rem !important;
  }
  .sp-pl80 {
    padding-left: 8rem !important;
  }
  .sp-mt85 {
    margin-top: 8.5rem !important;
  }
  .sp-mr85 {
    margin-right: 8.5rem !important;
  }
  .sp-mb85 {
    margin-bottom: 8.5rem !important;
  }
  .sp-ml85 {
    margin-left: 8.5rem !important;
  }
  .sp-pt85 {
    padding-top: 8.5rem !important;
  }
  .sp-pr85 {
    padding-right: 8.5rem !important;
  }
  .sp-pb85 {
    padding-bottom: 8.5rem !important;
  }
  .sp-pl85 {
    padding-left: 8.5rem !important;
  }
  .sp-mt90 {
    margin-top: 9rem !important;
  }
  .sp-mr90 {
    margin-right: 9rem !important;
  }
  .sp-mb90 {
    margin-bottom: 9rem !important;
  }
  .sp-ml90 {
    margin-left: 9rem !important;
  }
  .sp-pt90 {
    padding-top: 9rem !important;
  }
  .sp-pr90 {
    padding-right: 9rem !important;
  }
  .sp-pb90 {
    padding-bottom: 9rem !important;
  }
  .sp-pl90 {
    padding-left: 9rem !important;
  }
  .sp-mt95 {
    margin-top: 9.5rem !important;
  }
  .sp-mr95 {
    margin-right: 9.5rem !important;
  }
  .sp-mb95 {
    margin-bottom: 9.5rem !important;
  }
  .sp-ml95 {
    margin-left: 9.5rem !important;
  }
  .sp-pt95 {
    padding-top: 9.5rem !important;
  }
  .sp-pr95 {
    padding-right: 9.5rem !important;
  }
  .sp-pb95 {
    padding-bottom: 9.5rem !important;
  }
  .sp-pl95 {
    padding-left: 9.5rem !important;
  }
  .sp-mt100 {
    margin-top: 10rem !important;
  }
  .sp-mr100 {
    margin-right: 10rem !important;
  }
  .sp-mb100 {
    margin-bottom: 10rem !important;
  }
  .sp-ml100 {
    margin-left: 10rem !important;
  }
  .sp-pt100 {
    padding-top: 10rem !important;
  }
  .sp-pr100 {
    padding-right: 10rem !important;
  }
  .sp-pb100 {
    padding-bottom: 10rem !important;
  }
  .sp-pl100 {
    padding-left: 10rem !important;
  }
}
.col-0p {
  width: 0%;
}

.col-1p {
  width: 1%;
}

.col-2p {
  width: 2%;
}

.col-3p {
  width: 3%;
}

.col-4p {
  width: 4%;
}

.col-5p {
  width: 5%;
}

.col-6p {
  width: 6%;
}

.col-7p {
  width: 7%;
}

.col-8p {
  width: 8%;
}

.col-9p {
  width: 9%;
}

.col-10p {
  width: 10%;
}

.col-11p {
  width: 11%;
}

.col-12p {
  width: 12%;
}

.col-13p {
  width: 13%;
}

.col-14p {
  width: 14%;
}

.col-15p {
  width: 15%;
}

.col-16p {
  width: 16%;
}

.col-17p {
  width: 17%;
}

.col-18p {
  width: 18%;
}

.col-19p {
  width: 19%;
}

.col-20p {
  width: 20%;
}

.col-21p {
  width: 21%;
}

.col-22p {
  width: 22%;
}

.col-23p {
  width: 23%;
}

.col-24p {
  width: 24%;
}

.col-25p {
  width: 25%;
}

.col-26p {
  width: 26%;
}

.col-27p {
  width: 27%;
}

.col-28p {
  width: 28%;
}

.col-29p {
  width: 29%;
}

.col-30p {
  width: 30%;
}

.col-31p {
  width: 31%;
}

.col-32p {
  width: 32%;
}

.col-33p {
  width: 33%;
}

.col-34p {
  width: 34%;
}

.col-35p {
  width: 35%;
}

.col-36p {
  width: 36%;
}

.col-37p {
  width: 37%;
}

.col-38p {
  width: 38%;
}

.col-39p {
  width: 39%;
}

.col-40p {
  width: 40%;
}

.col-41p {
  width: 41%;
}

.col-42p {
  width: 42%;
}

.col-43p {
  width: 43%;
}

.col-44p {
  width: 44%;
}

.col-45p {
  width: 45%;
}

.col-46p {
  width: 46%;
}

.col-47p {
  width: 47%;
}

.col-48p {
  width: 48%;
}

.col-49p {
  width: 49%;
}

.col-50p {
  width: 50%;
}

.col-51p {
  width: 51%;
}

.col-52p {
  width: 52%;
}

.col-53p {
  width: 53%;
}

.col-54p {
  width: 54%;
}

.col-55p {
  width: 55%;
}

.col-56p {
  width: 56%;
}

.col-57p {
  width: 57%;
}

.col-58p {
  width: 58%;
}

.col-59p {
  width: 59%;
}

.col-60p {
  width: 60%;
}

.col-61p {
  width: 61%;
}

.col-62p {
  width: 62%;
}

.col-63p {
  width: 63%;
}

.col-64p {
  width: 64%;
}

.col-65p {
  width: 65%;
}

.col-66p {
  width: 66%;
}

.col-67p {
  width: 67%;
}

.col-68p {
  width: 68%;
}

.col-69p {
  width: 69%;
}

.col-70p {
  width: 70%;
}

.col-71p {
  width: 71%;
}

.col-72p {
  width: 72%;
}

.col-73p {
  width: 73%;
}

.col-74p {
  width: 74%;
}

.col-75p {
  width: 75%;
}

.col-76p {
  width: 76%;
}

.col-77p {
  width: 77%;
}

.col-78p {
  width: 78%;
}

.col-79p {
  width: 79%;
}

.col-80p {
  width: 80%;
}

.col-81p {
  width: 81%;
}

.col-82p {
  width: 82%;
}

.col-83p {
  width: 83%;
}

.col-84p {
  width: 84%;
}

.col-85p {
  width: 85%;
}

.col-86p {
  width: 86%;
}

.col-87p {
  width: 87%;
}

.col-88p {
  width: 88%;
}

.col-89p {
  width: 89%;
}

.col-90p {
  width: 90%;
}

.col-91p {
  width: 91%;
}

.col-92p {
  width: 92%;
}

.col-93p {
  width: 93%;
}

.col-94p {
  width: 94%;
}

.col-95p {
  width: 95%;
}

.col-96p {
  width: 96%;
}

.col-97p {
  width: 97%;
}

.col-98p {
  width: 98%;
}

.col-99p {
  width: 99%;
}

.col-100p {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .col-0p-sp {
    width: 0%;
  }
  .col-1p-sp {
    width: 1%;
  }
  .col-2p-sp {
    width: 2%;
  }
  .col-3p-sp {
    width: 3%;
  }
  .col-4p-sp {
    width: 4%;
  }
  .col-5p-sp {
    width: 5%;
  }
  .col-6p-sp {
    width: 6%;
  }
  .col-7p-sp {
    width: 7%;
  }
  .col-8p-sp {
    width: 8%;
  }
  .col-9p-sp {
    width: 9%;
  }
  .col-10p-sp {
    width: 10%;
  }
  .col-11p-sp {
    width: 11%;
  }
  .col-12p-sp {
    width: 12%;
  }
  .col-13p-sp {
    width: 13%;
  }
  .col-14p-sp {
    width: 14%;
  }
  .col-15p-sp {
    width: 15%;
  }
  .col-16p-sp {
    width: 16%;
  }
  .col-17p-sp {
    width: 17%;
  }
  .col-18p-sp {
    width: 18%;
  }
  .col-19p-sp {
    width: 19%;
  }
  .col-20p-sp {
    width: 20%;
  }
  .col-21p-sp {
    width: 21%;
  }
  .col-22p-sp {
    width: 22%;
  }
  .col-23p-sp {
    width: 23%;
  }
  .col-24p-sp {
    width: 24%;
  }
  .col-25p-sp {
    width: 25%;
  }
  .col-26p-sp {
    width: 26%;
  }
  .col-27p-sp {
    width: 27%;
  }
  .col-28p-sp {
    width: 28%;
  }
  .col-29p-sp {
    width: 29%;
  }
  .col-30p-sp {
    width: 30%;
  }
  .col-31p-sp {
    width: 31%;
  }
  .col-32p-sp {
    width: 32%;
  }
  .col-33p-sp {
    width: 33%;
  }
  .col-34p-sp {
    width: 34%;
  }
  .col-35p-sp {
    width: 35%;
  }
  .col-36p-sp {
    width: 36%;
  }
  .col-37p-sp {
    width: 37%;
  }
  .col-38p-sp {
    width: 38%;
  }
  .col-39p-sp {
    width: 39%;
  }
  .col-40p-sp {
    width: 40%;
  }
  .col-41p-sp {
    width: 41%;
  }
  .col-42p-sp {
    width: 42%;
  }
  .col-43p-sp {
    width: 43%;
  }
  .col-44p-sp {
    width: 44%;
  }
  .col-45p-sp {
    width: 45%;
  }
  .col-46p-sp {
    width: 46%;
  }
  .col-47p-sp {
    width: 47%;
  }
  .col-48p-sp {
    width: 48%;
  }
  .col-49p-sp {
    width: 49%;
  }
  .col-50p-sp {
    width: 50%;
  }
  .col-51p-sp {
    width: 51%;
  }
  .col-52p-sp {
    width: 52%;
  }
  .col-53p-sp {
    width: 53%;
  }
  .col-54p-sp {
    width: 54%;
  }
  .col-55p-sp {
    width: 55%;
  }
  .col-56p-sp {
    width: 56%;
  }
  .col-57p-sp {
    width: 57%;
  }
  .col-58p-sp {
    width: 58%;
  }
  .col-59p-sp {
    width: 59%;
  }
  .col-60p-sp {
    width: 60%;
  }
  .col-61p-sp {
    width: 61%;
  }
  .col-62p-sp {
    width: 62%;
  }
  .col-63p-sp {
    width: 63%;
  }
  .col-64p-sp {
    width: 64%;
  }
  .col-65p-sp {
    width: 65%;
  }
  .col-66p-sp {
    width: 66%;
  }
  .col-67p-sp {
    width: 67%;
  }
  .col-68p-sp {
    width: 68%;
  }
  .col-69p-sp {
    width: 69%;
  }
  .col-70p-sp {
    width: 70%;
  }
  .col-71p-sp {
    width: 71%;
  }
  .col-72p-sp {
    width: 72%;
  }
  .col-73p-sp {
    width: 73%;
  }
  .col-74p-sp {
    width: 74%;
  }
  .col-75p-sp {
    width: 75%;
  }
  .col-76p-sp {
    width: 76%;
  }
  .col-77p-sp {
    width: 77%;
  }
  .col-78p-sp {
    width: 78%;
  }
  .col-79p-sp {
    width: 79%;
  }
  .col-80p-sp {
    width: 80%;
  }
  .col-81p-sp {
    width: 81%;
  }
  .col-82p-sp {
    width: 82%;
  }
  .col-83p-sp {
    width: 83%;
  }
  .col-84p-sp {
    width: 84%;
  }
  .col-85p-sp {
    width: 85%;
  }
  .col-86p-sp {
    width: 86%;
  }
  .col-87p-sp {
    width: 87%;
  }
  .col-88p-sp {
    width: 88%;
  }
  .col-89p-sp {
    width: 89%;
  }
  .col-90p-sp {
    width: 90%;
  }
  .col-91p-sp {
    width: 91%;
  }
  .col-92p-sp {
    width: 92%;
  }
  .col-93p-sp {
    width: 93%;
  }
  .col-94p-sp {
    width: 94%;
  }
  .col-95p-sp {
    width: 95%;
  }
  .col-96p-sp {
    width: 96%;
  }
  .col-97p-sp {
    width: 97%;
  }
  .col-98p-sp {
    width: 98%;
  }
  .col-99p-sp {
    width: 99%;
  }
  .col-100p-sp {
    width: 100%;
  }
}
.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

@media screen and (min-width: 768px) {
  .align-center-pc {
    text-align: center;
  }
  .align-right-pc {
    text-align: right;
  }
  .align-left-pc {
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .align-center-sp {
    text-align: center;
  }
  .align-right-sp {
    text-align: right;
  }
  .align-left-sp {
    text-align: left;
  }
}
.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.fw-black {
  font-weight: 900;
}

.fw-bold,
.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-normal,
.fw-400 {
  font-weight: 400;
}

.fw-light,
.fw-300 {
  font-weight: 300;
}

.img-fit, .home-web-product__thumbnail img {
  font-family: "object-fit: cover;";
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.d-ib {
  display: inline-block;
}

.d-if {
  display: inline-flex;
}

.d-b {
  display: block;
}

.d-f {
  display: flex;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

input,
select,
textarea {
  background-color: transparent;
  outline: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

div,
p {
  word-break: break-word;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
nav,
picture {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

ul,
ol,
li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
  transition-duration: 0.3s;
}
a:hover {
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  a:hover {
    opacity: 0.75;
  }
}
a:focus {
  outline: none;
  text-decoration: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
}
ul li,
ol li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

figure {
  margin: 0;
  padding: 0;
  line-height: 0;
}
figure img {
  width: 100%;
}

input[type=submit] {
  border: none;
  transition-duration: 0.2s;
}

table {
  border-spacing: inherit;
  border-collapse: collapse;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

@media screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
  a[href^=tel] {
    pointer-events: none;
    text-decoration: none;
    opacity: 1 !important;
    cursor: default !important;
  }
}
@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}
.page-main {
  display: block;
  position: relative;
}

.js-anm-mask {
  position: relative;
  display: inline-flex;
  overflow: hidden;
}
.js-anm-mask::before {
  position: absolute;
  content: "";
  top: 5%;
  left: 0;
  width: 104rem;
  height: 92%;
  clip-path: inset(0 0 0 0);
  will-change: clip-path;
  z-index: 1;
  transition: 1s clip-path, 0.2s opacity 1s;
}
@media screen and (max-width: 767px) {
  .js-anm-mask::before {
    transition: 1s clip-path, 0.2s opacity 0.2s;
  }
}
.js-anm-mask.is-show::before {
  clip-path: inset(0 0 0 100%);
  opacity: 0;
}

.el-delay {
  opacity: 0;
  visibility: hidden;
  transition: opacity cubic-bezier(0.65, 0.01, 0.3, 0.97) 0.6s;
}
.is-show .el-delay {
  opacity: 1;
  visibility: visible;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

html.lenis:not(.is-mobile)::-webkit-scrollbar {
  width: 1rem;
}
html.lenis:not(.is-mobile)::-webkit-scrollbar-track {
  background: transparent;
}
html.lenis:not(.is-mobile)::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 1rem;
  cursor: grab;
}
@media screen and (min-width: 1025px) {
  html.lenis::-webkit-scrollbar {
    width: 1rem;
  }
  html.lenis::-webkit-scrollbar-track {
    background: transparent;
  }
  html.lenis::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 1rem;
    cursor: grab;
  }
}

.inview-img img {
  transform: scale(1.4, 1.4);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 0.1s;
}
.inview-img.is-show img {
  transform: scale(1, 1);
}

.fadeIn {
  opacity: 0;
  transition: 0.8s cubic-bezier(0.66, 0, 0.34, 1);
}
.fadeIn.is-show {
  opacity: 1;
}

.anm01 {
  animation-name: flash;
}

.anm02 {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

.anm03 {
  animation-duration: calc(var(--animate-duration) * 0.75);
  animation-name: bounceIn;
}

.anm04 {
  animation-name: fadeOutDown;
}

.anm05 {
  animation-name: fadeOutUp;
}

.anm06 {
  --animate-duration: 2s;
  animation-name: fadeIn;
}

.anm07 {
  animation-name: rotate2;
  animation-duration: calc(var(--animate-duration) * 0.75);
  animation-timing-function: linear;
}

.anm08 {
  animation-timing-function: ease-in-out;
  animation-name: bounceOut;
}

.anm09 {
  animation-name: fadeOutLeft;
}

.anm10 {
  animation-name: slideRightUp;
  animation-timing-function: linear;
}

.anm11 {
  animation-name: slideLeftDown;
  animation-timing-function: linear;
}

.anm12 {
  animation-name: slideRightUpH;
  animation-timing-function: linear;
}

.anm13 {
  animation-name: slideLeftDownH;
  animation-timing-function: linear;
}

.anm14 {
  animation-name: zoomOut;
}

.anm15 {
  animation-name: zoomIn;
}

.anm01,
.anm02,
.anm03,
.anm04,
.anm05,
.anm06,
.anm07,
.anm08,
.anm09,
.anm10,
.anm11,
.anm12,
.anm13,
.anm14,
.anm15 {
  animation-delay: var(--animate-delay);
}
.anm01.delay-1,
.anm02.delay-1,
.anm03.delay-1,
.anm04.delay-1,
.anm05.delay-1,
.anm06.delay-1,
.anm07.delay-1,
.anm08.delay-1,
.anm09.delay-1,
.anm10.delay-1,
.anm11.delay-1,
.anm12.delay-1,
.anm13.delay-1,
.anm14.delay-1,
.anm15.delay-1 {
  --animate-delay: 0.4s;
}
.anm01.delay-2,
.anm02.delay-2,
.anm03.delay-2,
.anm04.delay-2,
.anm05.delay-2,
.anm06.delay-2,
.anm07.delay-2,
.anm08.delay-2,
.anm09.delay-2,
.anm10.delay-2,
.anm11.delay-2,
.anm12.delay-2,
.anm13.delay-2,
.anm14.delay-2,
.anm15.delay-2 {
  --animate-delay: 0.8s;
}
.anm01.delay-3,
.anm02.delay-3,
.anm03.delay-3,
.anm04.delay-3,
.anm05.delay-3,
.anm06.delay-3,
.anm07.delay-3,
.anm08.delay-3,
.anm09.delay-3,
.anm10.delay-3,
.anm11.delay-3,
.anm12.delay-3,
.anm13.delay-3,
.anm14.delay-3,
.anm15.delay-3 {
  --animate-delay: 1.2s;
}
.anm01.js-inview,
.anm02.js-inview,
.anm03.js-inview,
.anm04.js-inview,
.anm05.js-inview,
.anm06.js-inview,
.anm07.js-inview,
.anm08.js-inview,
.anm09.js-inview,
.anm10.js-inview,
.anm11.js-inview,
.anm12.js-inview,
.anm13.js-inview,
.anm14.js-inview,
.anm15.js-inview {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.js-spline-text.anm-textEn {
  overflow: hidden;
  white-space: nowrap;
  width: max-content;
  display: block;
}
.js-spline-text.anm-textEn span {
  display: inline-block;
  transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 0.2s;
  transform: translate(0, 100%);
}
.is-show .js-spline-text.anm-textEn span {
  transform: translate(0, 0);
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(1) {
  transition-delay: calc(var(--el-delay) + 0s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(2) {
  transition-delay: calc(var(--el-delay) + 0.1s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(3) {
  transition-delay: calc(var(--el-delay) + 0.2s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(4) {
  transition-delay: calc(var(--el-delay) + 0.3s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(5) {
  transition-delay: calc(var(--el-delay) + 0.4s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(6) {
  transition-delay: calc(var(--el-delay) + 0.5s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(7) {
  transition-delay: calc(var(--el-delay) + 0.6s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(8) {
  transition-delay: calc(var(--el-delay) + 0.7s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(9) {
  transition-delay: calc(var(--el-delay) + 0.8s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(10) {
  transition-delay: calc(var(--el-delay) + 0.9s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(11) {
  transition-delay: calc(var(--el-delay) + 1s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(12) {
  transition-delay: calc(var(--el-delay) + 1.1s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(13) {
  transition-delay: calc(var(--el-delay) + 1.2s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(14) {
  transition-delay: calc(var(--el-delay) + 1.3s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(15) {
  transition-delay: calc(var(--el-delay) + 1.4s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(16) {
  transition-delay: calc(var(--el-delay) + 1.5s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(17) {
  transition-delay: calc(var(--el-delay) + 1.6s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(18) {
  transition-delay: calc(var(--el-delay) + 1.7s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(19) {
  transition-delay: calc(var(--el-delay) + 1.8s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(20) {
  transition-delay: calc(var(--el-delay) + 1.9s) !important;
}
.js-spline-text.anm-textEn:nth-child(1) span:nth-child(21) {
  transition-delay: calc(var(--el-delay) + 2s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(1) {
  transition-delay: calc(var(--el-delay) + 0.2s + 0s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(2) {
  transition-delay: calc(var(--el-delay) + 0.2s + 0.1s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(3) {
  transition-delay: calc(var(--el-delay) + 0.2s + 0.2s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(4) {
  transition-delay: calc(var(--el-delay) + 0.2s + 0.3s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(5) {
  transition-delay: calc(var(--el-delay) + 0.2s + 0.4s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(6) {
  transition-delay: calc(var(--el-delay) + 0.2s + 0.5s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(7) {
  transition-delay: calc(var(--el-delay) + 0.2s + 0.6s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(8) {
  transition-delay: calc(var(--el-delay) + 0.2s + 0.7s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(9) {
  transition-delay: calc(var(--el-delay) + 0.2s + 0.8s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(10) {
  transition-delay: calc(var(--el-delay) + 0.2s + 0.9s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(11) {
  transition-delay: calc(var(--el-delay) + 0.2s + 1s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(12) {
  transition-delay: calc(var(--el-delay) + 0.2s + 1.1s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(13) {
  transition-delay: calc(var(--el-delay) + 0.2s + 1.2s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(14) {
  transition-delay: calc(var(--el-delay) + 0.2s + 1.3s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(15) {
  transition-delay: calc(var(--el-delay) + 0.2s + 1.4s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(16) {
  transition-delay: calc(var(--el-delay) + 0.2s + 1.5s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(17) {
  transition-delay: calc(var(--el-delay) + 0.2s + 1.6s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(18) {
  transition-delay: calc(var(--el-delay) + 0.2s + 1.7s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(19) {
  transition-delay: calc(var(--el-delay) + 0.2s + 1.8s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(20) {
  transition-delay: calc(var(--el-delay) + 0.2s + 1.9s) !important;
}
.js-spline-text.anm-textEn:nth-child(2) span:nth-child(21) {
  transition-delay: calc(var(--el-delay) + 0.2s + 2s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(1) {
  transition-delay: calc(var(--el-delay) + 0.4s + 0s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(2) {
  transition-delay: calc(var(--el-delay) + 0.4s + 0.1s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(3) {
  transition-delay: calc(var(--el-delay) + 0.4s + 0.2s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(4) {
  transition-delay: calc(var(--el-delay) + 0.4s + 0.3s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(5) {
  transition-delay: calc(var(--el-delay) + 0.4s + 0.4s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(6) {
  transition-delay: calc(var(--el-delay) + 0.4s + 0.5s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(7) {
  transition-delay: calc(var(--el-delay) + 0.4s + 0.6s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(8) {
  transition-delay: calc(var(--el-delay) + 0.4s + 0.7s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(9) {
  transition-delay: calc(var(--el-delay) + 0.4s + 0.8s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(10) {
  transition-delay: calc(var(--el-delay) + 0.4s + 0.9s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(11) {
  transition-delay: calc(var(--el-delay) + 0.4s + 1s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(12) {
  transition-delay: calc(var(--el-delay) + 0.4s + 1.1s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(13) {
  transition-delay: calc(var(--el-delay) + 0.4s + 1.2s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(14) {
  transition-delay: calc(var(--el-delay) + 0.4s + 1.3s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(15) {
  transition-delay: calc(var(--el-delay) + 0.4s + 1.4s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(16) {
  transition-delay: calc(var(--el-delay) + 0.4s + 1.5s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(17) {
  transition-delay: calc(var(--el-delay) + 0.4s + 1.6s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(18) {
  transition-delay: calc(var(--el-delay) + 0.4s + 1.7s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(19) {
  transition-delay: calc(var(--el-delay) + 0.4s + 1.8s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(20) {
  transition-delay: calc(var(--el-delay) + 0.4s + 1.9s) !important;
}
.js-spline-text.anm-textEn:nth-child(3) span:nth-child(21) {
  transition-delay: calc(var(--el-delay) + 0.4s + 2s) !important;
}
.js-spline-text.anm-text {
  overflow: hidden;
  white-space: nowrap;
  width: max-content;
  display: block;
}
.js-spline-text.anm-text span {
  display: inline-block;
  transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.3s ease 0.2s;
  opacity: 0;
}
.is-show .js-spline-text.anm-text span {
  opacity: 1;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(1) {
  transition-delay: calc(var(--el-delay) + 0.15s + 0s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(2) {
  transition-delay: calc(var(--el-delay) + 0.15s + 0.1s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(3) {
  transition-delay: calc(var(--el-delay) + 0.15s + 0.2s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(4) {
  transition-delay: calc(var(--el-delay) + 0.15s + 0.3s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(5) {
  transition-delay: calc(var(--el-delay) + 0.15s + 0.4s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(6) {
  transition-delay: calc(var(--el-delay) + 0.15s + 0.5s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(7) {
  transition-delay: calc(var(--el-delay) + 0.15s + 0.6s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(8) {
  transition-delay: calc(var(--el-delay) + 0.15s + 0.7s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(9) {
  transition-delay: calc(var(--el-delay) + 0.15s + 0.8s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(10) {
  transition-delay: calc(var(--el-delay) + 0.15s + 0.9s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(11) {
  transition-delay: calc(var(--el-delay) + 0.15s + 1s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(12) {
  transition-delay: calc(var(--el-delay) + 0.15s + 1.1s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(13) {
  transition-delay: calc(var(--el-delay) + 0.15s + 1.2s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(14) {
  transition-delay: calc(var(--el-delay) + 0.15s + 1.3s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(15) {
  transition-delay: calc(var(--el-delay) + 0.15s + 1.4s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(16) {
  transition-delay: calc(var(--el-delay) + 0.15s + 1.5s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(17) {
  transition-delay: calc(var(--el-delay) + 0.15s + 1.6s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(18) {
  transition-delay: calc(var(--el-delay) + 0.15s + 1.7s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(19) {
  transition-delay: calc(var(--el-delay) + 0.15s + 1.8s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(20) {
  transition-delay: calc(var(--el-delay) + 0.15s + 1.9s) !important;
}
.js-spline-text.anm-text:nth-child(1) span:nth-child(21) {
  transition-delay: calc(var(--el-delay) + 0.15s + 2s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(1) {
  transition-delay: calc(var(--el-delay) + 0.3s + 0s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(2) {
  transition-delay: calc(var(--el-delay) + 0.3s + 0.1s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(3) {
  transition-delay: calc(var(--el-delay) + 0.3s + 0.2s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(4) {
  transition-delay: calc(var(--el-delay) + 0.3s + 0.3s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(5) {
  transition-delay: calc(var(--el-delay) + 0.3s + 0.4s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(6) {
  transition-delay: calc(var(--el-delay) + 0.3s + 0.5s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(7) {
  transition-delay: calc(var(--el-delay) + 0.3s + 0.6s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(8) {
  transition-delay: calc(var(--el-delay) + 0.3s + 0.7s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(9) {
  transition-delay: calc(var(--el-delay) + 0.3s + 0.8s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(10) {
  transition-delay: calc(var(--el-delay) + 0.3s + 0.9s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(11) {
  transition-delay: calc(var(--el-delay) + 0.3s + 1s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(12) {
  transition-delay: calc(var(--el-delay) + 0.3s + 1.1s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(13) {
  transition-delay: calc(var(--el-delay) + 0.3s + 1.2s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(14) {
  transition-delay: calc(var(--el-delay) + 0.3s + 1.3s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(15) {
  transition-delay: calc(var(--el-delay) + 0.3s + 1.4s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(16) {
  transition-delay: calc(var(--el-delay) + 0.3s + 1.5s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(17) {
  transition-delay: calc(var(--el-delay) + 0.3s + 1.6s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(18) {
  transition-delay: calc(var(--el-delay) + 0.3s + 1.7s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(19) {
  transition-delay: calc(var(--el-delay) + 0.3s + 1.8s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(20) {
  transition-delay: calc(var(--el-delay) + 0.3s + 1.9s) !important;
}
.js-spline-text.anm-text:nth-child(2) span:nth-child(21) {
  transition-delay: calc(var(--el-delay) + 0.3s + 2s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(1) {
  transition-delay: calc(var(--el-delay) + 0.45s + 0s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(2) {
  transition-delay: calc(var(--el-delay) + 0.45s + 0.1s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(3) {
  transition-delay: calc(var(--el-delay) + 0.45s + 0.2s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(4) {
  transition-delay: calc(var(--el-delay) + 0.45s + 0.3s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(5) {
  transition-delay: calc(var(--el-delay) + 0.45s + 0.4s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(6) {
  transition-delay: calc(var(--el-delay) + 0.45s + 0.5s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(7) {
  transition-delay: calc(var(--el-delay) + 0.45s + 0.6s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(8) {
  transition-delay: calc(var(--el-delay) + 0.45s + 0.7s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(9) {
  transition-delay: calc(var(--el-delay) + 0.45s + 0.8s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(10) {
  transition-delay: calc(var(--el-delay) + 0.45s + 0.9s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(11) {
  transition-delay: calc(var(--el-delay) + 0.45s + 1s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(12) {
  transition-delay: calc(var(--el-delay) + 0.45s + 1.1s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(13) {
  transition-delay: calc(var(--el-delay) + 0.45s + 1.2s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(14) {
  transition-delay: calc(var(--el-delay) + 0.45s + 1.3s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(15) {
  transition-delay: calc(var(--el-delay) + 0.45s + 1.4s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(16) {
  transition-delay: calc(var(--el-delay) + 0.45s + 1.5s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(17) {
  transition-delay: calc(var(--el-delay) + 0.45s + 1.6s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(18) {
  transition-delay: calc(var(--el-delay) + 0.45s + 1.7s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(19) {
  transition-delay: calc(var(--el-delay) + 0.45s + 1.8s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(20) {
  transition-delay: calc(var(--el-delay) + 0.45s + 1.9s) !important;
}
.js-spline-text.anm-text:nth-child(3) span:nth-child(21) {
  transition-delay: calc(var(--el-delay) + 0.45s + 2s) !important;
}

html {
  font-size: 62.5%;
}
@media screen and (max-width: 767px) {
  html {
    font-size: 1.33333333vw;
  }
}

body {
  min-height: 100vh;
  color: #000000;
  font-family: "Noto Sans JP", "YuGothic", "游ゴシック", "Yu Gothic", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ⾓ゴ Pro", "Hiragino Kaku Gothic Pro", "MS PGothic", sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 500;
  overflow-wrap: break-word;
  overflow-x: hidden;
  letter-spacing: 0;
}
body.is-fixed {
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  body {
    font-size: 2.4rem;
    line-height: 1.75;
  }
}

.body-no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

#wrapper {
  position: relative;
  overflow: hidden;
}
#wrapper .bg-deco {
  position: absolute;
  top: -2px;
  left: 0;
  z-index: -1;
  width: 317rem;
  pointer-events: none;
}
@media screen and (min-width: 3170px) {
  #wrapper .bg-deco {
    width: 120%;
  }
}
#wrapper .bg-deco li {
  position: relative;
}
#wrapper .bg-deco li::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  opacity: 1;
  transition: 0.5s transform linear 0s, 0.1s opacity 0.5s;
}
#wrapper .bg-deco li:nth-child(1) {
  width: 144.97143rem;
}
#wrapper .bg-deco li.is-show::after {
  transform: translate(0, 100%);
  opacity: 0;
}
@media screen and (min-width: 981px) and (max-width: 1366px) {
  #wrapper .bg-deco {
    width: 232.0644216691vw;
    left: -20.4978038067vw;
  }
}
@media screen and (min-width: 981px) and (max-width: 1200px) {
  #wrapper .bg-deco {
    left: -8.78477306vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  #wrapper .bg-deco {
    width: 323.4693877551vw;
    left: -43.8775510204vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  #wrapper .bg-deco {
    width: 323.4693877551vw;
    left: -48.9795918367vw;
  }
}
@media screen and (max-width: 880px) {
  #wrapper .bg-deco {
    width: 323.4693877551vw;
    left: 0vw;
  }
  #wrapper .bg-deco li:nth-child(1) {
    width: 100vw;
  }
}
@media screen and (max-width: 767px) {
  .no-scroll #wrapper {
    overflow: hidden;
    height: 100dvh;
    height: calc(var(--vh, 1vh) * 100);
  }
}

.inner {
  max-width: 104rem;
  margin: 0 auto;
  padding: 0 3rem;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 100;
}
.header::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  opacity: 0;
  transform: translateY(-101%);
  transition: 0.2s;
  z-index: 1;
}
.header__inner {
  padding: 0 6rem;
}
.header__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-logo {
  width: max-content;
  flex-shrink: 0;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  position: relative;
  z-index: 100;
}
.header-logo__link {
  display: inline-flex;
  width: 19.5rem;
}
.header-logo__slg {
  font-size: 1.2rem;
  line-height: 1.75;
  font-weight: 400;
  letter-spacing: 0.05em;
}
.header-main__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.header-nav {
  text-align: center;
  position: relative;
  z-index: 99;
}
.header-nav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  gap: 4.6rem;
}
.header-nav__item {
  line-height: 1;
}
.header-nav__item.is-service {
  padding-right: 1.6rem;
  position: relative;
}
.header-nav__item.is-service::before {
  position: absolute;
  content: "";
  z-index: 3;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.12rem;
  height: 0.72rem;
  background: url("../img/common/ico_arrow_down01.svg") no-repeat center center/100% 100%;
}
.header-nav-sub .service-nav {
  padding-right: 3.2rem;
}
@media screen and (min-width: 1101px) {
  .header-nav-sub {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
    z-index: 1;
    padding: 10rem 6rem 6rem;
  }
  .header-nav-sub::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transform: translateY(-101%);
  }
  .header-nav-sub__inner {
    position: relative;
    transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
    transition-delay: 0.2s;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
  }
  .header-nav-sub.is-open {
    visibility: visible;
    opacity: 1;
  }
  .header-nav-sub.is-open::before {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  .header-nav-sub.is-open .header-nav-sub__inner {
    visibility: visible;
    opacity: 1;
  }
}
.header-nav__link {
  position: relative;
  z-index: 3;
  display: inline-flex;
  font-family: "DIN Pro", sans-serif;
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: 700;
  letter-spacing: 0.1em;
}
.header-nav__link::before {
  position: absolute;
  z-index: 1;
  bottom: -0.2rem;
  left: 0;
  transform: scaleX(0);
  transform-origin: right;
  content: "";
  width: 100%;
  background: #000000;
  height: 1px;
  transition: transform 0.3s ease;
}
@media screen and (min-width: 768px) {
  .header-nav__link:hover::before {
    transform: scaleY(1);
    transform-origin: left;
  }
}
.header-tel {
  font-family: "DIN Pro", sans-serif;
  font-size: 2.2rem;
  line-height: 1.0909090909;
  font-weight: 700;
  letter-spacing: 0.05em;
  position: relative;
  z-index: 100;
}
.header-tel--tablet {
  display: none;
}
.header .hamburger-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 4.5rem;
  height: 3.6rem;
  cursor: pointer;
  position: fixed;
  z-index: 102;
  top: 3.2rem;
  right: 3rem;
  display: none;
}
.header .hamburger-btn span {
  background: #000000;
  width: 100%;
  height: 0.4rem;
  height: 0.5rem;
  transition: 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
}
.header .hamburger-btn span:nth-child(3) {
  width: 2.4rem;
  width: 54%;
}
.header .hamburger-btn.is-active span {
  background: #FFFFFF;
}
.header .hamburger-btn.is-active span:nth-child(1) {
  transform: rotateZ(45deg) translate(1.1rem, 1.1rem);
}
.header .hamburger-btn.is-active span:nth-child(2) {
  transform: rotateZ(-45deg);
}
.header .hamburger-btn.is-active span:nth-child(3) {
  opacity: 0;
  transform: translateX(100%);
}
.header.is-sticky::before {
  transform: translateY(0);
  opacity: 1;
}

@media screen and (min-width: 981px) and (max-width: 1366px) {
  .header__inner {
    padding: 0 2.9282576867vw 0;
  }
  .header-logo {
    gap: 2.196193265vw;
  }
  .header-main__inner {
    gap: 3.6603221083vw;
  }
  .header-nav__list {
    gap: 2.9282576867vw;
  }
  .header-nav-sub {
    padding-right: 2.9282576867vw;
  }
  .header-nav-sub .service-nav {
    padding-right: 0;
  }
}
@media screen and (min-width: 981px) and (max-width: 1200px) {
  .header__inner {
    padding: 0 2.0497803807vw 0;
  }
  .header-logo {
    gap: 1.4641288433vw;
  }
  .header-main__inner {
    gap: 2.196193265vw;
  }
  .header-nav__list {
    gap: 1.4641288433vw;
  }
  .header-nav-sub {
    padding-right: 2.196193265vw;
  }
}
@media screen and (max-width: 1100px) {
  .header__inner {
    padding: 0 3.0612244898vw;
  }
  .header__row {
    gap: 1.5rem;
  }
  .header-logo {
    gap: 3rem;
  }
  .header-logo__link {
    width: 19.5rem;
  }
  .header-logo__slg {
    font-size: 1.2rem;
    line-height: 1.75;
  }
  .header-main-sp {
    display: block;
  }
  .header-tel--tablet {
    display: block;
    margin-right: 8rem;
  }
  .header-main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100dvh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100dvh;
    max-height: calc(var(--vh, 1vh) * 100);
    gap: 5rem;
    background: #000000;
    color: #FFFFFF;
    z-index: 101;
    padding: 10rem 0 6rem;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    transform: scale(1.4);
    transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
  }
  .header-main__inner {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .header-main .header-tel {
    display: none;
  }
  .header-nav {
    text-align: left;
    width: 100%;
    max-width: 70.9rem;
  }
  .header-nav__list {
    display: block;
  }
  .header-nav__item {
    padding-bottom: 1rem;
  }
  .header-nav__item.is-service {
    padding-right: 0;
  }
  .header-nav__item.is-service::before {
    content: none;
  }
  .header-nav-sub {
    padding: 3rem 0 4rem;
  }
  .header-nav-sub::before {
    content: none;
  }
  .header-nav-sub__inner {
    margin-left: -2.7rem;
  }
  .header-nav-sub .service-nav__head a {
    background: #FFFFFF;
    color: #000000;
  }
  .header-nav-sub .service-nav__head a::after {
    background-image: url("../img/common/ico_arrow_menu_black.svg");
  }
  .header-nav__link {
    font-size: 3rem;
    line-height: 1.7;
    padding-right: 6rem;
    position: relative;
  }
  .header-nav__link::after {
    position: absolute;
    content: "";
    z-index: 1;
    background: url("../img/common/ico_arrow_btn.svg") no-repeat bottom right/100% 100%;
    width: 4.5rem;
    height: 1.2rem;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 1100px) and (min-width: 768px) {
  .header-nav__link:hover::after {
    animation-name: arrow-btn;
    animation-duration: 0.4s;
    animation-timing-function: cubic-bezier(0.65, 0.01, 0.3, 0.97);
  }
}
@media screen and (max-width: 1100px) {
  .header .hamburger-btn {
    display: flex;
  }
  .header.is-open .header-main {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}
@media screen and (min-width: 768px) and (max-width: 800px) {
  .header-main {
    padding-top: 4rem;
  }
  .header-main .header-tel {
    display: block;
    width: 100%;
    max-width: 70.9rem;
  }
  .header-main .header-tel a {
    font-size: 140%;
  }
  .header-tel--tablet {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header__inner {
    padding: 0 4vw;
  }
  .header-logo {
    height: 16vw;
    gap: 2.6666666667vw;
  }
  .header-logo__link {
    width: 35.2vw;
  }
  .header-logo__slg {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
  .header-main {
    padding: 3rem 0 6rem;
  }
  .header-main .header-tel {
    display: block;
    width: 100%;
    max-width: 79.2vw;
  }
  .header-nav {
    max-width: 79.2vw;
  }
  .header-nav__item {
    padding-bottom: 2.2rem;
  }
  .header-nav-sub {
    padding: 6.6666666667vw 0 6.6666666667vw;
  }
  .header-nav-sub .service-nav {
    padding-right: 0;
  }
  .header-nav-sub__inner {
    margin-left: 0;
  }
  .header-nav__link {
    font-size: 4rem;
    line-height: 1.75;
    padding-right: 7.9rem;
  }
  .header-nav__link::after {
    width: 6.1rem;
    height: 1.6rem;
  }
  .header-tel {
    font-size: 4.6rem;
    line-height: 1.5652173913;
  }
  .header-tel--tablet {
    display: none;
  }
  .header .hamburger-btn {
    width: 8.2666666667vw;
    height: 6.6666666667vw;
    top: 4.6666666667vw;
    right: 4.5333333333vw;
  }
  .header .hamburger-btn span {
    height: 0.8vw;
  }
  .header .hamburger-btn span:nth-child(3) {
    width: 4.5333333333vw;
  }
  .header .hamburger-btn.is-active span:nth-child(1) {
    transform: rotateZ(45deg) translate(2.0666666667vw, 2.0666666667vw);
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .header__inner {
    padding: 0 4vw;
  }
  .header-logo {
    height: 12vw;
    gap: 2.6666666667vw;
  }
  .header-logo__link {
    width: 29.3333333333vw;
  }
  .header-logo__slg {
    font-size: 1.6rem;
  }
  .header-main {
    padding: 1.8rem 0 3rem;
  }
  .header-main__inner {
    gap: 4vw;
  }
  .header-nav {
    padding-right: 3rem;
  }
  .header-nav__item {
    padding-bottom: 1.2rem;
  }
  .header-nav-sub {
    padding: 3.3333333333vw 0;
  }
  .header-nav__link {
    font-size: 2.7rem;
    padding-right: 5.5rem;
  }
  .header-nav__link::after {
    width: 4rem;
    height: 1.05rem;
  }
  .header-tel {
    font-size: 3rem;
  }
  .header .hamburger-btn {
    width: 6.6666666667vw;
    height: 5.0666666667vw;
    top: 3.3333333333vw;
    right: 4vw;
  }
  .header .hamburger-btn span {
    height: 0.5333333333vw;
  }
  .header .hamburger-btn span:nth-child(3) {
    width: 3.7333333333vw;
  }
  .header .hamburger-btn.is-active span:nth-child(1) {
    transform: rotateZ(45deg) translate(1.6vw, 1.6vw);
  }
}
.cta {
  background: #000000;
  color: #FFFFFF;
  padding: 5rem 0;
}
.cta__row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cta__title {
  flex-shrink: 0;
  font-size: 1.8rem;
  line-height: 1.6666666667;
  font-weight: 700;
  width: 17.5rem;
  letter-spacing: 0.05em;
}
.cta__content {
  text-align: center;
  width: max-content;
}
.cta__tel {
  font-family: "DIN Pro", sans-serif;
  font-size: 2.5rem;
  line-height: 1.08;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin-bottom: 1.7rem;
}
.cta__btn a {
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.03em;
  padding: 1rem 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 24.9rem;
  background: #FFFFFF;
  color: #000000;
  border-radius: 4rem;
}

@media screen and (min-width: 981px) and (max-width: 1366px) {
  .cta {
    background: #000000;
    color: #FFFFFF;
    padding: 5rem 0;
  }
  .cta__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cta__title {
    flex-shrink: 0;
    font-size: 1.8rem;
    line-height: 1.6666666667;
    font-weight: 700;
    width: 17.5rem;
    letter-spacing: 0.05em;
  }
  .cta__content {
    text-align: center;
    width: max-content;
  }
  .cta__tel {
    font-family: "DIN Pro", sans-serif;
    font-size: 2.5rem;
    line-height: 1.08;
    font-weight: 700;
    letter-spacing: 0.05em;
    margin-bottom: 1.7rem;
  }
  .cta__btn a {
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 0.03em;
    padding: 1rem 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 24.9rem;
    background: #FFFFFF;
    color: #000000;
    border-radius: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .cta__title {
    flex-shrink: 0;
    font-size: 2.8rem;
    width: 22rem;
  }
  .cta__tel {
    font-size: 3.8rem;
    margin-bottom: 2.5rem;
  }
  .cta__btn a {
    font-size: 2.2rem;
    line-height: 1.9090909091;
    padding: 1rem 2rem;
    min-width: 38rem;
    border-radius: 8rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .cta__title {
    font-size: 2.2rem;
    width: 18rem;
  }
  .cta__tel {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  .cta__btn a {
    font-size: 1.8rem;
    min-width: 30rem;
  }
}
.pagetop {
  position: fixed;
  bottom: 10rem;
  right: 4rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
  width: 4.8rem;
  height: 5.6rem;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 90;
  background: rgba(255, 255, 255, 0.3);
}
.pagetop img {
  width: 100%;
}
.pagetop.is-active {
  opacity: 1;
  visibility: visible;
}
@media screen and (min-width: 768px) {
  .pagetop.is-active:hover {
    opacity: 0.7;
  }
}

.footer {
  background: #FFFFFF;
  padding-bottom: 3rem;
}
.footer-main {
  padding: 10rem 0;
}
.footer-main__row {
  display: flex;
}
.footer-main__col1 {
  width: 19.5rem;
  flex-shrink: 0;
}
.footer-main__col2 {
  flex-grow: 1;
}
.footer-logo {
  max-width: 100%;
}
.footer-nav {
  text-align: center;
  margin-top: 3rem;
}
.footer-nav__item {
  margin-bottom: 1rem;
  line-height: 1;
}
.footer-nav a {
  display: inline-flex;
  font-family: "DIN Pro", sans-serif;
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: 700;
  letter-spacing: 0.1em;
  position: relative;
  padding: 0 1.4rem;
}
.footer-nav a::before {
  position: absolute;
  z-index: 1;
  bottom: -0.2rem;
  left: 1.4rem;
  transform: scaleX(0);
  transform-origin: right;
  content: "";
  width: calc(100% - 2.8rem);
  background: #000000;
  height: 1px;
  transition: transform 0.3s ease;
}
.footer-nav a::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 0.5rem;
  height: 0.8rem;
  content: "";
  z-index: 1;
  background: url("../img/common/ico_arrow_menu_black.svg") no-repeat left center/cover;
}
@media screen and (min-width: 768px) {
  .footer-nav a:hover::before {
    transform: scaleY(1);
    transform-origin: left;
  }
}
.footer-copyright {
  padding: 0 5rem;
  text-align: right;
  letter-spacing: 0.05em;
  font-size: 1.2rem;
  line-height: 1.3333333333;
}
.footer-copyright p + p {
  margin-top: 1rem;
}

@media screen and (min-width: 981px) and (max-width: 1366px) {
  .pagetop {
    right: 2rem;
  }
  .footer-copyright {
    padding: 0 3.5rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .pagetop {
    right: 2rem;
  }
  .footer .service-nav__item {
    width: 17.2rem;
    padding-left: 1.5rem;
  }
  .footer-copyright {
    padding: 0 3.5rem;
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 960px) {
  .footer-main__row {
    justify-content: center;
    gap: 5rem;
  }
  .footer-main__col2 {
    max-width: 36.4rem;
  }
  .footer .service-nav__list {
    row-gap: 3rem;
  }
  .footer .service-nav__item {
    width: 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .pagetop {
    bottom: 4rem;
    right: 4rem;
    width: 5.6rem;
    height: 6rem;
  }
  .footer {
    padding-bottom: 12rem;
  }
  .footer-main {
    padding: 15rem 0 10rem;
  }
  .footer-main__row {
    justify-content: center;
  }
  .footer-main__col1 {
    width: 31.7rem;
  }
  .footer-main__col2 {
    display: none;
  }
  .footer-nav {
    margin-top: 5rem;
  }
  .footer-nav__item {
    margin-bottom: 2rem;
  }
  .footer-nav a {
    font-size: 2.44rem;
    line-height: 1.75;
    padding: 0 2.2rem;
  }
  .footer-nav a::after {
    width: 1rem;
    height: 1.6rem;
  }
  .footer-copyright {
    padding: 0 5rem;
    text-align: left;
    font-size: 2rem;
    line-height: 1.7;
  }
  .footer-copyright p + p {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .pagetop {
    bottom: 3rem;
    right: 3rem;
    width: 3.6rem;
    height: 4.2rem;
  }
  .footer {
    text-align: center;
    padding-bottom: 6rem;
  }
  .footer-main {
    padding: 10rem 0 6rem;
  }
  .footer-main__col1 {
    width: 28rem;
  }
  .footer-nav {
    margin-top: 3rem;
  }
  .footer-nav__item {
    margin-bottom: 1rem;
  }
  .footer-nav a {
    font-size: 2.2rem;
    padding: 0 1.6rem;
  }
  .footer-nav a::after {
    width: 0.7rem;
    height: 1.12rem;
  }
  .footer-copyright {
    padding: 0 3rem;
    font-size: 1.36rem;
    display: inline-block;
    text-align: left;
  }
}
.c-content-page {
  padding-bottom: 14rem;
}
@media screen and (max-width: 767px) {
  .c-content-page {
    padding-bottom: 20rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .c-content-page {
    padding-bottom: 14rem;
  }
}

.section-area {
  max-width: 98rem;
  margin: 0 auto;
  padding-top: 8rem;
}
.section-area__ttl {
  padding: 0.2rem 2.5rem;
  font-size: 1.6rem;
  line-height: 1.625;
  color: #fff;
  font-weight: 700;
  margin-bottom: 3rem;
  background: #000;
}
.section-area__ctn {
  padding: 0 1.5rem;
}
@media screen and (max-width: 767px) {
  .section-area {
    padding-top: 16rem;
  }
  .section-area__ttl {
    padding: 1rem 2.5rem;
    font-size: 3.1rem;
    line-height: 1.2903225806;
    margin-bottom: 4rem;
  }
  .section-area__ctn {
    padding: 0;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .section-area {
    padding-top: 11rem;
  }
  .section-area__ttl {
    padding: 0.4rem 1.5rem;
    font-size: 1.8rem;
    line-height: 1.6666666667;
    margin-bottom: 3rem;
  }
  .section-area__ctn {
    padding: 0;
  }
}

.c-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  top: 0;
  left: 0;
  perspective: 1px;
  z-index: 10002;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
}
.c-loading__bg {
  background: #FFFFFF;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  content: "";
  pointer-events: all;
  transition: 0.2s;
  transition-delay: 0.8s;
}
.c-loading__content {
  padding-bottom: 5%;
  position: relative;
  z-index: 2;
}
.c-loading__logo {
  width: 19.5rem;
  width: 28rem;
  padding: 0;
  margin: 0;
  display: block;
  overflow: hidden;
}
.c-loading__logo img {
  transition: 0.4s;
  transition-delay: 0.1s;
  display: block;
  transform: translate(0, 0);
}
.c-loading__circle {
  margin: 4rem auto 0;
  width: 4rem;
  height: 4rem;
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-left: 4px solid #fff;
  border-radius: 50%;
  animation: loader-rotate 1s linear infinite;
  transition: 0.4s;
  transition-delay: 0s;
}
.c-loading.is-hidden {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
  transition-delay: 0.8s;
}
.c-loading.is-hidden .c-loading__logo img {
  transform: translate(0, -105%) !important;
}
.c-loading.is-hidden .c-loading__circle {
  opacity: 0 !important;
  visibility: hidden !important;
}
.c-loading.is-hidden .c-loading__bg {
  transform: translateY(-101%);
}
@media screen and (max-width: 767px) {
  .c-loading__logo {
    width: 40rem;
  }
  .c-loading__circle {
    margin: 6rem auto 0;
    width: 6rem;
    height: 6rem;
    border-width: 3px;
    border-left-width: 3px;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .c-loading__logo {
    width: 30rem;
  }
  .c-loading__circle {
    margin: 4.4rem auto 0;
    width: 4.4rem;
    height: 4.4rem;
    border-width: 2px;
    border-left-width: 2px;
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(0);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fadeInSpinner {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 900;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.85);
  transition: 0.3s;
}
.c-modal__inner {
  background: #EFEFEF;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.c-modal__body {
  max-width: 90%;
  max-height: 80vh;
  position: relative;
}
.c-modal__close {
  position: absolute;
  top: -0.8rem;
  right: 0;
  transform: translateY(-100%);
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
}
@media screen and (min-width: 768px) {
  .c-modal__close:hover {
    opacity: 0.75;
  }
}
.c-modal__content {
  width: 100%;
  max-height: 80vh;
  overflow: auto;
}
.c-modal__content__inner {
  background: #fff;
}
.c-modal.is-show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.effect-hover {
  --noiseOpacity: 0.15;
  position: relative;
  display: block;
}
.effect-hover__img-wrapper {
  position: relative;
  overflow: hidden;
}
.effect-hover .noise {
  opacity: var(--noiseOpacity);
  background-color: #000;
  will-change: opacity;
  animation-name: effectNoiseAnimationReverse;
  animation-delay: var(--effectNoiseDelayReverse);
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.6, 1);
}
.effect-hover .noise-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(10, 1fr);
  position: absolute;
  top: 0;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
}
@media screen and (min-width: 768px) {
  .effect-hover:hover {
    opacity: 1;
  }
  .effect-hover:hover .noise {
    opacity: 0;
    animation-name: effectNoiseAnimation;
    animation-fill-mode: forwards;
    animation-delay: var(--effectNoiseDelay);
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.6, 1);
  }
  .effect-hover:hover .noise-wrapper {
    box-shadow: inset 0 0 10px rgba(51, 51, 51, 0.1254901961);
  }
}

@keyframes effectNoiseAnimationReverse {
  0% {
    opacity: var(--noiseOpacity);
  }
  100% {
    opacity: 0;
  }
}
@keyframes effectNoiseAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: var(--noiseOpacity);
  }
}
.service-nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.service-nav__item {
  width: 18.4rem;
  max-width: 25%;
  padding-left: 1.7rem;
  text-align: left;
}
.service-nav__head a {
  background: #000000;
  color: #FFFFFF;
  font-size: 1.4rem;
  line-height: 1.5;
  padding: 0.2rem 0rem 0.2rem 2.6rem;
  font-weight: 700;
  display: flex;
  border-radius: 2.5rem;
  letter-spacing: 0.1em;
  position: relative;
}
.service-nav__head a::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.5rem;
  width: 0.5rem;
  height: 0.8rem;
  content: "";
  z-index: 1;
  background: url("../img/common/ico_arrow_menu_white.svg") no-repeat left center/cover;
}
.service-nav__sub {
  margin-top: 1rem;
  padding-left: 1.5rem;
}
.service-nav__sub li {
  line-height: 1;
}
.service-nav__sub li:nth-child(n+2) {
  margin-top: 0.2rem;
}
.service-nav__sub span {
  display: inline-flex;
  font-size: 1.4rem;
  line-height: 1.7857142857;
  font-weight: 700;
  letter-spacing: 0.1em;
  letter-spacing: 0.075em;
  position: relative;
}
.service-nav__sub span::before {
  position: absolute;
  z-index: 1;
  bottom: 0.1rem;
  left: 0;
  transform: scaleX(0);
  transform-origin: right;
  content: none;
  width: 100%;
  background: #000000;
  height: 1px;
  transition: transform 0.3s ease;
}
@media screen and (min-width: 768px) {
  .service-nav__sub span:hover::before {
    transform: scaleY(1);
    transform-origin: left;
  }
}

@media screen and (min-width: 768px) {
  .service-nav__head a {
    transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
    border: 1px solid #000000;
  }
  .service-nav__head a::after {
    transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
  }
  .service-nav__head a:hover {
    background: #FFFFFF;
    color: #000000;
    opacity: 1;
  }
  .service-nav__head a:hover::after {
    background-image: url("../img/common/ico_arrow_menu_black.svg");
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .header-nav-sub .service-nav__head a {
    border: 1px solid #FFFFFF;
  }
  .header-nav-sub .service-nav__head a:hover {
    background: #000000;
    color: #FFFFFF;
  }
  .header-nav-sub .service-nav__head a:hover::after {
    background-image: url("../img/common/ico_arrow_menu_white.svg");
  }
}
@media screen and (max-width: 767px) {
  .service-nav__list {
    justify-content: space-between;
    gap: 6.6666666667vw;
  }
  .service-nav__item {
    width: calc(50% - 3.3333333333vw);
    max-width: calc(50% - 3.3333333333vw);
    padding-left: 0;
  }
  .service-nav__head a {
    font-size: 2.4rem;
    line-height: 1.5833333333;
    padding: 0.1333333333vw 0vw 0.5333333333vw 5.3333333333vw;
    border-radius: 6vw;
  }
  .service-nav__head a::after {
    width: 1rem;
    height: 1.6rem;
    left: 1.8rem;
  }
  .service-nav__sub {
    margin-top: 2.4vw;
    padding-left: 2.4vw;
    padding-bottom: 1.3333333333vw;
  }
  .service-nav__sub li:nth-child(n+2) {
    margin-top: 0.4vw;
  }
  .service-nav__sub span {
    font-size: 2.4rem;
    line-height: 1.75;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .service-nav__list {
    gap: 4vw;
  }
  .service-nav__item {
    width: calc(50% - 2.6666666667vw);
    max-width: calc(50% - 2.6666666667vw);
  }
  .service-nav__head a {
    font-size: 1.6rem;
    padding: 0.4vw 0vw 0.6666666667vw 4.4vw;
  }
  .service-nav__head a::after {
    width: 0.7rem;
    height: 1.12rem;
  }
  .service-nav__sub {
    margin-top: 2vw;
    padding-left: 2.2666666667vw;
  }
  .service-nav__sub span {
    font-size: 1.6rem;
  }
}
.c-fv {
  position: relative;
  z-index: 2;
  margin-bottom: 3rem;
  transition-delay: 0.3s;
}
.c-fv__inner {
  width: 75%;
  max-width: 144rem;
  margin: 0 auto;
  padding: 0 3rem;
}
.c-fv__img {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: block;
  transform: scale(calc(1 - 0.14136 * (var(--scroll-progress) - 1) * -1));
  transform-origin: center center;
  transition: unset !important;
}
.c-fv__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  scale: calc(1 + 0.2 * (var(--scroll-progress) - 1) * -1);
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .c-fv__inner {
    width: 94%;
  }
}
@media screen and (max-width: 767px) {
  .c-fv__inner {
    width: 100%;
  }
  .c-fv__img {
    transform: scale(calc(1 - 0.096 * (var(--scroll-progress) - 1) * -1));
  }
}

.c-btn {
  position: relative;
  display: inline-flex;
  min-width: 29rem;
  background: #000000;
  background: linear-gradient(to right, #000000 0%, #211F20 100%);
  color: #FFFFFF;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.7058823529;
  padding: 1rem 3rem 1rem 3.5rem;
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
}
.c-btn__txt {
  position: relative;
  padding-right: 4.5rem;
  display: block;
  width: 100%;
  text-align: left;
}
.c-btn__txt::after {
  position: absolute;
  top: 50%;
  right: 0;
  content: "";
  width: 3.7rem;
  height: 1rem;
  z-index: 2;
  background: url("../img/common/ico_arrow_btn.svg") no-repeat right bottom/auto 100%;
  transform: translateY(-50%);
}
@media screen and (min-width: 768px) {
  a:hover .c-btn__txt::after {
    animation-name: arrow-btn;
    animation-duration: 0.4s;
    animation-timing-function: cubic-bezier(0.65, 0.01, 0.3, 0.97);
  }
}
@media screen and (min-width: 768px) {
  .c-btn:hover, a:hover .c-btn {
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .c-btn {
    min-width: 48rem;
    font-size: 2.8rem;
    line-height: 1.4642857143;
    padding: 2rem 5rem 2rem 5.8rem;
  }
  .c-btn__txt {
    padding-right: 6.5rem;
  }
  .c-btn__txt::after {
    width: 6.1rem;
    height: 1.6rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .c-btn {
    min-width: 31rem;
    font-size: 1.8rem;
    padding: 1.5rem 3.5rem 1.5rem 4rem;
  }
  .c-btn__txt {
    padding-right: 5.4rem;
  }
  .c-btn__txt::after {
    width: 4rem;
    height: 1.05rem;
  }
}
.c-heading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 20rem;
  margin-bottom: 5.5rem;
  --el-delay: 0.3s;
}
.c-heading-page__headline {
  font-size: 6.8rem;
  line-height: 1.1764705882;
  font-weight: 700;
}
.c-heading-page__sub {
  font-size: 2rem;
  line-height: 1.75;
  font-weight: 700;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .c-heading-page {
    padding-top: 18.3673469388vw;
  }
}
@media screen and (max-width: 767px) {
  .c-heading-page {
    padding-top: 26rem;
    padding-top: 21rem;
    margin-bottom: 5.5rem;
  }
  .c-heading-page__headline {
    font-size: 6.8rem;
  }
  .c-heading-page__sub {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .c-heading-page {
    padding-top: 16rem;
    margin-bottom: 4rem;
  }
  .c-heading-page__headline {
    font-size: 4.7rem;
  }
  .c-heading-page__sub {
    margin-top: 0.5rem;
    font-size: 1.9rem;
  }
}

.c-heading-page-02 {
  font-family: "DIN Pro", sans-serif;
  display: flex;
  padding-top: 20rem;
  margin-bottom: 1rem;
  --el-delay: 0.3s;
}
.c-heading-page-02__headline {
  font-size: 9rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .c-heading-page-02 {
    padding-top: 18.3673469388vw;
  }
}
@media screen and (max-width: 767px) {
  .c-heading-page-02 {
    padding-top: 30rem;
    margin-bottom: 3rem;
  }
  .c-heading-page-02__headline {
    font-size: 7.7rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .c-heading-page-02 {
    padding-top: 22rem;
    margin-bottom: 2rem;
  }
  .c-heading-page-02__headline {
    font-size: 6rem;
  }
}

.c-heading-lv2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 5.5rem;
}
.c-heading-lv2__headline {
  font-size: 4.6rem;
  line-height: 1.3043478261;
  letter-spacing: 0.03em;
  font-weight: 700;
}
.c-heading-lv2__sub {
  font-size: 1.8rem;
  line-height: 1.75;
  font-weight: 400;
  letter-spacing: 0.03em;
}
@media screen and (max-width: 767px) {
  .c-heading-lv2 {
    margin-bottom: 8rem;
  }
  .c-heading-lv2__headline {
    font-size: 5rem;
  }
  .c-heading-lv2__sub {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .c-heading-lv2 {
    margin-bottom: 6rem;
  }
  .c-heading-lv2__headline {
    font-size: 3.6rem;
  }
  .c-heading-lv2__sub {
    font-size: 1.9rem;
  }
}

.c-heading-lv3 {
  font-size: 3rem;
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .c-heading-lv3 {
    font-size: 3.8rem;
    line-height: 1.3684210526;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .c-heading-lv3 {
    font-size: 2.6rem;
    margin-bottom: 2.5rem;
  }
}

.c-heading-lv4 {
  font-size: 1.7rem;
  line-height: 1.7647058824;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-heading-lv4 {
    font-size: 3.2rem;
    line-height: 1.3125;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .c-heading-lv4 {
    font-size: 2.2rem;
    margin-bottom: 3rem;
  }
}

.c-copy, .s-copy {
  font-size: 1.3rem;
  line-height: 1.75;
  font-weight: 400;
  color: #333;
  text-align: justify;
}
@media screen and (max-width: 767px) {
  .c-copy, .s-copy {
    font-size: 2.4rem;
    line-height: 1.75;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .c-copy, .s-copy {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 768px) {
  .s-copy {
    font-size: 1.4rem;
  }
}

.c-link-inline {
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .c-link-inline:hover {
    text-decoration: none;
  }
}

.p-home {
  overflow: hidden;
}
.p-home .bg-fixed {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
}
.p-home .bg-fixed::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: transparent;
  z-index: 1;
}
.p-home .bg-fixed::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 2px solid #FFF;
  content: "";
  content: none;
}
@media screen and (max-width: 767px) {
  .p-home .bg-fixed::after {
    border-width: 1px;
  }
}
.p-home .bg-fixed video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.p-home .layer-main {
  position: relative;
  z-index: 2;
}

.home-mv {
  height: 100vh;
  height: 100svh;
  position: relative;
  padding-top: 10rem;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1025px) {
  .home-mv {
    max-height: 100vw;
  }
}
.home-mv__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  padding-left: 10%;
}
.home-mv__headline {
  position: relative;
  z-index: 2;
  font-size: 5.4rem;
  line-height: 1.7037037037;
  font-size: min(5.4rem, 4.8vh);
  letter-spacing: 0.03em;
  font-weight: 900;
  display: flex;
  flex-direction: row-reverse;
}
.home-mv__headline .txt {
  writing-mode: vertical-rl;
  white-space: nowrap;
  padding: 0 0.5rem;
  transform: rotate(0);
  overflow: unset;
}
.home-mv__copy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 4.6rem;
  font-size: min(4.6rem, 2.3958333333vw);
  line-height: 1.4347826087;
  padding-bottom: 4.6875vw;
  letter-spacing: 0.05em;
}
.home-mv__copy p {
  margin-bottom: 6rem;
  margin-bottom: 2.34375vw;
  display: inline-block;
  color: #FFFFFF;
  position: relative;
  padding: 1.2rem 3rem;
  font-weight: 700;
}
.home-mv__copy p::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 1;
  background: #000000;
  transform: skewX(-15deg);
  transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  clip-path: inset(0 100% 0 0);
}
.home-mv__copy p .txt {
  position: relative;
  z-index: 2;
}
.home-mv__copy.is-show p::before {
  clip-path: inset(0 0 0 0);
}
.home-mv__running-text {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  gap: 3em;
}
.home-mv__running-text ul {
  display: flex;
  width: 150%;
  animation: marquee 30s linear infinite;
  gap: 3em;
}
.home-mv__running-text ul li {
  white-space: nowrap;
  font-size: 19rem;
  line-height: 0.8947368421;
  font-weight: 700;
  color: #EFEFEF;
  letter-spacing: 0;
  width: 50%;
}

.home-intro {
  padding: 16rem 0 18rem;
}
.home-intro__inner {
  max-width: 156rem;
}
.home-intro__headline {
  font-size: 4.7rem;
  line-height: 1.7553191489;
  font-weight: 700;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
}
.home-intro__copy {
  font-size: 1.8rem;
  line-height: 1.75;
  font-weight: 400;
  text-align: right;
}
.home-intro__copy p {
  text-align: left;
  max-width: 96rem;
  display: inline-block;
}

.home-product__slider {
  padding-bottom: 8.8rem;
}
.home-product__item {
  width: 58.2rem;
  padding: 0 1px;
}
.home-product__img {
  width: 100%;
  aspect-ratio: 580/386;
}
.home-product__description {
  width: 90%;
  max-width: 45rem;
  padding: 3rem 4.2rem 4.2rem;
  color: #FFFFFF;
  background: #000000;
  margin-top: -10rem;
  position: relative;
  z-index: 1;
}
.home-product__title {
  font-size: 2.4rem;
  line-height: 1.75;
  font-weight: 700;
  margin-bottom: 1rem;
}
.home-product__copy {
  font-size: 1.4rem;
  line-height: 1.75;
  font-weight: 400;
  letter-spacing: 0.03em;
}
.home-product .splide-pagination {
  position: absolute;
  left: 0;
  bottom: 1.2rem;
  width: 100%;
  font-size: 1.9rem;
  line-height: 1.2631578947;
  font-weight: 400;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-product .splide-pagination span {
  padding: 0 1.2rem;
}
.home-product .splide__arrow {
  background: transparent;
  top: auto;
  bottom: 0;
  transform: translate(0, 0);
  z-index: 2;
  width: 4.4rem;
  height: 4.4rem;
  position: absolute;
  bottom: 0;
  outline: none !important;
}
.home-product .splide__arrow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: no-repeat center center/3rem 1.4rem;
}
.home-product .splide__arrow svg {
  display: none;
}
@media screen and (min-width: 768px) {
  .home-product .splide__arrow {
    background: rgba(255, 255, 255, 0) !important;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
  }
  .home-product .splide__arrow:hover {
    opacity: 0.75;
    background: white !important;
  }
}
.home-product .splide__arrow--prev {
  left: 50%;
  transform: translate(-10rem, 0);
}
.home-product .splide__arrow--prev::after {
  background-image: url("/assets/img/common/ico_arrow_left01.svg");
}
.home-product .splide__arrow--next {
  right: 50%;
  transform: translate(10rem, 0);
}
.home-product .splide__arrow--next::after {
  background-image: url("/assets/img/common/ico_arrow_right01.svg");
}

.home-service {
  padding: 12rem 0 10rem;
}
.home-service__inner {
  max-width: 77rem;
}
.home-service__headline {
  font-size: 10rem;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-service__title {
  font-size: 2.7rem;
  line-height: 1.7592592593;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.5rem;
}
.home-service__copy {
  font-size: 1.4rem;
  line-height: 1.75;
  font-weight: 400;
}
.home-service__list {
  margin-top: 6rem;
  margin-left: -2.7rem;
}
@media screen and (min-width: 768px) {
  .home-service .service-nav__item:nth-child(2) {
    transition-delay: 0.2s;
  }
  .home-service .service-nav__item:nth-child(3) {
    transition-delay: 0.4s;
  }
  .home-service .service-nav__item:nth-child(4) {
    transition-delay: 0.6s;
  }
}
@media screen and (max-width: 767px) {
  .home-service .service-nav__item:nth-child(2n) {
    transition-delay: 0.2s;
  }
}
.home-service__btn {
  margin: 6rem 0 0;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1700px) {
  .home-vision {
    padding-right: 2.0833333333vw;
  }
}
.home-vision__inner {
  max-width: 178rem;
}
.home-vision__row {
  display: flex;
  align-items: center;
  gap: 6rem;
  flex-direction: row-reverse;
}
.home-vision__img {
  width: 56.2209302326%;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.home-vision__img .is-frame {
  opacity: 0;
}
.home-vision__img .is-part {
  position: absolute;
  z-index: 1;
  width: 46.3rem;
  width: 47.880041365%;
}
.home-vision__img .is-part01 {
  left: 0;
  bottom: 0;
}
.home-vision__img .is-part02 {
  right: 0;
  top: 0;
}
.home-vision__description {
  flex-grow: 1;
  position: relative;
  z-index: 2;
}
.home-vision__headline {
  font-size: 4.5rem;
  line-height: 1.3333333333;
  font-weight: 700;
  margin-bottom: 3rem;
  margin-right: -20rem;
}
.home-vision__copy {
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 400;
  max-width: 68.2rem;
}
.home-vision__btn {
  margin-top: 5rem;
}

.home-headline-en {
  font-size: 19rem;
  font-size: min(19rem, 9.8958333333vw);
  line-height: 0.9894736842;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.home-movie {
  margin-top: 15rem;
}
.home-movie__inner {
  max-width: 171.2rem;
}
.home-movie__content {
  position: relative;
}
.home-movie__headline {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
.home-movie__video {
  position: absolute;
  top: 9rem;
  right: -3rem;
  width: 92.8rem;
  z-index: 1;
  aspect-ratio: 928/705;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
}
.home-movie__video::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: transparent;
  z-index: 1;
}
.home-movie__video::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 2px solid #FFF;
  content: "";
  content: none;
}
@media screen and (max-width: 767px) {
  .home-movie__video::after {
    border-width: 1px;
  }
}
.home-movie__video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-movie__description {
  max-width: 89rem;
  margin: 10rem auto 0;
  background: #EFEFEF;
  padding: 9rem 12rem;
  position: relative;
  z-index: 2;
}
.home-movie__title {
  font-size: 3rem;
  line-height: 1.75;
  font-weight: 700;
  margin-bottom: 2rem;
}
.home-movie__copy {
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 400;
}

.home-design {
  max-width: 131rem;
  margin: 19rem auto 0;
  margin: 19rem auto 12rem;
}
.home-design__row {
  display: flex;
  align-items: center;
  gap: 7.84%;
}
.home-design__img {
  width: 41.2rem;
  flex-shrink: 0;
  aspect-ratio: 412/507;
  overflow: hidden;
}
.home-design__description {
  flex-grow: 1;
}
.home-design__copy {
  max-width: 48rem;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 400;
}

.home-meeting__inner {
  max-width: 182.6rem;
  margin: 0 auto;
}
.home-meeting__row {
  display: flex;
  flex-direction: row-reverse;
  gap: 5.0962627407%;
  align-items: center;
}
.home-meeting__img {
  width: 47.3386183465%;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.home-meeting__img .is-frame {
  opacity: 0;
}
.home-meeting__img .is-part {
  position: absolute;
  z-index: 1;
}
.home-meeting__img .is-part01 {
  left: 0;
  bottom: 0;
  width: 37.4rem;
  width: 44.7368421053%;
}
.home-meeting__img .is-part02 {
  right: 0;
  top: 0;
  width: 53.4rem;
  width: 63.8755980861%;
}
.home-meeting__description {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 5%;
}
.home-meeting__description__inner {
  width: max-content;
}
.home-meeting__title {
  font-size: 3rem;
  line-height: 1.75;
  font-weight: 700;
  margin-bottom: 2rem;
}
.home-meeting__copy {
  max-width: 38.5rem;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 400;
}

.home-web {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.home-web__inner {
  max-width: 105rem;
}
.home-web__product {
  margin-top: -0.9rem;
  width: 100vw;
  overflow: hidden;
}
.home-web__product li {
  pointer-events: none;
  position: relative;
  width: 100%;
  height: 15rem;
}
.home-web__product li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: repeat-x;
  background-size: 189rem;
  animation: 25s linear infinite scroll_gallery;
  z-index: 1;
}
.home-web__product li:nth-child(1) span {
  background-image: url("../img/top/web_group01.jpg");
}
.home-web__product li:nth-child(2) span {
  background-image: url("../img/top/web_group02.jpg");
}
.home-web__product li:nth-child(3) span {
  background-image: url("../img/top/web_group03.jpg");
}
.home-web__product li:nth-child(2n) span {
  animation-direction: reverse;
}
.home-web__product li:nth-child(n+2) {
  margin-top: 0.5rem;
}
.home-web__lead {
  max-width: 77.6rem;
  margin: 6rem auto 0;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 400;
}
.home-web__btn {
  margin-top: 6rem;
  text-align: center;
}
.home-web__list {
  padding-top: 12rem;
  max-width: 74.6rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: 8.8rem;
  column-gap: 8.8471849866%;
}
.home-web__item {
  width: calc(50% - 3.3rem);
}
.home-web__item:nth-child(2n) {
  transition-delay: 0.2s;
}
.home-web-product__thumbnail {
  margin-bottom: 3rem;
}
.home-web-product__thumbnail picture {
  display: block;
  overflow: hidden;
  aspect-ratio: 340/200;
}
.home-web-product__thumbnail img {
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
}
.home-web-product__copy {
  font-size: 1.4rem;
  line-height: 1.75;
  font-weight: 400;
  letter-spacing: 0;
}
.home-web-product__btn {
  margin-top: 3rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .home-web__link:hover {
    opacity: 1;
  }
  .home-web__link:hover .home-web-product__thumbnail img {
    transform: scale(1.05);
  }
}

@media screen and (min-width: 981px) and (max-width: 1500px) {
  .home-product__item {
    width: 50rem;
  }
  .home-product__description {
    padding: 2rem 3rem 3rem;
    margin-top: -9rem;
  }
  .home-product__title {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 981px) and (max-width: 1366px) {
  .home-mv__inner {
    gap: 5.8565153734vw;
  }
  .home-mv__headline {
    font-size: 3.9rem;
    font-size: min(3.9rem, 3.6448598131vw);
  }
  .home-mv__headline .txt {
    padding: 0 0.1464128843vw;
  }
  .home-mv__copy {
    font-size: 4.1rem;
    font-size: min(4.1rem, 3.0014641288vw);
    line-height: 1.6097560976;
    padding-bottom: 7.3206442167vw;
  }
  .home-mv__copy p {
    margin-bottom: 2.6041666667vw;
    padding: 0.7320644217vw 2.196193265vw;
  }
  .home-mv__running-text ul li {
    font-size: 12.298682284vw;
    line-height: 0.88;
  }
  .home-intro {
    padding: 16rem 0 18rem;
  }
  .home-intro__inner {
    max-width: 122rem;
  }
  .home-intro__headline {
    font-size: 4.7rem;
    font-size: min(4.7rem, 3.4407027818vw);
    margin-bottom: 6rem;
  }
  .home-intro__copy p {
    max-width: 96rem;
    width: 80.5270863836vw;
  }
  .home-product__item {
    width: 46rem;
  }
  .home-service {
    padding: 12rem 0 16rem;
  }
  .home-vision__inner {
    max-width: 128.6rem;
  }
  .home-vision__row {
    align-items: flex-start;
    gap: 0;
    margin-right: calc(-2.9282576867vw - 3rem);
  }
  .home-vision__img {
    width: 60.8024691358%;
    margin-top: 12rem;
  }
  .home-vision__img .is-part {
    width: 37.7rem;
    width: 47.8426395939%;
  }
  .home-vision__description {
    margin-right: -30rem;
  }
  .home-vision__headline {
    margin-bottom: 3rem;
    padding-right: 0;
  }
  .home-headline-en {
    font-size: 16rem;
    font-size: min(16rem, 11.7130307467vw);
  }
  .home-movie {
    margin-top: 16rem;
  }
  .home-movie__inner {
    max-width: 171.2rem;
  }
  .home-movie__content {
    margin: 0 -3rem;
  }
  .home-movie__headline {
    margin-left: -1.0980966325vw;
  }
  .home-movie__video {
    top: 0.7320644217vw;
    right: -0.7320644217vw;
    width: 67.9355783309vw;
  }
  .home-movie__description {
    max-width: 89rem;
    width: 73.2064421669vw;
    margin: 5.8565153734vw auto 0;
    padding: 6.588579795vw 8.78477306vw;
    transform: translateX(-3.2942898975vw);
  }
  .home-design {
    margin-top: 18rem;
    max-width: 103.4rem;
  }
  .home-design__row {
    gap: 8.7040618956%;
  }
  .home-meeting__inner {
    margin: 0 -3rem;
  }
  .home-meeting__row {
    gap: 0;
    align-items: flex-start;
  }
  .home-meeting__img {
    width: 55.710102489%;
  }
  .home-meeting__img .is-part01 {
    width: 34rem;
    width: 44.6780551905%;
  }
  .home-meeting__img .is-part02 {
    width: 48.6rem;
    width: 63.8633377135%;
  }
  .home-meeting__description {
    padding-top: 7rem;
    padding-left: 7.3206442167vw;
    padding-bottom: 0;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
    margin-right: -2rem;
  }
  .home-web__inner {
    max-width: 104.6rem;
  }
  .home-web__product {
    margin-top: -0.9rem;
  }
}
@media screen and (min-width: 981px) and (max-width: 1200px) {
  .home-product__item {
    width: 40rem;
  }
  .home-product__description {
    padding: 1.5rem 2rem;
    margin-top: -8rem;
  }
  .home-product__title {
    font-size: 2rem;
  }
  .home-product__copy {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .home-mv__inner {
    gap: 8.1632653061vw;
    padding-left: 7%;
  }
  .home-mv__headline {
    font-size: 3.7rem;
    font-size: min(3.7rem, 3.4579439252vw);
  }
  .home-mv__headline .txt {
    padding: 0 0.2040816327vw;
  }
  .home-mv__copy {
    font-size: 3.06rem;
    font-size: min(3.06rem, 3.1224489796vw);
    line-height: 1.4052287582;
    padding-bottom: 10.2040816327vw;
  }
  .home-mv__copy p {
    margin-bottom: 2.6041666667vw;
    padding: 1.0204081633vw 3.0612244898vw;
  }
  .home-mv__running-text ul li {
    font-size: 12.7551020408vw;
    line-height: 0.88;
  }
  .home-intro {
    padding: 14rem 0 14rem 1.0204081633vw;
  }
  .home-intro__inner {
    max-width: 93rem;
  }
  .home-intro__headline {
    font-size: 4.4rem;
    font-size: min(4.4rem, 4.4897959184vw);
    margin-bottom: 5rem;
  }
  .home-intro__copy {
    font-size: 1.6rem;
    line-height: 1.75;
  }
  .home-intro__copy p {
    max-width: 80.4rem;
    width: 85.7142857143vw;
  }
  .home-service {
    padding: 10rem 0 14rem;
  }
  .home-service__headline {
    font-size: 10.2040816327vw;
  }
  .home-vision__inner {
    max-width: 92rem;
  }
  .home-vision__row {
    align-items: flex-start;
    gap: 0;
    margin-right: calc(-4.0816326531vw - 3rem);
  }
  .home-vision__img {
    width: 76.0869565217%;
    margin-top: 26rem;
  }
  .home-vision__img .is-part {
    width: 33.5rem;
    width: 47.8571428571%;
  }
  .home-vision__description {
    margin-right: -50rem;
  }
  .home-vision__headline {
    margin-bottom: 3rem;
    padding-right: 0;
    font-size: 4.5918367347vw;
  }
  .home-headline-en {
    font-size: 12rem;
    font-size: min(12rem, 12.2448979592vw);
    font-size: 11.8rem;
    font-size: min(11.8rem, 12.0408163265vw);
  }
  .home-movie {
    margin-top: 14rem;
  }
  .home-movie__inner {
    max-width: 100%;
  }
  .home-movie__content {
    margin: 0 -3rem;
  }
  .home-movie__headline {
    margin-left: -1.1224489796vw;
    letter-spacing: -0.02em;
  }
  .home-movie__video {
    top: 8.1632653061vw;
    right: 0vw;
    width: 75.7142857143vw;
  }
  .home-movie__description {
    max-width: 89rem;
    width: 102.0408163265vw;
    margin: 14.2857142857vw 0 0;
    padding: 9.1836734694vw 12.2448979592vw;
  }
  .home-design {
    margin-top: 14rem;
    margin-bottom: 14rem;
    max-width: 100%;
  }
  .home-design__row {
    margin-left: -3rem;
    margin-right: 1.0204081633vw;
    gap: 5.3191489362%;
  }
  .home-meeting__row {
    margin-right: -3rem;
    gap: 0;
    align-items: flex-start;
  }
  .home-meeting__img {
    width: 78.2105263158%;
    margin-top: 14rem;
  }
  .home-meeting__img .is-part01 {
    width: 33.2rem;
    width: 44.6837146703%;
  }
  .home-meeting__img .is-part02 {
    width: 47.4rem;
    width: 63.7954239569%;
  }
  .home-meeting__description {
    padding-left: 0;
    padding-bottom: 0;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
    margin-right: -40rem;
  }
  .home-web__inner {
    max-width: 104.6rem;
  }
  .home-web__product {
    margin-top: -0.9rem;
  }
}
@media screen and (max-height: 700px) and (orientation: landscape) {
  .home-mv {
    height: auto;
    padding-top: 11.5rem;
    padding-bottom: 10rem;
  }
}
@media screen and (max-height: 700px) and (orientation: landscape) and (min-width: 1025px) {
  .home-mv {
    max-height: auto;
  }
}
@media screen and (max-height: 700px) and (orientation: landscape) {
  .home-mv__inner {
    gap: 7rem;
  }
  .home-mv__headline {
    font-size: 5.4rem;
    line-height: 1.7037037037;
    font-size: min(4.6rem, 5vw);
    letter-spacing: 0em;
  }
  .home-mv__headline .txt {
    padding: 0 0.3rem;
  }
}
@media screen and (max-width: 767px) {
  .home-mv {
    padding-top: calc(16vw + 6.44329897vw);
  }
  .home-mv__inner {
    gap: 7rem;
    padding-left: 0;
    align-items: flex-start;
  }
  .home-mv__headline {
    font-size: 5.8rem;
    line-height: 1.5862068966;
  }
  .home-mv__headline .txt {
    padding: 0 0.5rem;
  }
  .home-mv__copy {
    font-size: 3.9rem;
    line-height: 1.3333333333;
    padding-bottom: 0;
    letter-spacing: 0.1em;
    writing-mode: vertical-rl;
    white-space: nowrap;
    gap: 2.8rem;
  }
  .home-mv__copy p {
    margin-bottom: 6rem;
    margin-bottom: 2.34375vw;
    padding: 3rem 1.5rem;
  }
  .home-mv__copy p::before {
    transform: skewX(0);
    transform: skewY(-15deg);
    clip-path: inset(0 0 100% 0);
  }
  .home-mv__copy .js-spline-text.anm-textEn span {
    transform: translate(0, 0);
    opacity: 0;
  }
  .home-mv__copy.is-show p::before {
    clip-path: inset(0 0 0 0);
  }
  .home-mv__copy.is-show .js-spline-text.anm-textEn span {
    opacity: 1;
    transform: translate(0, 0);
  }
  .home-mv__running-text ul li {
    font-size: 19rem;
  }
  .home-intro {
    padding: 14rem 3rem 18rem;
  }
  .home-intro__inner {
    max-width: 100%;
  }
  .home-intro__headline {
    font-size: 4rem;
    line-height: 1.75;
    margin-bottom: 5rem;
    margin-right: -4rem;
  }
  .home-intro__copy {
    font-size: 2.4rem;
    line-height: 1.75;
    text-align: left;
  }
  .home-intro__copy p {
    max-width: 100%;
  }
  .home-product__description {
    width: 100%;
    max-width: 100%;
    padding: 4rem 5rem 5rem;
    margin-top: 0;
  }
  .home-product__title {
    font-size: 3.1rem;
    line-height: 1.7419354839;
    margin-bottom: 1rem;
  }
  .home-product__copy {
    font-size: 2.2rem;
    line-height: 1.75;
  }
  .home-product .splide-pagination {
    bottom: 2rem;
    font-size: 2rem;
    line-height: 1.2;
    font-size: 2.4rem;
    line-height: 1;
  }
  .home-product .splide-pagination span {
    padding: 0 1.2rem;
  }
  .home-product .splide__arrow {
    width: 6rem;
    height: 6rem;
  }
  .home-product .splide__arrow::after {
    background-size: 3.4rem 1.6rem;
  }
  .home-product .splide__arrow--prev {
    transform: translate(-12rem, 0);
  }
  .home-product .splide__arrow--next {
    transform: translate(12rem, 0);
  }
  .home-service {
    padding: 10rem 3rem 18rem;
  }
  .home-service__inner {
    max-width: 100%;
  }
  .home-service__headline {
    font-size: 10rem;
    line-height: 1.2;
  }
  .home-service__title {
    font-size: 2.7rem;
    line-height: 1.7592592593;
    margin-bottom: 6rem;
  }
  .home-service__copy {
    font-size: 2.4rem;
    line-height: 1.75;
  }
  .home-service__list {
    margin-top: 10rem;
    margin-left: 0rem;
  }
  .home-service__btn {
    margin: 10rem 0 0;
  }
  .home-vision__inner {
    max-width: 100%;
  }
  .home-vision__row {
    flex-direction: column;
    gap: 10rem;
  }
  .home-vision__img {
    width: 100%;
  }
  .home-vision__img .is-part {
    width: 33rem;
    width: 47.8260869565%;
  }
  .home-vision__description {
    padding: 0 3rem;
  }
  .home-vision__headline {
    font-size: 4.4rem;
    margin-bottom: 5rem;
    margin-right: -3rem;
  }
  .home-vision__copy {
    font-size: 2.4rem;
    line-height: 1.75;
    max-width: 100%;
  }
  .home-vision__btn {
    display: flex;
    justify-content: center;
    margin-top: 10rem;
  }
  .home-headline-en {
    font-size: 9rem;
    font-size: min(9rem, 12vw);
  }
  .home-movie {
    margin-top: 18rem;
  }
  .home-movie__inner {
    margin: 0 -3rem;
  }
  .home-movie__headline {
    margin-left: -0.8rem;
    margin-right: -2rem;
  }
  .home-movie__video {
    top: 7.6rem;
    right: -0.1rem;
    width: 63.8rem;
    aspect-ratio: 638/485;
  }
  .home-movie__description {
    width: 63rem;
    padding: 5rem 3rem;
    margin: 18rem 0 0;
  }
  .home-movie__title {
    font-size: 4rem;
    line-height: 1.5;
    margin-bottom: 4rem;
  }
  .home-movie__copy {
    font-size: 2.4rem;
    line-height: 1.75;
  }
  .home-design {
    max-width: 100%;
    margin: 18rem auto;
  }
  .home-design__row {
    display: flex;
    flex-direction: column;
    gap: 10rem;
  }
  .home-design__description {
    padding: 0 3rem;
  }
  .home-design__copy {
    max-width: 100%;
    font-size: 2.4rem;
    line-height: 1.75;
  }
  .home-meeting__inner {
    max-width: 100%;
  }
  .home-meeting__row {
    display: flex;
    flex-direction: column;
    gap: 10rem;
  }
  .home-meeting__img {
    width: calc(100% + 3.2rem);
    margin-right: -3.2rem;
  }
  .home-meeting__img .is-part01 {
    width: 32.3rem;
    width: 44.7368421053%;
  }
  .home-meeting__img .is-part02 {
    width: 46.1rem;
    width: 63.8504155125%;
  }
  .home-meeting__description {
    padding: 0 3rem;
  }
  .home-meeting__description__inner {
    width: 100%;
  }
  .home-meeting__title {
    font-size: 4rem;
    line-height: 1.5;
    margin-bottom: 5rem;
  }
  .home-meeting__copy {
    max-width: 100%;
    font-size: 2.4rem;
    line-height: 1.75;
  }
  .home-web {
    padding: 18rem 0;
  }
  .home-web__inner {
    max-width: 105rem;
  }
  .home-web__product {
    margin-top: -0.3rem;
  }
  .home-web__lead {
    max-width: 100%;
    margin: 10rem 3rem 0;
    font-size: 2.4rem;
    line-height: 1.75;
  }
  .home-web__btn {
    margin-top: 10rem;
    text-align: center;
  }
  .home-web__list {
    padding-top: 18rem;
    max-width: 75rem;
    margin: 0 -3rem;
    row-gap: 10rem;
    column-gap: 0.4rem;
  }
  .home-web__item {
    width: calc(50% - 0.2rem);
  }
  .home-web-product__thumbnail picture {
    aspect-ratio: 374/374;
  }
  .home-web-product__copy {
    font-size: 2.2rem;
    line-height: 1.75;
    padding: 0 2.6rem;
  }
  .home-web-product__btn {
    margin-top: 4rem;
  }
  .home-web-product__btn .c-btn {
    min-width: 100%;
    font-size: 2.2rem;
    line-height: 1.5454545455;
    padding: 1.5rem 3.6rem 1.5rem 4.5rem;
  }
  .home-web-product__btn .c-btn__txt {
    padding-right: 5rem;
  }
  .home-web-product__btn .c-btn__txt::after {
    width: 4.7rem;
    height: 1.2rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .home-mv {
    padding-top: calc(13.3333333333vw + 2vw);
    height: auto;
  }
  .home-mv__inner {
    gap: 5rem;
    padding-bottom: 5.3333333333vw;
  }
  .home-mv__headline {
    font-size: 6.4rem;
    font-size: min(6.4rem, 4.7976011994svh);
  }
  .home-mv__headline .txt {
    padding: 0 0.5rem;
  }
  .home-mv__copy {
    font-size: 4.2rem;
    font-size: min(4.2rem, 3.1484257871svh);
    gap: 2rem;
  }
  .home-mv__copy p {
    margin-bottom: 0;
    padding: 2.5rem 1.2rem 1.5rem;
  }
  .home-mv__running-text ul li {
    font-size: 10rem;
  }
  .home-intro {
    padding: 10rem 2rem 14rem;
  }
  .home-intro__headline {
    font-size: 2.7rem;
    margin-bottom: 2.5rem;
    margin-right: -3rem;
  }
  .home-intro__copy {
    font-size: 1.6rem;
  }
  .home-product__description {
    padding: 2.5rem 3rem 3rem;
  }
  .home-product__title {
    font-size: 2.2rem;
  }
  .home-product__copy {
    font-size: 1.6rem;
  }
  .home-product__item {
    width: 40rem;
  }
  .home-product .swiper-pagination {
    font-size: 1.8rem;
  }
  .home-product .swiper-button-prev,
  .home-product .swiper-button-next {
    width: 5.4rem;
    height: 5.4rem;
  }
  .home-service {
    padding: 10rem 2rem 14rem;
  }
  .home-service__headline {
    font-size: 6rem;
  }
  .home-service__title {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
  .home-service__copy {
    font-size: 1.6rem;
  }
  .home-service__list {
    margin: 5rem 7% 0;
  }
  .home-service__btn {
    margin: 5rem 0 0;
  }
  .home-vision__row {
    gap: 5rem;
  }
  .home-vision__img {
    max-width: 94%;
  }
  .home-vision__description {
    padding: 0 2rem;
  }
  .home-vision__headline {
    font-size: 2.7rem;
    margin-bottom: 2.5rem;
  }
  .home-vision__copy {
    font-size: 1.6rem;
  }
  .home-vision__btn {
    margin-top: 5rem;
  }
  .home-headline-en {
    font-size: 9rem;
    font-size: min(9rem, 12vw);
    letter-spacing: 0;
  }
  .home-movie {
    margin-top: 14rem;
  }
  .home-movie__inner {
    margin: 0 -3rem;
  }
  .home-movie__headline {
    margin-left: -0.8rem;
    margin-right: -2rem;
  }
  .home-movie__video {
    top: 8rem;
  }
  .home-movie__description {
    width: 62rem;
    margin: 14rem 0 0;
    padding: 4rem 3rem;
  }
  .home-movie__title {
    font-size: 2.7rem;
    margin-bottom: 2.5rem;
  }
  .home-movie__copy {
    font-size: 1.6rem;
  }
  .home-design {
    margin: 14rem auto;
  }
  .home-design__row {
    gap: 5rem;
  }
  .home-design__img {
    max-width: 36rem;
  }
  .home-design__description {
    padding: 0 2rem;
  }
  .home-design__copy {
    font-size: 1.6rem;
  }
  .home-meeting__row {
    gap: 5rem;
  }
  .home-meeting__description {
    padding: 0 2rem;
  }
  .home-meeting__img {
    max-width: 94%;
    margin: 0 auto;
  }
  .home-meeting__title {
    font-size: 2.7rem;
    margin-bottom: 2.5rem;
  }
  .home-meeting__copy {
    font-size: 1.6rem;
  }
  .home-web {
    padding: 14rem 0;
  }
  .home-web__inner {
    max-width: 100rem;
  }
  .home-web__product {
    margin-top: -0.6rem;
  }
  .home-web__product li {
    height: 12rem;
  }
  .home-web__lead {
    margin: 5rem 2rem 0;
    font-size: 1.6rem;
  }
  .home-web__btn {
    margin-top: 5rem;
  }
  .home-web__list {
    padding-top: 14rem;
    max-width: calc(100% + 2rem);
    margin: 0 -1rem;
    row-gap: 6rem;
    column-gap: 2rem;
  }
  .home-web__item {
    width: calc(50% - 1rem);
  }
  .home-web-product__thumbnail {
    margin-bottom: 2.5rem;
  }
  .home-web-product__copy {
    font-size: 1.6rem;
    padding: 0 1rem;
  }
  .home-web-product__btn {
    margin-top: 2.5rem;
  }
  .home-web-product__btn .c-btn {
    font-size: 1.8rem;
    padding: 1.5rem 3.5rem 1.5rem 4rem;
  }
  .home-web-product__btn .c-btn__txt {
    padding-right: 5.5rem;
  }
  .home-web-product__btn .c-btn__txt::after {
    width: 4rem;
    height: 1.05rem;
  }
}
.p-service {
  position: relative;
}
.p-service .layer-main {
  position: relative;
  z-index: 2;
}
.p-service .fv-deco {
  position: absolute;
  top: -10.5rem;
  width: 290.3rem;
  left: 50%;
  transform: translateX(calc(-50% - 5.8rem));
  z-index: 2;
  pointer-events: none;
  transition-delay: 1s;
}
.p-service .fv-deco__bg {
  opacity: 0;
}
.p-service .fv-deco__el {
  position: absolute;
  z-index: 2;
}
.p-service .fv-deco__el.el-l01 {
  width: 106.5478px;
  width: 3.6702652429%;
  top: 914px;
  top: 76.4214046823%;
  left: 931px;
  left: 32.0702721323%;
}
.p-service .fv-deco__el.el-l02 {
  width: 174.2199px;
  width: 6.0013744402%;
  top: 464px;
  top: 38.7959866221%;
  left: 954px;
  left: 32.8625559766%;
}
.p-service .fv-deco__el.el-l03 {
  width: 17.2745px;
  width: 0.5950568378%;
  top: 757px;
  top: 63.2943143813%;
  left: 921px;
  left: 31.7258008956%;
}
.p-service .fv-deco__el.el-l04 {
  width: 106.5478px;
  width: 3.6702652429%;
  top: 474px;
  top: 39.6321070234%;
  left: 852px;
  left: 29.3489493627%;
}
.p-service .fv-deco__el.el-l05 {
  width: 27.4422px;
  width: 0.945304857%;
  top: 345px;
  top: 28.8461538462%;
  left: 893px;
  left: 30.761281433%;
}
.p-service .fv-deco__el.el-l06 {
  width: 129.6381px;
  width: 4.4656596624%;
  top: 715px;
  top: 59.7826086957%;
  left: 742px;
  left: 25.5597657596%;
}
.p-service .fv-deco__el.el-l07 {
  width: 158.8294px;
  width: 5.4712159835%;
  top: 308px;
  top: 25.7525083612%;
  left: 666px;
  left: 22.941784361%;
}
.p-service .fv-deco__el.el-l08 {
  width: 123.7541px;
  width: 4.2629727868%;
  bottom: 12px;
  bottom: 1.0033444816%;
  left: 603px;
  left: 20.7716155701%;
}
.p-service .fv-deco__el.el-l09 {
  width: 219.3968px;
  width: 7.5575887013%;
  top: 792px;
  top: 66.220735786%;
  left: 470px;
  left: 16.1901481226%;
}
.p-service .fv-deco__el.el-l10 {
  width: 400.1568px;
  width: 13.7842507751%;
  top: 437px;
  top: 36.5384615385%;
  left: 471px;
  left: 16.2245952463%;
}
.p-service .fv-deco__el.el-l11 {
  --animate-duration: 3s;
  width: 152.3843px;
  width: 5.2492008267%;
  top: 249px;
  top: 20.8193979933%;
  left: 498px;
  left: 17.1546675853%;
}
.p-service .fv-deco__el.el-l12 {
  width: 78.9368px;
  width: 2.7191457113%;
  top: 617px;
  top: 51.5886287625%;
  left: 432px;
  left: 14.8811574234%;
}
.p-service .fv-deco__el.el-l13 {
  width: 400.157px;
  width: 13.7842576645%;
  top: 602px;
  top: 50.3344481605%;
  left: 0px;
  left: 0%;
}
.p-service .fv-deco__el.el-l14 {
  width: 271.6834px;
  width: 9.3587116776%;
  top: 350px;
  top: 29.2642140468%;
  left: 106px;
  left: 3.6513951085%;
}
.p-service .fv-deco__el.el-l15 {
  width: 13.2884px;
  width: 0.4577471581%;
  bottom: 0px;
  bottom: 0%;
  left: 794px;
  left: 27.3510161901%;
}
.p-service .fv-deco__el.el-r01 {
  width: 81.8745px;
  width: 2.8203410265%;
  top: 346px;
  top: 28.9297658863%;
  right: 952px;
  right: 32.7936617292%;
}
.p-service .fv-deco__el.el-r02 {
  width: 13.2884px;
  width: 0.4577471581%;
  top: 466px;
  top: 38.9632107023%;
  right: 1015px;
  right: 34.9638305202%;
}
.p-service .fv-deco__el.el-r03 {
  width: 13.2884px;
  width: 0.4577471581%;
  top: 586px;
  top: 48.9966555184%;
  right: 927px;
  right: 31.9324836376%;
}
.p-service .fv-deco__el.el-r04 {
  width: 17.2743px;
  width: 0.5950499483%;
  top: 752px;
  top: 62.8762541806%;
  right: 904px;
  right: 31.1401997933%;
}
.p-service .fv-deco__el.el-r05 {
  width: 70.685px;
  width: 2.4348949363%;
  top: 221px;
  top: 18.4782608696%;
  right: 800px;
  right: 27.5576989321%;
}
.p-service .fv-deco__el.el-r06 {
  width: 17.2745px;
  width: 0.5950568378%;
  top: 304px;
  top: 25.4180602007%;
  right: 788px;
  right: 27.1443334482%;
}
.p-service .fv-deco__el.el-r07 {
  width: 158.8293px;
  width: 5.4712125388%;
  top: 795px;
  top: 66.4715719064%;
  right: 740px;
  right: 25.4908715122%;
}
.p-service .fv-deco__el.el-r08 {
  width: 169.1172px;
  width: 5.8256011023%;
  top: 332px;
  top: 27.7591973244%;
  right: 595px;
  right: 20.4960385808%;
}
.p-service .fv-deco__el.el-r09 {
  width: 171.3224px;
  width: 5.9015638994%;
  top: 553px;
  top: 46.237458194%;
  right: 614px;
  right: 21.1505339304%;
}
.p-service .fv-deco__el.el-r10 {
  width: 106.5463px;
  width: 3.6702135722%;
  top: 840px;
  top: 70.2341137124%;
  right: 595px;
  right: 20.4960385808%;
}
.p-service .fv-deco__el.el-r11 {
  width: 172.019px;
  width: 5.9255597658%;
  bottom: 100px;
  bottom: 8.3612040134%;
  right: 512px;
  right: 17.6369273166%;
}
.p-service .fv-deco__el.el-r12 {
  width: 400.1568px;
  width: 13.7842507751%;
  top: 0px;
  top: 0%;
  right: 153px;
  right: 5.2704099208%;
}
.p-service .fv-deco__el.el-r13 {
  width: 39.0933px;
  width: 1.3466517396%;
  top: 448px;
  top: 37.4581939799%;
  right: 482px;
  right: 16.6035136066%;
}
.p-service .fv-deco__el.el-r14 {
  width: 17.2745px;
  width: 0.5950568378%;
  top: 586px;
  top: 48.9966555184%;
  right: 518px;
  right: 17.8436100586%;
}
.p-service .fv-deco__el.el-r15 {
  width: 36.6545px;
  width: 1.2626420944%;
  top: 798px;
  top: 66.7224080268%;
  right: 525px;
  right: 18.0847399242%;
}
.p-service .fv-deco__el.el-r16 {
  width: 17.2745px;
  width: 0.5950568378%;
  bottom: 40px;
  bottom: 3.3444816054%;
  right: 511px;
  right: 17.6024801929%;
}
.p-service .fv-deco__el.el-r17 {
  width: 174.7609px;
  width: 6.0200103341%;
  top: 602px;
  top: 50.3344481605%;
  right: 330px;
  right: 11.3675508095%;
}
.p-service .fv-deco__el.el-r18 {
  width: 208.5819px;
  width: 7.1850465036%;
  top: 212px;
  top: 17.7257525084%;
  right: 0px;
  right: 0%;
}
.p-service .fv-deco__el.el-r19 {
  width: 176.7552px;
  width: 6.0887082329%;
  top: 623px;
  top: 52.0903010033%;
  right: 86px;
  right: 2.9624526352%;
}
.p-service .fv-deco__el.el-r20 {
  width: 203.4181px;
  width: 7.0071684464%;
  bottom: 172px;
  bottom: 14.381270903%;
  right: 140px;
  right: 4.8225973131%;
}
.p-service .fv {
  position: relative;
  z-index: 3;
}
.p-service .fv__inner {
  perspective: 100rem;
}
.p-service .fv-img {
  max-width: 88rem;
  margin: 0 auto;
  transition-delay: 0.5s;
}
.p-service .service-menu {
  padding-top: 16rem;
}
.p-service .service-menu__list {
  display: flex;
  justify-content: center;
}
.p-service .service-menu__ico {
  height: 8.8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.p-service .service-menu__ico img {
  height: auto;
  max-height: 100%;
}
.p-service .service-menu__desc {
  margin-top: 3rem;
}
.p-service .service-menu__item {
  width: 18.7rem;
  max-width: 25%;
  padding: 0 1rem;
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .p-service .service-menu__item:nth-child(1) {
    transition-delay: calc(var(--el-delay) + 0s) !important;
  }
  .p-service .service-menu__item:nth-child(2) {
    transition-delay: calc(var(--el-delay) + 0.15s) !important;
  }
  .p-service .service-menu__item:nth-child(3) {
    transition-delay: calc(var(--el-delay) + 0.3s) !important;
  }
  .p-service .service-menu__item:nth-child(4) {
    transition-delay: calc(var(--el-delay) + 0.45s) !important;
  }
  .p-service .service-menu__item:nth-child(5) {
    transition-delay: calc(var(--el-delay) + 0.6s) !important;
  }
}
.p-service .service-menu__item:nth-child(1) .service-menu__ico img {
  max-width: 6.612rem;
}
.p-service .service-menu__item:nth-child(2) .service-menu__ico img {
  max-width: 11.34rem;
}
.p-service .service-menu__item:nth-child(3) .service-menu__ico img {
  max-width: 8.21rem;
}
.p-service .service-menu__item:nth-child(4) .service-menu__ico img {
  max-width: 11rem;
}
.p-service .service-card {
  padding: 8rem 0;
}
@media screen and (max-width: 1040px) {
  .p-service .service-card__inner {
    padding: 0;
  }
}
.p-service .service-card__desc {
  background: #FFFFFF;
  width: 54.9rem;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}
.p-service .service-card__desc__inner {
  width: 45.7rem;
  max-width: 100%;
  margin: 0 auto;
  padding: 6.5rem 3rem 8rem;
}
.p-service .service-card__title {
  font-size: 4.6rem;
  line-height: 1.3043478261;
  font-weight: 700;
  margin-bottom: 1.3333333333vw;
  letter-spacing: 0.03em;
}
.p-service .service-card__copy {
  letter-spacing: 0.03em;
}
.p-service .service-card__btn {
  margin-top: 4.5rem;
}
.p-service .service-card__btn a {
  font-size: 1.7rem;
  line-height: 1.7058823529;
  letter-spacing: 0.03em;
}
.p-service .service-card__pic__inner {
  overflow: hidden;
}
.p-service .service-card__unit {
  padding: 8rem 0;
}
.p-service .service-card__unit__inner {
  display: flex;
  align-items: flex-start;
}
.p-service .service-card__unit:nth-child(2n+1) .service-card__unit__inner {
  flex-direction: row-reverse;
}
.p-service .service-card__unit:nth-child(1) .service-card__desc {
  margin-top: 11rem;
}
.p-service .service-card__unit:nth-child(1) .service-card__pic {
  margin-left: -22.7rem;
  margin-right: -47rem;
}
.p-service .service-card__unit:nth-child(2) .service-card__pic {
  margin-top: 15.4rem;
  margin-left: -34rem;
  margin-right: -4.6rem;
}
.p-service .service-card__unit:nth-child(3) .service-card__desc {
  margin-top: 10.5rem;
}
.p-service .service-card__unit:nth-child(3) .service-card__pic {
  margin-left: -13.4rem;
  margin-right: -19rem;
}
.p-service .service-card__unit:nth-child(4) .service-card__pic {
  margin-top: 18.5rem;
  margin-left: -35.7rem;
  margin-right: -34rem;
}
.p-service .service-flow__inner {
  max-width: 86rem;
  margin: 0 auto;
}
.p-service .service-flow__title {
  background: #EFEFEF;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.03em;
  padding: 1.3rem 3rem;
  border-radius: 5.6rem;
}
.p-service .service-flow__copy {
  padding: 3rem 3rem 5rem;
  letter-spacing: 0.03em;
}
.p-service .service-flow__deco {
  position: relative;
}
.p-service .service-flow__deco li {
  position: absolute;
  z-index: 2;
}
.p-service .service-flow__deco li:nth-child(1) {
  width: 17.5659rem;
  left: -23rem;
  top: -17rem;
}
.p-service .service-flow__deco li:nth-child(2) {
  top: -21rem;
  width: 34.2rem;
  right: -38.3rem;
}
.p-service .service-flow__more-btn {
  width: 6rem;
  cursor: pointer;
  margin: 0 auto 4.5rem;
  padding: 1rem;
}
.p-service .service-flow__more-btn img {
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
}
.p-service .service-flow__more-btn.is-active img {
  transform: rotate(180deg);
}
.p-service .service-flow__more-bg {
  padding-top: 6.5rem;
  position: relative;
}
.p-service .service-flow__more-bg::before {
  position: absolute;
  top: 0;
  left: -200%;
  transform: translateX(-50%);
  height: 100%;
  width: 120vw;
  z-index: 1;
  content: "";
  background: #EFEFEF;
  opacity: 0;
  transition: 0.5s all ease 0.3s;
}
.p-service .service-flow__more-bg.is-show::before {
  opacity: 1;
  left: 50%;
  transition: 0.5s all ease 0.1s;
}
.p-service .service-flow__more-bg .service-flow__title {
  background: #FFFFFF;
}
.p-service .service-flow__more-ctn {
  padding: 0 0 9rem;
}
.p-service .service-flow__more-inner {
  position: relative;
  z-index: 2;
}
.p-service .service-flow__more .service-flow__title {
  background: #FFFFFF;
}
.p-service .service-flow__item-last {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  z-index: 2;
}
.p-service .service-flow__item-last .service-flow__img {
  width: 57rem;
  margin-right: -16.2rem;
  flex-shrink: 0;
}
.p-service .service-flow__item-last .service-flow__desc {
  padding-right: 2rem;
  flex-grow: 1;
  padding-top: 5rem;
}
.p-service .service-flow__item-last .service-flow__title {
  background: #FFFFFF;
  position: relative;
  z-index: 100;
}
.p-service .service-flow__item-last .service-flow__copy {
  padding-top: 0;
}
@media screen and (min-width: 981px) and (max-width: 1366px) {
  .p-service .service-card__unit:nth-child(1) .service-card__pic {
    margin-left: -23.7rem;
    margin-right: -19.3rem;
    margin-right: calc(50% - 50vw);
  }
  .p-service .service-card__unit:nth-child(2) .service-card__pic {
    margin-left: -19.3rem;
    margin-left: calc(50% - 50vw);
    margin-right: -19.3rem;
  }
  .p-service .service-card__unit:nth-child(3) .service-card__pic {
    margin-right: -19.3rem;
    margin-right: calc(50% - 50vw);
  }
  .p-service .service-card__unit:nth-child(4) .service-card__pic {
    margin-left: -19.3rem;
    margin-left: calc(50% - 50vw);
    margin-right: -31.6rem;
  }
  .p-service .service-flow__deco li:nth-child(1) {
    left: -23rem;
    top: -17rem;
  }
}
@media screen and (min-width: 981px) and (max-width: 1200px) {
  .p-service .service-flow__deco li:nth-child(1) {
    left: -12rem;
    top: -13rem;
  }
  .p-service .service-flow__deco li:nth-child(2) {
    top: -18rem;
    right: -25rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .p-service .bg-deco li:nth-child(2) {
    width: 310rem;
    top: -35vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .p-service .bg-deco li:nth-child(2) {
    width: 260rem;
    top: 36rem;
  }
  .p-service .fv-deco {
    top: 0rem;
    width: 178.7rem;
    width: 230.612244898vw;
    transform: translateX(calc(-50% - 4.5rem));
  }
  .p-service .fv-img {
    max-width: 70.4081632653vw;
  }
  .p-service .service-menu__list {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .p-service .service-menu__item {
    width: 18.3rem;
    padding: 0 0.8rem;
  }
  .p-service .service-card__desc {
    max-width: 60%;
  }
  .p-service .service-card__desc__inner {
    width: 50.7142857143vw;
    max-width: 100%;
    margin: 0 auto;
    padding: 6.6326530612vw 5.1020408163vw 8.1632653061vw;
  }
  .p-service .service-card__title {
    font-size: 4.693877551vw;
  }
  .p-service .service-card__unit:nth-child(1) .service-card__desc {
    margin-top: 11.2244897959vw;
  }
  .p-service .service-card__unit:nth-child(1) .service-card__pic {
    margin-left: -23.1632653061vw;
    margin-right: 0;
  }
  .p-service .service-card__unit:nth-child(2) .service-card__pic {
    margin-top: 15.7142857143vw;
    margin-left: 0;
    margin-right: -13.8775510204vw;
  }
  .p-service .service-card__unit:nth-child(3) .service-card__desc {
    margin-top: 10.7142857143vw;
  }
  .p-service .service-card__unit:nth-child(3) .service-card__pic {
    margin-left: -13.6734693878vw;
    margin-right: 0;
  }
  .p-service .service-card__unit:nth-child(4) .service-card__pic {
    margin-top: 18.8775510204vw;
    margin-left: 0;
    margin-right: -34.693877551vw;
  }
  .p-service .service-flow__deco li:nth-child(1) {
    width: 10.23611rem;
    top: -5rem;
    left: -6.1224489796vw;
  }
  .p-service .service-flow__deco li:nth-child(2) {
    width: 19rem;
    top: -7.5rem;
    right: -6.1224489796vw;
  }
}

.s-service-headline {
  text-align: center;
  position: relative;
  z-index: 2;
  padding-left: 1.0416666667vw;
  padding-right: 1.0416666667vw;
}
.s-service-headline__text {
  display: inline-block;
  font-size: 17.6rem;
  line-height: 1.0738636364;
  font-size: 17.6rem;
  font-size: min(17.6rem, 9.1666666667vw);
  font-weight: 700;
}
.s-service-headline__text.js-inview {
  opacity: 0;
}
.s-service-headline__text.js-inview.is-show {
  opacity: 1;
}
.s-service-mv {
  padding-top: 19rem;
}
.s-service-mv__inner {
  margin-top: -7rem;
  margin-top: -3.6458333333vw;
}
.s-service-gallery {
  margin-right: calc(50% - 50vw);
}
.s-service-gallery .splide {
  padding-bottom: 3rem;
}
.s-service-gallery .splide__slide {
  width: 62.2rem;
  padding-right: 2.2rem;
  aspect-ratio: 600/400;
}
.s-service-gallery .splide__pagination {
  bottom: 0;
  text-align: left;
  justify-content: flex-start;
  padding: 0;
}
.s-service-gallery .splide__pagination__page {
  width: 6rem;
  height: 2rem;
  border-radius: 0;
  margin: 0 1.1rem 0 0;
  transition: 0.3s;
  position: relative;
  background: transparent;
  opacity: 1;
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
}
.s-service-gallery .splide__pagination__page::after {
  position: absolute;
  content: "";
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 0.4rem;
  background: #c6c6c6;
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
}
.s-service-gallery .splide__pagination__page.is-active {
  transform: scale(1);
}
.s-service-gallery .splide__pagination__page.is-active::after {
  background: #000 !important;
}
@media screen and (min-width: 768px) {
  .s-service-gallery .splide__pagination__page:hover::after {
    background: rgba(0, 0, 0, 0.6);
  }
}
.s-service-content {
  padding-bottom: 14rem;
}
.s-service-content__inner {
  max-width: 91.3rem;
  padding: 0 1rem;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .s-service-content .u-mr-ng {
    margin-right: -1.5rem;
  }
}
.s-service-block {
  margin-top: 9rem;
}
.s-service-block__row {
  display: flex;
}
.s-service-block__row.items-center {
  align-items: center;
}
@media screen and (min-width: 768px) {
  .s-service-block__desc {
    flex-grow: 1;
    font-size: 1.3rem;
    line-height: 1.75;
    padding-right: 5rem;
  }
}
.s-service-block__img {
  width: 33rem;
  max-width: 45%;
  flex-shrink: 0;
}
.s-service-price__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2.5rem;
}
.s-service-price__item {
  width: 50%;
  flex-shrink: 0;
  min-width: 46.5rem;
  margin: 0 auto;
}
.s-service-price__item__inner {
  padding: 8rem 2.5rem 0;
}
.s-service-price__hdg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
}
.s-service-price__hdg .ico {
  flex-shrink: 0;
  min-height: 6.15rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 6.8rem;
}
.s-service-price__hdg .ico-sz01 img {
  width: 6.15rem;
}
.s-service-price__hdg .ico-sz02 img {
  width: 5.38182rem;
}
.s-service-price__hdg .ico-sz03 img {
  width: 5.5rem;
}
.s-service-price__hdg .ico-sz04 img {
  width: 5.30352rem;
}
.s-service-price__hdg .ico-sz05 img {
  width: 5.12rem;
}
.s-service-price__hdg .ico-sz06 img {
  width: 5.2rem;
}
.s-service-price__hdg .ico-sz07 img {
  width: 5.1rem;
}
.s-service-price__hdg .txt {
  font-size: 2rem;
  line-height: 1.75;
  font-weight: 700;
}
.s-service-price__data {
  font-size: 1.4rem;
  line-height: 1.3571428571;
}
.s-service-price__data dl {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #c4c4c4;
  padding: 1rem 2rem;
}
.s-service-price__data dt {
  flex-grow: 1;
  padding-right: 1rem;
  font-weight: 400;
  color: #333;
}
.s-service-price__data dd {
  font-size: 1.6rem;
  line-height: 1.1875;
  font-weight: 700;
  flex-shrink: 0;
}
.s-service-price__data--02 {
  font-size: 2rem;
}
.s-service-price__data--02 dt {
  font-weight: 700;
  color: #000000;
}
.s-service-price__data--02 dd {
  font-size: 2rem;
}
.s-service-price__note {
  text-align: right;
  margin-top: 1rem;
  padding-right: 2rem;
  font-size: 1.1rem;
  font-weight: 400;
  color: #333;
}
.s-service-visit {
  max-width: 68rem;
  margin: 6rem auto 0;
}
.s-service-visit__main {
  position: relative;
}
.s-service-visit__caption {
  position: absolute;
  z-index: 1;
  bottom: 3rem;
  left: 0;
  font-size: 2.3rem;
  line-height: 1.8260869565;
  font-weight: 700;
  padding: 1rem 3rem;
  min-width: 33.9rem;
  text-align: center;
  background: #FFFFFF;
  border-radius: 0 6.2rem 6.2rem 0;
  overflow: hidden;
}
.s-service-visit__caption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: 1;
  content: "";
  will-change: clip-path;
  clip-path: inset(0 0 0 0);
  transition: clip-path 0.5s cubic-bezier(0.4, 0.4, 0, 1) 0.2s;
}
.s-service-visit__caption span {
  position: relative;
  z-index: 2;
}
.s-service-visit__caption.is-show::before {
  clip-path: inset(0 0 0 100%);
}
.s-service-visit__btn {
  margin-top: 6.5rem;
  display: flex;
  justify-content: center;
}
.s-service-web-about {
  padding-top: 3rem;
}
.s-service-web-about__main {
  display: flex;
  align-items: flex-end;
  margin-right: calc(50% - 50vw + 0.5rem);
}
.s-service-web-about__pht {
  order: 2;
  width: 94rem;
  margin-left: -13rem;
  margin-bottom: 6rem;
  flex-shrink: 0;
}
.s-service-web-about__desc {
  background: #ececec;
  width: 59.2rem;
  flex-shrink: 0;
  padding: 11rem 9rem;
  position: relative;
  z-index: 2;
}
.s-service-web-about__desc h3 {
  font-size: 3rem;
  line-height: 1.7333333333;
  font-weight: 700;
  margin-bottom: 2.8rem;
}
.s-service-web-about__list {
  margin-top: 8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 8rem 2.2rem;
}
.s-service-web-about__item {
  width: calc(33.3333% - 4.4rem / 3);
}
.s-service-web-about__hdg {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 700;
}
.s-service-web-about__ico {
  width: 12.5rem;
  margin: 2.5rem auto;
}
.s-service-web-about__copy {
  position: relative;
  font-weight: 400;
  height: 12.9rem;
  overflow: hidden;
  padding-bottom: 3rem;
  color: #333;
  line-height: 2.1rem;
}
@media screen and (min-width: 768px) {
  .s-service-web-about__copy {
    font-size: 1.3rem;
    line-height: 1.6153846154;
  }
}
.s-service-web-about__more {
  text-align: center;
  width: 100%;
  height: 6.8rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8);
  background: linear-gradient(0deg, white 0%, white 35%, white 35%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
}
.s-service-web-about__more .txt {
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  color: #000000;
}
.s-service-web-about__more .txt-01 {
  opacity: 1;
}
.s-service-web-about__more .txt-02 {
  opacity: 0;
}
.is-more .s-service-web-about__more {
  background: transparent;
}
.is-more .s-service-web-about__more .txt-01 {
  opacity: 0;
}
.is-more .s-service-web-about__more .txt-02 {
  opacity: 1;
}
.s-service-video {
  margin-top: 4.5rem;
}
.s-service-video video {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}
.s-service-features {
  padding-top: 2rem;
}
.s-service-features__list {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem 3.85rem;
}
.s-service-features__item {
  width: calc((100% - 3.85rem * 2) / 3);
}
.s-service-features__hdg {
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
}
.s-service-sns {
  padding: 2rem 2rem 4rem;
}
.s-service-sns__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.s-service-sns__item {
  width: 21.1rem;
  max-width: 28%;
}
@media screen and (min-width: 768px) {
  .s-service-sns__item:nth-child(1) {
    transition-delay: calc(var(--el-delay) + 0s) !important;
  }
  .s-service-sns__item:nth-child(2) {
    transition-delay: calc(var(--el-delay) + 0.15s) !important;
  }
  .s-service-sns__item:nth-child(3) {
    transition-delay: calc(var(--el-delay) + 0.3s) !important;
  }
  .s-service-sns__item:nth-child(4) {
    transition-delay: calc(var(--el-delay) + 0.45s) !important;
  }
}
.s-service-sns__link {
  display: block;
}
@media screen and (min-width: 981px) and (max-width: 1366px) {
  .s-service-web-about__pht {
    margin-left: -35.6rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .s-service-web-about__pht {
    margin-left: -42rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .s-service.p-service .bg-deco {
    left: 0;
  }
  .s-service.p-service .bg-deco li:nth-child(1) {
    width: 100vw;
  }
  .s-service-headline {
    padding-left: 0vw;
    padding-right: 0vw;
  }
  .s-service-headline__text {
    font-size: 9.351rem;
    font-size: min(9.351rem, 9.5418367347vw);
  }
  .s-service-mv {
    padding-top: 14rem;
  }
  .s-service-mv__inner {
    margin-top: 8.1632653061vw;
  }
  .s-service-web-about {
    padding-top: 3rem;
  }
  .s-service-web-about__main {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-right: -4rem;
  }
  .s-service-web-about__pht {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    flex-shrink: 0;
  }
  .s-service-web-about__desc {
    margin-top: -10.4rem;
    margin-left: -4rem;
  }
  .s-service-features {
    padding-top: 2rem;
  }
  .s-service-features__list {
    max-width: 58.4rem;
    margin: 0 auto;
    gap: 4rem 4rem;
  }
  .s-service-features__item {
    width: calc(50% - 2rem);
  }
  .s-service-sns {
    padding: 2rem 2rem 4rem;
  }
}

@media screen and (max-width: 767px) {
  .p-service .bg-deco {
    top: 0;
    left: 0;
    width: 100vw;
  }
  .p-service .bg-deco li:nth-child(2) {
    width: 100vw;
    margin-left: 0;
    margin-top: 158rem;
  }
  .p-service .fv-deco {
    top: 25rem;
    top: 20rem;
    width: 109.3rem;
    left: -4.7rem;
    transform: translateX(0);
  }
  .p-service .fv-deco__el.el-l01 {
    width: 75.8409px;
    width: 6.9387831656%;
    top: 367px;
    top: 42.0871559633%;
    left: 272px;
    left: 24.8856358646%;
  }
  .p-service .fv-deco__el.el-l02 {
    width: 124.0116px;
    width: 11.3459835316%;
    top: 360px;
    top: 41.2844036697%;
    left: 345px;
    left: 31.5645013724%;
  }
  .p-service .fv-deco__el.el-l03 {
    width: 12.2962px;
    width: 1.1249954254%;
    top: 568px;
    top: 65.1376146789%;
    left: 322px;
    left: 29.4602012809%;
  }
  .p-service .fv-deco__el.el-l04 {
    width: 75.8409px;
    width: 6.9387831656%;
    top: 730px;
    top: 83.7155963303%;
    left: 272px;
    left: 24.8856358646%;
  }
  .p-service .fv-deco__el.el-l05 {
    width: 19.5337px;
    width: 1.7871637694%;
    top: 276px;
    top: 31.6513761468%;
    left: 300px;
    left: 27.4473924977%;
  }
  .p-service .fv-deco__el.el-l06 {
    width: 92.2778px;
    width: 8.4426166514%;
    top: 540px;
    top: 61.9266055046%;
    left: 195px;
    left: 17.8408051235%;
  }
  .p-service .fv-deco__el.el-l07 {
    width: 113.0565px;
    width: 10.3436870997%;
    top: 250px;
    top: 28.6697247706%;
    left: 140px;
    left: 12.8087831656%;
  }
  .p-service .fv-deco__el.el-l08 {
    width: 88.0895px;
    width: 8.0594236048%;
    bottom: 0px;
    bottom: 0%;
    left: 96px;
    left: 8.7831655993%;
  }
  .p-service .fv-deco__el.el-l09 {
    width: 156.169px;
    width: 14.2881061299%;
    top: 592px;
    top: 67.8899082569%;
    left: 0px;
    left: 0%;
  }
  .p-service .fv-deco__el.el-l10 {
    width: 284.8359px;
    width: 26.0600091491%;
    top: 342px;
    top: 39.2201834862%;
    left: 1px;
    left: 0.0914913083%;
  }
  .p-service .fv-deco__el.el-l11 {
    width: 108.4688px;
    width: 9.9239524245%;
    top: 207px;
    top: 23.7385321101%;
    left: 21px;
    left: 1.9213174748%;
  }
  .p-service .fv-deco__el.el-l12 {
    width: 27.827px;
    width: 2.5459286368%;
    top: 500px;
    top: 57.3394495413%;
    left: -100px;
    left: -9.1491308326%;
    display: none;
  }
  .p-service .fv-deco__el.el-l13 {
    width: 400.157px;
    width: 36.6108874657%;
    top: 602px;
    top: 69.0366972477%;
    left: -150px;
    left: -13.7236962489%;
    display: none;
  }
  .p-service .fv-deco__el.el-l14 {
    width: 271.6834px;
    width: 24.8566697164%;
    top: 350px;
    top: 40.1376146789%;
    left: -200px;
    left: -18.2982616651%;
    display: none;
  }
  .p-service .fv-deco__el.el-l15 {
    width: 12.2962px;
    width: 1.1249954254%;
    bottom: 0px;
    bottom: 0%;
    left: 322px;
    left: 29.4602012809%;
    bottom: 0%;
  }
  .p-service .fv-deco__el.el-r01 {
    width: 58.2792px;
    width: 5.3320402562%;
    top: 246px;
    top: 28.2110091743%;
    right: 570px;
    right: 52.1500457457%;
  }
  .p-service .fv-deco__el.el-r02 {
    width: 9.4589px;
    width: 0.8654071363%;
    top: 418px;
    top: 47.9357798165%;
    right: 553px;
    right: 50.5946935041%;
  }
  .p-service .fv-deco__el.el-r03 {
    width: 9.4589px;
    width: 0.8654071363%;
    top: 117px;
    top: 13.4174311927%;
    right: 350px;
    right: 32.021957914%;
  }
  .p-service .fv-deco__el.el-r04 {
    width: 12.2961px;
    width: 1.1249862763%;
    top: 535px;
    top: 61.3532110092%;
    right: 535px;
    right: 48.9478499543%;
  }
  .p-service .fv-deco__el.el-r05 {
    width: 50.3143px;
    width: 4.6033211345%;
    top: 158px;
    top: 18.119266055%;
    right: 461px;
    right: 42.1774931382%;
  }
  .p-service .fv-deco__el.el-r06 {
    width: 12.2962px;
    width: 1.1249954254%;
    top: 216px;
    top: 24.7706422018%;
    right: 452px;
    right: 41.3540713632%;
  }
  .p-service .fv-deco__el.el-r07 {
    width: 113.0564px;
    width: 10.3436779506%;
    top: 566px;
    top: 64.9082568807%;
    right: 418px;
    right: 38.2433668801%;
  }
  .p-service .fv-deco__el.el-r08 {
    width: 120.3794px;
    width: 11.0136688015%;
    top: 239px;
    top: 27.4082568807%;
    right: 317px;
    right: 29.0027447392%;
  }
  .p-service .fv-deco__el.el-r09 {
    width: 121.9491px;
    width: 11.1572827081%;
    top: 394px;
    top: 45.1834862385%;
    right: 329px;
    right: 30.1006404392%;
  }
  .p-service .fv-deco__el.el-r10 {
    width: 75.8408px;
    width: 6.9387740165%;
    top: 598px;
    top: 68.5779816514%;
    right: 316px;
    right: 28.9112534309%;
  }
  .p-service .fv-deco__el.el-r11 {
    width: 122.445px;
    width: 11.2026532479%;
    bottom: 92px;
    bottom: 10.5504587156%;
    right: 256px;
    right: 23.4217749314%;
  }
  .p-service .fv-deco__el.el-r12 {
    width: 284.8359px;
    width: 26.0600091491%;
    top: 0px;
    top: 0%;
    right: 0px;
    right: 0%;
  }
  .p-service .fv-deco__el.el-r13 {
    width: 27.827px;
    width: 2.5459286368%;
    top: 320px;
    top: 36.6972477064%;
    right: 237px;
    right: 21.6834400732%;
  }
  .p-service .fv-deco__el.el-r14 {
    width: 12.2962px;
    width: 1.1249954254%;
    top: 416px;
    top: 47.7064220183%;
    right: 260px;
    right: 23.7877401647%;
  }
  .p-service .fv-deco__el.el-r15 {
    width: 26.0911px;
    width: 2.3871088747%;
    top: 568px;
    top: 65.1376146789%;
    right: 266px;
    right: 24.3366880146%;
  }
  .p-service .fv-deco__el.el-r16 {
    width: 12.2962px;
    width: 1.1249954254%;
    bottom: 47px;
    bottom: 5.3899082569%;
    right: 257px;
    right: 23.5132662397%;
  }
  .p-service .fv-deco__el.el-r17 {
    width: 124.3967px;
    width: 11.3812168344%;
    top: 434px;
    top: 49.7706422018%;
    right: 130px;
    right: 11.8938700823%;
  }
  .p-service .fv-deco__el.el-r18 {
    width: 208.5819px;
    width: 19.0834309241%;
    top: 212px;
    top: 24.3119266055%;
    right: 0px;
    right: 0%;
    display: none;
  }
  .p-service .fv-deco__el.el-r19 {
    width: 176.7552px;
    width: 16.1715645014%;
    top: 623px;
    top: 71.4449541284%;
    right: 50px;
    right: 4.5745654163%;
    display: none;
  }
  .p-service .fv-deco__el.el-r20 {
    width: 122.445px;
    width: 11.2026532479%;
    bottom: 50px;
    bottom: 5.7339449541%;
    right: 22px;
    right: 2.0128087832%;
  }
  .p-service .fv-img {
    max-width: 53.93072rem;
    margin: 41rem auto 0;
  }
  .p-service .service-menu {
    padding-top: 10rem;
  }
  .p-service .service-menu__list {
    flex-wrap: wrap;
  }
  .p-service .service-menu__ico {
    height: 14.084rem;
  }
  .p-service .service-menu__desc {
    margin-top: 5rem;
  }
  .p-service .service-menu__item {
    width: 32.6rem;
    max-width: 50%;
    padding: 0 2.2rem;
    margin-top: 8rem;
  }
  .p-service .service-menu__item:nth-child(1) .service-menu__ico img {
    max-width: 10.57238rem;
  }
  .p-service .service-menu__item:nth-child(2) .service-menu__ico img {
    max-width: 18.1299rem;
  }
  .p-service .service-menu__item:nth-child(3) .service-menu__ico img {
    max-width: 13.12388rem;
  }
  .p-service .service-menu__item:nth-child(4) .service-menu__ico img {
    max-width: 17.58871rem;
  }
  .p-service .service-card {
    padding: 8rem 0;
  }
  .p-service .service-card__desc {
    width: 61.6rem;
  }
  .p-service .service-card__desc__inner {
    width: 48rem;
    padding: 8rem 0rem 9rem;
  }
  .p-service .service-card__title {
    font-size: 4.6rem;
    line-height: 1.3043478261;
    margin-bottom: 2.6666666667vw;
  }
  .p-service .service-card__btn {
    margin-top: 4.5rem;
  }
  .p-service .service-card__btn a {
    font-size: 2.8rem;
    line-height: 1.4642857143;
  }
  .p-service .service-card__unit {
    padding: 8rem 0;
  }
  .p-service .service-card__unit__inner {
    display: block;
  }
  .p-service .service-card__unit:nth-child(2n) .service-card__desc {
    margin-left: auto;
  }
  .p-service .service-card__unit:nth-child(1) .service-card__desc {
    margin-top: -17.2rem;
  }
  .p-service .service-card__unit:nth-child(1) .service-card__pic {
    margin-top: 0;
    margin-left: 8.7rem;
    margin-right: 0;
  }
  .p-service .service-card__unit:nth-child(2) .service-card__desc {
    margin-top: -13.6rem;
  }
  .p-service .service-card__unit:nth-child(2) .service-card__pic {
    margin-top: 0;
    padding-top: 0;
    margin-left: 0;
    margin-right: 6rem;
  }
  .p-service .service-card__unit:nth-child(3) .service-card__desc {
    margin-top: -33.3rem;
  }
  .p-service .service-card__unit:nth-child(3) .service-card__pic {
    margin-top: 0;
    margin-left: 0;
    margin-right: -0.5rem;
  }
  .p-service .service-card__unit:nth-child(4) .service-card__pic {
    margin-top: 0rem;
    margin-left: 0;
    margin-right: 8rem;
  }
  .p-service .service-card__unit:nth-child(4) .service-card__desc {
    margin-top: -9.4rem;
  }
  .p-service .service-flow {
    padding-left: 1rem;
    padding-right: 2rem;
  }
  .p-service .service-flow__inner {
    max-width: 87rem;
    margin: 0 auto;
  }
  .p-service .service-flow__title {
    font-size: 2.9rem;
    line-height: 1.4482758621;
    padding: 2rem 4.4rem;
    border-radius: 8.2rem;
  }
  .p-service .service-flow__copy {
    font-size: 2.2rem;
    padding: 3rem 4rem 8rem;
  }
  .p-service .service-flow__deco li:nth-child(1) {
    width: 16.42774rem;
    left: 5rem;
    top: -3rem;
  }
  .p-service .service-flow__deco li:nth-child(2) {
    width: 31.98406rem;
    top: -7.5rem;
    right: -5.2rem;
  }
  .p-service .service-flow__more-btn {
    display: none;
  }
  .p-service .service-flow__more-bg {
    padding-top: 33rem;
    padding-bottom: 23.5rem;
  }
  .p-service .service-flow__more-ctn {
    padding: 0 0 6rem;
  }
  .p-service .service-flow__item-last {
    display: block;
    margin-top: -21rem;
  }
  .p-service .service-flow__item-last .service-flow__img {
    margin-right: -5rem;
    margin-left: auto;
  }
  .p-service .service-flow__item-last .service-flow__desc {
    padding-right: 0rem;
    padding-top: 6rem;
  }
  .s-service-headline {
    text-align: left;
    padding-left: 3rem;
    padding-right: 0;
  }
  .s-service-headline__text {
    font-size: 6.859rem;
    line-height: 1.0642950867;
    font-size: 6.859rem;
    font-size: min(6.859rem, 9.1453333333vw);
  }
  .s-service-headline__text.txt-lg-sp {
    font-size: 7.8rem;
    line-height: 1.0512820513;
    font-size: 7.8rem;
    font-size: min(7.8rem, 10.4vw);
  }
  .s-service-mv__inner {
    margin-top: 10.6666666667vw;
  }
  .s-service-gallery .splide {
    padding-bottom: 4rem;
  }
  .s-service-gallery .splide__pagination__page {
    width: 8.6rem;
    height: 4rem;
    margin: 0 1.5rem 0 0;
  }
  .s-service-gallery .splide__pagination__page::after {
    height: 0.6rem;
  }
  .s-service-content {
    margin-top: -3rem;
    padding-bottom: 20rem;
  }
  .s-service-block {
    margin-top: 16rem;
  }
  .s-service-block__row {
    display: block;
  }
  .s-service-block__img {
    width: 46.8rem;
    max-width: 80%;
    margin: 8rem auto 0;
  }
  .s-service-price__row {
    margin: 0;
  }
  .s-service-price__item {
    width: 100%;
    min-width: 0;
  }
  .s-service-price__item__inner {
    padding: 14rem 0 0;
  }
  .s-service-price__hdg {
    margin-bottom: 3rem;
    gap: 1.5rem;
  }
  .s-service-price__hdg .ico {
    min-height: 0;
  }
  .s-service-price__hdg .ico-sz01 img {
    width: 10.3rem;
  }
  .s-service-price__hdg .ico-sz02 img {
    width: 8.60609rem;
  }
  .s-service-price__hdg .ico-sz03 img {
    width: 8.79507rem;
  }
  .s-service-price__hdg .ico-sz04 img {
    width: 8.48087rem;
  }
  .s-service-price__hdg .ico-sz05 img {
    width: 8.65rem;
  }
  .s-service-price__hdg .ico-sz06 img {
    width: 8.25434rem;
  }
  .s-service-price__hdg .ico-sz07 img {
    width: 7.75rem;
  }
  .s-service-price__hdg .txt {
    font-size: 3.2rem;
    line-height: 1.3125;
  }
  .s-service-price__data {
    font-size: 2.4rem;
    line-height: 1.3333333333;
  }
  .s-service-price__data dl {
    padding: 1.5rem 3rem 1.5rem 2.2rem;
  }
  .s-service-price__data dd {
    font-size: 2.6rem;
    line-height: 1.2307692308;
  }
  .s-service-price__data--02 {
    font-size: 2.8rem;
  }
  .s-service-price__data--02 dd {
    font-size: 2.8rem;
  }
  .s-service-price__note {
    margin-top: 2rem;
    padding-right: 3rem;
    font-size: 2rem;
  }
  .s-service-visit {
    max-width: 56rem;
    margin: 10rem auto 0;
  }
  .s-service-visit__caption {
    bottom: 4rem;
    font-size: 3rem;
    line-height: 1.7333333333;
    padding: 1.5rem 5rem 1.5rem 3rem;
    min-width: 44.6rem;
    border-radius: 0 8.2rem 8.2rem 0;
  }
  .s-service-visit__btn {
    margin-top: 8rem;
  }
  .s-service-web-about {
    padding-top: 0;
  }
  .s-service-web-about__main {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .s-service-web-about__pht {
    width: 100vw;
    margin-left: 0;
    margin-bottom: 0;
  }
  .s-service-web-about__desc {
    width: 61.6rem;
    padding: 9rem 7rem;
    margin-top: -7rem;
  }
  .s-service-web-about__desc h3 {
    font-size: 4.6rem;
    line-height: 1.3043478261;
    margin-bottom: 2rem;
  }
  .s-service-web-about__desc p {
    letter-spacing: 0.03em;
  }
  .s-service-web-about__list {
    gap: 12rem;
    max-width: 55.6rem;
    margin: 12rem auto 0;
  }
  .s-service-web-about__item {
    width: 100%;
  }
  .s-service-web-about__hdg {
    font-size: 3rem;
  }
  .s-service-web-about__ico {
    display: block;
    width: 20rem;
    margin: 4rem auto;
  }
  .s-service-web-about__copy {
    height: 21.8rem;
    padding-bottom: 5rem;
    line-height: 4.2rem;
  }
  .s-service-web-about__more {
    height: 13.4rem;
  }
  .s-service-web-about__more .txt {
    font-size: 2.6rem;
    line-height: 1.9230769231;
  }
  .s-service-video {
    margin-top: 6.5rem;
  }
  .s-service-features {
    padding: 2rem 5.5rem 0;
  }
  .s-service-features__list {
    gap: 8rem;
  }
  .s-service-features__item {
    width: 100%;
  }
  .s-service-features__hdg {
    font-size: 3rem;
    margin-bottom: 3rem;
  }
  .s-service-sns {
    padding: 2rem 0 6rem;
  }
  .s-service-sns__list {
    justify-content: space-between;
  }
  .s-service-sns__item {
    max-width: 32%;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .p-service .bg-deco {
    max-height: 570rem;
    overflow: hidden;
  }
  .p-service .bg-deco li:nth-child(2) {
    width: 100vw;
    margin-left: 0;
    margin-top: 100rem;
  }
  .p-service .fv-deco {
    top: 25rem;
    top: 10rem;
    left: 0rem;
    width: 96rem;
  }
  .p-service .fv-img {
    max-width: 50rem;
    margin: 34rem auto 0;
  }
  .p-service .service-menu {
    padding: 5rem 7% 0;
  }
  .p-service .service-menu__ico {
    height: 10rem;
  }
  .p-service .service-menu__desc {
    margin-top: 3rem;
  }
  .p-service .service-menu__item {
    width: calc(50% - 2.6666666667vw);
    max-width: calc(50% - 2.6666666667vw);
    padding: 0 1.5rem;
    margin-top: 5rem;
  }
  .p-service .service-menu__item:nth-child(1) .service-menu__ico img {
    max-width: 7rem;
  }
  .p-service .service-menu__item:nth-child(2) .service-menu__ico img {
    max-width: 12rem;
  }
  .p-service .service-menu__item:nth-child(3) .service-menu__ico img {
    max-width: 8.6rem;
  }
  .p-service .service-menu__item:nth-child(4) .service-menu__ico img {
    max-width: 12rem;
  }
  .p-service .service-card {
    padding: 4rem 0;
  }
  .p-service .service-card__desc {
    width: 54rem;
  }
  .p-service .service-card__desc__inner {
    width: 44rem;
    padding: 5rem 0rem 5rem;
  }
  .p-service .service-card__title {
    font-size: 3.6rem;
    margin-bottom: 1.6rem;
  }
  .p-service .service-card__btn {
    margin-top: 3rem;
  }
  .p-service .service-card__btn a {
    font-size: 1.6rem;
  }
  .p-service .service-card__unit {
    padding: 6rem 0;
  }
  .p-service .service-card__unit:nth-child(1) .service-card__desc {
    margin-top: -13rem;
  }
  .p-service .service-card__unit:nth-child(1) .service-card__pic {
    margin-left: 20rem;
  }
  .p-service .service-card__unit:nth-child(2) .service-card__desc {
    margin-top: -11rem;
  }
  .p-service .service-card__unit:nth-child(2) .service-card__pic {
    margin-right: 20rem;
  }
  .p-service .service-card__unit:nth-child(3) .service-card__desc {
    margin-top: -16rem;
  }
  .p-service .service-card__unit:nth-child(3) .service-card__pic {
    margin-left: 12.5%;
    margin-right: 12.5%;
  }
  .p-service .service-card__unit:nth-child(4) .service-card__pic {
    margin-right: 20rem;
  }
  .p-service .service-card__unit:nth-child(4) .service-card__desc {
    margin-top: -10rem;
  }
  .p-service .service-flow {
    padding-top: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .p-service .service-flow__inner {
    max-width: 52rem;
  }
  .p-service .service-flow__title {
    font-size: 2rem;
    padding: 1rem 3rem;
  }
  .p-service .service-flow__copy {
    font-size: 1.6rem;
    padding: 2.5rem 3rem 5rem;
  }
  .p-service .service-flow__deco li:nth-child(1) {
    width: 12rem;
    left: -11rem;
    top: -8rem;
  }
  .p-service .service-flow__deco li:nth-child(2) {
    width: 23rem;
    top: -10rem;
    right: -11rem;
  }
  .p-service .service-flow__more-bg {
    padding-top: 19rem;
    padding-bottom: 20rem;
  }
  .p-service .service-flow__more-ctn {
    padding: 0 0 4rem;
  }
  .p-service .service-flow__item-last {
    margin-top: -18rem;
  }
  .p-service .service-flow__item-last .service-flow__img {
    max-width: 52rem;
    margin-right: 0rem;
  }
  .p-service .service-flow__item-last .service-flow__desc {
    padding-right: 0rem;
    padding-top: 6rem;
  }
  .s-service-headline {
    text-align: left;
    padding-left: 3rem;
  }
  .s-service-headline__text {
    font-size: 4.7rem;
  }
  .s-service-headline__text.txt-lg-sp {
    font-size: 5.2rem;
  }
  .s-service-mv {
    padding-top: 14rem;
  }
  .s-service-mv__inner {
    margin-top: 5.3333333333vw;
  }
  .s-service-gallery .splide__slide {
    width: 40.2rem;
    padding-right: 1.4rem;
  }
  .s-service-gallery .splide__pagination__page {
    width: 7rem;
    height: 0.5rem;
    margin: 0 1.2rem 0 0;
  }
  .s-service-content {
    padding-bottom: 14rem;
  }
  .s-service-block {
    margin-top: 11rem;
  }
  .s-service-block__img {
    width: 33rem;
    margin: 6rem auto 0;
  }
  .s-service-price {
    max-width: 48rem;
    margin: 0 auto;
  }
  .s-service-price__item__inner {
    padding: 11rem 0 0;
  }
  .s-service-price__hdg {
    margin-bottom: 2rem;
    gap: 1.2rem;
  }
  .s-service-price__hdg .ico {
    width: 5rem;
  }
  .s-service-price__hdg .txt {
    font-size: 2.2rem;
  }
  .s-service-price__data {
    font-size: 1.7rem;
  }
  .s-service-price__data dl {
    padding: 1.2rem 2.4rem 1.2rem 1.8rem;
  }
  .s-service-price__data dd {
    font-size: 1.9rem;
  }
  .s-service-price__data--02 {
    font-size: 2rem;
  }
  .s-service-price__data--02 dd {
    font-size: 2rem;
  }
  .s-service-price__note {
    margin-top: 1rem;
    padding-right: 2.4rem;
    font-size: 1.5rem;
  }
  .s-service-visit {
    max-width: 48rem;
    margin: 8rem auto 0;
  }
  .s-service-visit__caption {
    bottom: 3rem;
    font-size: 2rem;
    padding: 1.2rem 3rem 1.2rem 2rem;
    min-width: 32rem;
    border-radius: 0 4.4rem 4.4rem 0;
  }
  .s-service-visit__btn {
    margin-top: 6rem;
  }
  .s-service-web-about__desc {
    width: 54rem;
    padding: 7rem 5rem;
    margin-top: -7rem;
  }
  .s-service-web-about__desc h3 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  .s-service-web-about__desc p {
    font-size: 1.6rem;
  }
  .s-service-web-about__list {
    gap: 10rem;
    max-width: 44rem;
    margin: 10rem auto 0;
  }
  .s-service-web-about__hdg {
    font-size: 2.4rem;
  }
  .s-service-web-about__ico {
    width: 14rem;
    margin: 2.4rem auto;
  }
  .s-service-web-about__copy {
    font-size: 1.6rem;
    height: 15rem;
    padding-bottom: 3rem;
    line-height: 3rem;
  }
  .s-service-web-about__more {
    height: 9rem;
  }
  .s-service-web-about__more .txt {
    font-size: 1.8rem;
    line-height: 1.6666666667;
  }
  .s-service-video {
    margin-top: 4rem;
  }
  .s-service-features {
    padding: 2rem 0 0;
  }
  .s-service-features__list {
    gap: 4rem 4rem;
  }
  .s-service-features__item {
    width: calc(50% - 2rem);
  }
  .s-service-features__hdg {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .s-service-sns {
    padding: 2rem 2rem 4rem;
  }
  .s-service-sns__item {
    max-width: 28%;
  }
}
.p-works-ttl {
  font-family: "DIN Pro", sans-serif;
  text-align: center;
  margin-bottom: 6rem;
  font-size: 5.3rem;
  line-height: 1.1320754717;
  font-weight: 700;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .p-works-ttl {
    margin-bottom: 8rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .p-works-ttl {
    margin-bottom: 6rem;
  }
}

.p-works-list {
  margin-top: 5rem;
  display: grid;
  gap: 5rem 2.8rem;
  grid-template-columns: repeat(2, 1fr);
}
.p-works-list .thumb {
  display: block;
  padding: 0.7rem;
  background: #EFEFEF;
}
.p-works-list .thumb img {
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
}
.p-works-list.list-01 .thumb {
  aspect-ratio: 321/233;
}
.p-works-list.list-02 .thumb {
  aspect-ratio: 321/448;
}
.p-works-list .desc {
  display: block;
  margin-top: 0.6rem;
  font-size: 2.2rem;
  line-height: 1.75;
  font-weight: 400;
}
.p-works-list .desc h4 {
  font-weight: 700;
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .p-works-list {
    gap: 4rem 2.6rem;
    margin-top: 3.5rem;
  }
  .p-works-list .desc {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 768px) {
  .p-works-list {
    gap: 3.6rem 1.6rem;
    margin-top: 3.5rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .p-works-list .thumb {
    padding: 0.4rem;
    transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
  }
  .p-works-list .thumb:hover {
    background: #333;
    opacity: 1;
  }
  .p-works-list.list-01 {
    grid-template-columns: repeat(4, 1fr);
  }
  .p-works-list.list-02 {
    grid-template-columns: repeat(5, 1fr);
  }
  .p-works-list .desc {
    margin-top: 1rem;
    font-size: 1.3rem;
    line-height: 1.75;
  }
  .p-works-list .desc a:hover {
    text-decoration: underline;
    opacity: 0.85;
  }
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .p-works-list.list-01 {
    grid-template-columns: repeat(3, 1fr);
  }
  .p-works-list.list-02 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.p-company-name {
  margin-top: 2.5rem;
  width: 20.5rem;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .p-company-name {
    margin-top: 5rem;
    width: 33rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .p-company-name {
    margin-top: 3rem;
    width: 22rem;
  }
}

.p-company-lead {
  text-align: left;
}

.p-company-info {
  margin: 5rem 0;
}
.p-company-info dl {
  display: flex;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  line-height: 2;
}
.p-company-info dl dt {
  background: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 21.7rem;
  font-weight: 700;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.p-company-info dl dd {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 3.5rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .p-company-info {
    margin: 8rem 0;
  }
  .p-company-info dl {
    flex-direction: column;
    margin-top: 2rem;
    font-size: 2.4rem;
    line-height: 1.6666666667;
  }
  .p-company-info dl dt {
    display: block;
    width: 100%;
    padding: 1rem 2.5rem;
  }
  .p-company-info dl dd {
    padding: 2rem 2.5rem;
    display: block;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .p-company-info {
    margin: 5rem 0;
  }
  .p-company-info dl {
    margin-top: 1rem;
    font-size: 1.6rem;
    line-height: 1.75;
  }
  .p-company-info dl dt {
    padding: 0.5rem 1.5rem;
  }
  .p-company-info dl dd {
    padding: 1rem 1.5rem;
  }
}

.p-contact .contact-tel {
  margin: 5rem 0 6rem;
}
.p-contact .contact-tel dl {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  gap: 1.5em;
}
.p-contact .contact-tel dl dt {
  font-size: 1.8rem;
  line-height: 2.2222222222;
  color: #333;
}
.p-contact .contact-tel dl dd {
  font-size: 3.1rem;
  line-height: 1.2903225806;
  font-family: "DIN Pro", sans-serif;
  letter-spacing: 0.05em;
}
.p-contact .contact-copy {
  font-weight: 700;
}
.p-contact .contact-form {
  --bg-label: #000;
  --color-label: #fff;
  --bg-input: #fff;
  --border-input: #878787;
  margin-top: 3rem;
}
.p-contact .contact-form .entry-form__error {
  padding: 2rem;
  background: #fff;
  color: #E50011;
  font-weight: 500;
  border: 2px solid #E50011;
  margin-bottom: 4rem;
}
.p-contact .contact-form .entry-form__error .error-ttl {
  font-size: 120%;
  margin-left: -0.5em;
  margin-bottom: 1rem;
}
.p-contact .contact-form .entry-form__error .error-box ul {
  margin-left: 1.2em;
}
.p-contact .contact-form .entry-form__error .error-box li {
  padding: 0.5rem 0;
  list-style: disc;
  font-size: 100%;
  font-weight: 500;
  line-height: 1.5;
}
.p-contact .contact-form .entry-form__error .error-box li em {
  font-style: normal;
}
.p-contact .contact-form .form-ctrl {
  display: flex;
  gap: 0.6rem;
  margin-top: 0.5rem;
}
.p-contact .contact-form .form-ctrl .ipt {
  width: 100%;
  padding: 0.6rem 1.5rem;
  border: 1px solid var(--border-input);
  background: var(--bg-input);
  color: #333;
  border-radius: 0 !important;
  font-size: 1.4rem;
  line-height: 1.8571428571;
}
.p-contact .contact-form .form-ctrl .ipt.min-size {
  width: 10rem;
  text-align: center;
}
.p-contact .contact-form .form-ctrl .ipt.ipt-textarea {
  min-height: 22.2rem;
}
.p-contact .contact-form .form-ctrl textarea {
  display: block;
  resize: none;
}
.p-contact .contact-form .form-ctrl .error {
  color: #E50011;
  font-size: 75%;
  margin-top: 0.3rem;
}
.p-contact .contact-form .form-ctrl span.ipt {
  display: inline-block;
}
.p-contact .contact-form .form-ctrl__lbl {
  width: 21.7rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-label);
  background: var(--bg-label);
  font-size: 1.4rem;
  line-height: 1.75;
  font-weight: 700;
  min-height: 4rem;
  gap: 0.3rem;
}
.p-contact .contact-form .form-ctrl__lbl .u-requỉed {
  vertical-align: text-top;
  font-size: 71%;
  margin-top: -0.8rem;
}
.p-contact .contact-form .form-ctrl__ctn {
  flex-grow: 1;
}
.p-contact .contact-form .form-ctrl__ctn.is-error .ipt {
  border-color: #E50011;
}
.p-contact .contact-form__btn {
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.p-contact .contact-form__btn > div {
  width: 36rem;
  max-width: calc(50% - 2.5rem);
}
.p-contact .contact-form #contact-confirm {
  --bg-label: #dbdbdb;
  --color-label: #000;
  --bg-input: #ededed;
  --border-input: #ededed;
}
.p-contact .contact-btn {
  width: 100%;
  max-width: 36rem;
  color: #fff;
  background: #000;
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 1.9rem;
  line-height: 1.8947368421;
  padding: 1rem 1.5rem;
  border: 2px solid #000;
  transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
}
.p-contact .contact-btn.btn-back {
  background: #898989;
  border-color: #898989;
}
@media screen and (min-width: 768px) {
  .p-contact .contact-btn:hover {
    color: #000;
    background: #fff;
  }
}
.p-contact .contact-thanks {
  max-width: 67rem;
  margin: 0 auto;
}
.p-contact .thanks-ttl {
  font-size: 2.8rem;
  line-height: 1.4285714286;
  font-weight: 700;
  margin-bottom: 3rem;
}
@media screen and (max-width: 767px) {
  .p-contact .contact-tel {
    margin: 10rem 0 12rem;
  }
  .p-contact .contact-tel dl {
    flex-direction: column;
    gap: 0;
  }
  .p-contact .contact-tel dl dt {
    font-size: 3.1rem;
  }
  .p-contact .contact-tel dl dd {
    font-size: 5.6rem;
  }
  .p-contact .contact-copy {
    color: #333;
  }
  .p-contact .contact-form {
    margin-top: 4.5rem;
  }
  .p-contact .contact-form .entry-form__error {
    font-size: 2.2rem;
    padding: 3rem;
    border-width: 1px;
    margin-bottom: 4rem;
  }
  .p-contact .contact-form .entry-form__error .error-ttl {
    margin-bottom: 1.5rem;
  }
  .p-contact .contact-form .form-ctrl {
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 4rem;
  }
  .p-contact .contact-form .form-ctrl .ipt {
    width: 100%;
    padding: 1.5rem 2.5rem;
    font-size: 2.4rem;
  }
  .p-contact .contact-form .form-ctrl .ipt.min-size {
    width: 15rem;
  }
  .p-contact .contact-form .form-ctrl .ipt.ipt-textarea {
    min-height: 19.5rem;
  }
  .p-contact .contact-form .form-ctrl .error {
    margin-top: 0.6rem;
  }
  .p-contact .contact-form .form-ctrl__lbl {
    display: block;
    padding: 1.5rem 2.5rem;
    width: 100%;
    font-size: 2.4rem;
    min-height: 7.2rem;
  }
  .p-contact .contact-form .form-ctrl__lbl .u-requỉed {
    display: inline-block;
  }
  .p-contact .contact-form__btn {
    margin-top: 9rem;
    gap: 2.6rem;
  }
  .p-contact .contact-form__btn > div {
    width: 58rem;
    max-width: calc(50% - 1.3rem);
  }
  .p-contact .contact-btn {
    width: 100%;
    max-width: 58rem;
    font-size: 3rem;
    line-height: 1.8666666667;
    padding: 2.5rem;
  }
  .p-contact .thanks-ttl {
    font-size: 4.2rem;
    line-height: 1.4285714286;
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px), screen and (max-width: 767px) and (orientation: landscape) {
  .p-contact .contact-tel {
    margin: 5rem 0 6rem;
  }
  .p-contact .contact-tel dl dt {
    font-size: 1.7rem;
  }
  .p-contact .contact-tel dl dd {
    font-size: 3.1rem;
  }
  .p-contact .contact-form {
    margin-top: 3rem;
  }
  .p-contact .contact-form .entry-form__error {
    font-size: 1.6rem;
    padding: 2rem;
    margin-bottom: 3rem;
  }
  .p-contact .contact-form .entry-form__error .error-ttl {
    margin-bottom: 1rem;
  }
  .p-contact .contact-form .form-ctrl {
    gap: 1rem;
    margin-top: 2rem;
  }
  .p-contact .contact-form .form-ctrl .ipt {
    padding: 1.5rem 2rem;
    font-size: 1.8rem;
  }
  .p-contact .contact-form .form-ctrl .ipt.min-size {
    width: 12rem;
  }
  .p-contact .contact-form .form-ctrl .error {
    margin-top: 0.6rem;
  }
  .p-contact .contact-form .form-ctrl__lbl {
    padding: 1.5rem 2rem;
    font-size: 1.8rem;
    min-height: 5rem;
  }
  .p-contact .contact-form__btn {
    margin-top: 8rem;
    gap: 2.6rem;
  }
  .p-contact .contact-btn {
    max-width: 42rem;
    font-size: 2.4rem;
    padding: 1.5rem;
  }
  .p-contact .thanks-ttl {
    font-size: 3.2rem;
    margin-bottom: 3rem;
  }
}