.p-home {
  overflow: hidden;

  .bg-fixed {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    z-index: 0;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: transparent;
      z-index: 1;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      border: 2px solid #FFF;
      content: "";
      content: none;

      @include sp {
        border-width: 1px;
      }
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }

  .layer-main {
    position: relative;
    z-index: 2;
  }
}

.home-mv {
  height: 100vh;
  height: 100svh;
  position: relative;
  padding-top: rem(100);
  padding-bottom: rem(15);
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1025px) {
    max-height: 100vw;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(80);
    padding-left: 10%;
  }

  &__headline {
    position: relative;
    z-index: 2;
    @include fz(54, 92);
    font-size: min(5.4rem, 4.8vh);
    letter-spacing: 0.03em;
    font-weight: 900;
    display: flex;
    flex-direction: row-reverse;

    .txt {
      writing-mode: vertical-rl;
      white-space: nowrap;
      padding: 0 rem(5);
      transform: rotate(0);
      overflow: unset;
    }
  }

  &__copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include fz_min_pc(46, 66);
    padding-bottom: vw_pc(90);
    letter-spacing: 0.05em;

    p {
      margin-bottom: rem(60);
      margin-bottom: vw_pc(45);
      display: inline-block;
      color: $COLOR_WHITE;
      position: relative;
      padding: rem(12) rem(30);
      font-weight: 700;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        z-index: 1;
        background: $COLOR_BLACK;
        transform: skewX(-15deg);
        transition: .8s cubic-bezier(.215, .61, .355, 1);
        clip-path: inset(0 100% 0 0);
      }

      .txt {
        position: relative;
        z-index: 2;
      }
    }

    &.is-show {
      p::before {
        clip-path: inset(0 0 0 0);
      }
    }
  }

  &__running-text {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    gap: 3em;

    ul {
      display: flex;
      width: 150%;
      animation: marquee 30s linear infinite;
      gap: 3em;

      li {
        white-space: nowrap;
        @include fz(190, 170);
        font-weight: 700;
        color: $COLOR_GRAY;
        letter-spacing: 0;
        width: 50%;
      }
    }
  }
}

.home-intro {
  padding: rem(160) 0 rem(180);

  &__inner {
    max-width: rem(1560);
  }

  &__headline {
    @include fz(47, 82.5);
    font-weight: 700;
    margin-bottom: rem(60);
    display: flex;
    flex-direction: column;
  }

  &__copy {
    @include fz(18, 31.5);
    font-weight: 400;
    text-align: right;

    p {
      text-align: left;
      max-width: rem(960);
      display: inline-block;
    }
  }
}

.home-product {
  &__slider {
    padding-bottom: rem(88);
  }

  &__list {}

  &__item {
    width: rem(582);
    padding: 0 1px;
  }

  &__img {
    width: 100%;
    aspect-ratio: 580/386;
  }

  &__description {
    width: 90%;
    max-width: rem(450);
    padding: rem(30) rem(42) rem(42);
    color: $COLOR_WHITE;
    background: $COLOR_BLACK;
    margin-top: rem(-100);
    position: relative;
    z-index: 1;
  }

  &__title {
    @include fz(24, 42);
    font-weight: 700;
    margin-bottom: rem(10);
  }

  &__copy {
    @include fz(14, 24.5);
    font-weight: 400;
    letter-spacing: 0.03em;
  }

  .splide-pagination {
    position: absolute;
    left: 0;
    bottom: rem(12);
    width: 100%;
    @include fz(19, 24);
    font-weight: 400;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      padding: 0 rem(12);
    }
  }

  .splide__arrow {
    background: transparent;
    top: auto;
    bottom: 0;
    transform: translate(0, 0);
    z-index: 2;
    width: rem(44);
    height: rem(44);
    position: absolute;
    bottom: 0;
    outline: none !important;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: no-repeat center center / rem(30) rem(14);
    }

    svg {
      display: none;
    }

    @include pc {
      background: rgba($COLOR_WHITE, 0) !important;
      border-radius: 50%;
      transition: $TRANSITION_BASE;

      &:hover {
        opacity: $OPACITY_HOVER;
        background: rgba($COLOR_WHITE, 1) !important;
      }
    }

    &--prev {
      left: 50%;
      transform: translate(-10rem, 0);

      &::after {
        background-image: url("/assets/img/common/ico_arrow_left01.svg");
      }
    }

    &--next {
      right: 50%;
      transform: translate(10rem, 0);

      &::after {
        background-image: url("/assets/img/common/ico_arrow_right01.svg");
      }
    }
  }
}

.home-service {
  padding: rem(120) 0 rem(100);

  &__inner {
    max-width: rem(770);
  }

  &__headline {
    @include fz(100, 120);
    font-weight: 700;
    letter-spacing: 0.03em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    @include fz(27, 47.5);
    font-weight: 700;
    letter-spacing: 0.03em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(35);
  }

  &__copy {
    @include fz(14, 24.5);
    font-weight: 400;
  }

  &__list {
    margin-top: rem(60);
    margin-left: rem(-27);
  }

  .service-nav__item {
    @include pc {
      &:nth-child(2) {
        transition-delay: 0.2s;
      }

      &:nth-child(3) {
        transition-delay: 0.4s;
      }

      &:nth-child(4) {
        transition-delay: 0.6s;
      }
    }

    @include sp {
      &:nth-child(2n) {
        transition-delay: 0.2s;
      }
    }
  }

  &__btn {
    margin: rem(60) 0 0;
    display: flex;
    justify-content: center;
  }
}

.home-vision {
  @media screen and (min-width: 1700px) {
    padding-right: vw_pc(40);
  }

  &__inner {
    max-width: rem(1780);
  }

  &__row {
    display: flex;
    align-items: center;
    gap: rem(60);
    flex-direction: row-reverse;
  }

  &__img {
    width: calc((967 / 1720) * 100%);
    flex-shrink: 0;
    position: relative;
    overflow: hidden;

    .is-frame {
      opacity: 0;
    }

    .is-part {
      position: absolute;
      z-index: 1;
      width: rem(463);
      width: calc(463 / 967 * 100%);

      &01 {
        left: 0;
        bottom: 0;
      }

      &02 {
        right: 0;
        top: 0;
      }
    }
  }

  &__description {
    flex-grow: 1;
    position: relative;
    z-index: 2;
  }

  &__headline {
    @include fz(45, 60);
    font-weight: 700;
    margin-bottom: rem(30);
    margin-right: rem(-200);
  }

  &__copy {
    @include fz(16, 28);
    font-weight: 400;
    max-width: rem(682);
  }

  &__btn {
    margin-top: rem(50);
  }
}

.home-headline-en {
  @include fz_min_pc(190, 188);
  font-weight: 700;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.home-movie {
  margin-top: rem(150);

  &__inner {
    max-width: rem(1712);
  }

  &__content {
    position: relative;
  }

  &__headline {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
  }

  &__video {
    position: absolute;
    top: rem(90);
    right: rem(-30);
    width: rem(928);
    z-index: 1;
    aspect-ratio: 928/705;
    overflow: hidden;
    pointer-events: none;
    z-index: 0;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: transparent;
      z-index: 1;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      border: 2px solid #FFF;
      content: "";
      content: none;

      @include sp {
        border-width: 1px;
      }
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__description {
    max-width: rem(890);
    margin: rem(100) auto 0;
    background: $COLOR_GRAY;
    padding: rem(90) rem(120);
    position: relative;
    z-index: 2;
  }

  &__title {
    @include fz(30, 52.5);
    font-weight: 700;
    margin-bottom: rem(20);
  }

  &__copy {
    @include fz(16, 28);
    font-weight: 400;
  }
}

.home-design {
  max-width: rem(1310);
  margin: rem(190) auto 0;
  margin: rem(190) auto rem(120);

  &__row {
    display: flex;
    align-items: center;
    gap: calc((98 / 1250) * 100%);
  }

  &__img {
    width: rem(412);
    flex-shrink: 0;
    aspect-ratio: 412/507;
    overflow: hidden;
  }

  &__description {
    flex-grow: 1;
  }

  &__copy {
    max-width: rem(480);
    @include fz(16, 28);
    font-weight: 400;
  }
}

.home-meeting {
  &__inner {
    max-width: rem(1826);
    margin: 0 auto;
  }

  &__row {
    display: flex;
    flex-direction: row-reverse;
    gap: calc(90 / 1766 * 100%);
    align-items: center;
  }

  &__img {
    width: calc(836 / 1766 * 100%);
    flex-shrink: 0;
    position: relative;
    overflow: hidden;

    .is-frame {
      opacity: 0;
    }

    .is-part {
      position: absolute;
      z-index: 1;

      &01 {
        left: 0;
        bottom: 0;
        width: rem(374);
        width: calc(374 / 836 * 100%);
      }

      &02 {
        right: 0;
        top: 0;
        width: rem(534);
        width: calc(534 / 836 * 100%);
      }
    }
  }

  &__description {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5%;

    &__inner {
      width: max-content;
    }
  }

  &__title {
    @include fz(30, 52.5);
    font-weight: 700;
    margin-bottom: rem(20);
  }

  &__copy {
    max-width: rem(385);
    @include fz(16, 28);
    font-weight: 400;
  }
}

.home-web {
  padding-top: rem(120);
  padding-bottom: rem(120);

  &__inner {
    max-width: rem(1050);
  }

  &__headline {}

  &__product {
    margin-top: rem(-9);
    width: 100vw;
    overflow: hidden;

    li {
      pointer-events: none;
      position: relative;
      width: 100%;
      height: rem(150);

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: repeat-x;
        background-size: rem(1890);
        animation: 25s linear infinite scroll_gallery;
        z-index: 1;
      }

      &:nth-child(1) {
        span {
          background-image: url("../img/top/web_group01.jpg");
        }
      }

      &:nth-child(2) {
        span {
          background-image: url("../img/top/web_group02.jpg");
        }
      }

      &:nth-child(3) {
        span {
          background-image: url("../img/top/web_group03.jpg");
        }
      }

      &:nth-child(2n) {
        span {
          animation-direction: reverse;
        }
      }

      &:nth-child(n+2) {
        margin-top: rem(5);
      }
    }
  }

  &__lead {
    max-width: rem(776);
    margin: rem(60) auto 0;
    @include fz(16, 28);
    font-weight: 400;
  }

  &__btn {
    margin-top: rem(60);
    text-align: center;
  }

  &__list {
    padding-top: rem(120);
    max-width: rem(746);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    row-gap: rem(88);
    column-gap: calc((66 / 746) * 100%);
  }

  &__item {
    width: calc(50% - #{rem(33)});

    &:nth-child(2n) {
      transition-delay: 0.2s;
    }
  }

  &-product {
    &__thumbnail {
      margin-bottom: rem(30);

      picture {
        display: block;
        overflow: hidden;
        aspect-ratio: 340/200;
      }

      img {
        @extend .img-fit;
        transition: $TRANSITION_BASE;
      }
    }

    &__copy {
      @include fz(14, 24.5);
      font-weight: 400;
      letter-spacing: 0;
    }

    &__btn {
      margin-top: rem(30);
      text-align: center;
    }
  }

  &__link {
    @include pc {
      &:hover {
        opacity: 1;

        .home-web-product {
          &__thumbnail {
            img {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 981px) and (max-width: 1500px) {
  .home-product {
    &__item {
      width: rem(500);
    }

    &__description {
      padding: rem(20) rem(30) rem(30);
      margin-top: rem(-90);
    }

    &__title {
      @include fz(22);
    }
  }
}

@include laptop {
  .home-mv {
    &__inner {
      gap: vw_md(80);
    }

    &__headline {
      @include fz_min_h(39);

      .txt {
        padding: 0 vw_md(2);
      }
    }

    &__copy {
      @include fz_min_md(41, 66);
      padding-bottom: vw_md(100);

      p {
        margin-bottom: vw_pc(50);
        padding: vw_md(10) vw_md(30);
      }
    }

    &__running-text {
      ul {
        li {
          font-size: vw_md(168);
          line-height: 0.88;
        }
      }
    }
  }

  .home-intro {
    padding: rem(160) 0 rem(180);

    &__inner {
      max-width: rem(1220);
    }

    &__headline {
      @include fz_min_md(47);
      margin-bottom: rem(60);
    }

    &__copy {
      p {
        max-width: rem(960);
        width: vw_md(1100);
      }
    }
  }

  .home-product {
    &__item {
      width: rem(460);
    }
  }

  .home-service {
    padding: rem(120) 0 rem(160);
  }

  .home-vision {
    &__inner {
      max-width: rem(1286);
    }

    &__row {
      align-items: flex-start;
      gap: 0;
      margin-right: calc(#{vw_md(-40)} - 3rem);
    }

    &__img {
      width: calc((788 / 1296) * 100%);
      margin-top: rem(120);

      .is-part {
        width: rem(377);
        width: calc(377 / 788 * 100%);
      }
    }

    &__description {
      margin-right: rem(-300);
    }

    &__headline {
      margin-bottom: rem(30);
      padding-right: 0;
    }
  }

  .home-headline-en {
    @include fz_min_md(160);
  }

  .home-movie {
    margin-top: rem(160);

    &__inner {
      max-width: rem(1712);
    }

    &__content {
      margin: 0 -3rem;
    }

    &__headline {
      margin-left: vw_md(-15);
    }

    &__video {
      top: vw_md(10);
      right: vw_md(-10);
      width: vw_md(928);
    }

    &__description {
      max-width: rem(890);
      width: vw_md(1000);
      margin: vw_md(80) auto 0;
      padding: vw_md(90) vw_md(120);
      transform: translateX(#{vw_md(-45)})
    }
  }

  .home-design {
    margin-top: rem(180);
    max-width: rem(1034);

    &__row {
      gap: calc((90 / 1034) * 100%);
    }
  }

  .home-meeting {
    &__inner {
      margin: 0 -3rem;
    }

    &__row {
      gap: 0;
      align-items: flex-start;
    }

    &__img {
      width: calc(761 / 1366 * 100%);

      .is-part {
        &01 {
          width: rem(340);
          width: calc(340 / 761 * 100%);
        }

        &02 {
          width: rem(486);
          width: calc(486 / 761 * 100%);
        }
      }
    }

    &__description {
      padding-top: rem(70);
      padding-left: vw_md(100);
      padding-bottom: 0;
      justify-content: flex-start;
      position: relative;
      z-index: 2;
      margin-right: rem(-20);
    }
  }

  .home-web {
    &__inner {
      max-width: rem(1046);
    }

    &__product {
      margin-top: rem(-9);
    }
  }
}

@include laptop_sm {
  .home-product {
    &__item {
      width: rem(400);
    }

    &__description {
      padding: rem(15) rem(20);
      margin-top: rem(-80);
    }

    &__title {
      @include fz(20);
    }

    &__copy {
      @include fz(13);
    }
  }
}

@include tablet {
  .home-mv {
    &__inner {
      gap: vw_sm(80);
      padding-left: 7%;
    }

    &__headline {
      @include fz_min_h(37);

      .txt {
        padding: 0 vw_sm(2);
      }
    }

    &__copy {
      @include fz_min_sm(30.6, 43);
      padding-bottom: vw_sm(100);

      p {
        margin-bottom: vw_pc(50);
        padding: vw_sm(10) vw_sm(30);
      }
    }

    &__running-text {
      ul {
        li {
          font-size: vw_sm(125);
          line-height: 0.88;
        }
      }
    }
  }

  .home-intro {
    padding: rem(140) 0 rem(140) vw_sm(10);

    &__inner {
      max-width: rem(930);
    }

    &__headline {
      @include fz_min_sm(44);
      margin-bottom: rem(50);
    }

    &__copy {
      @include fz(16, 28);

      p {
        max-width: rem(804);
        width: vw_sm(840);
      }
    }
  }

  .home-service {
    padding: rem(100) 0 rem(140);

    &__headline {
      font-size: vw_sm(100);
    }
  }

  .home-vision {
    &__inner {
      max-width: rem(920);
    }

    &__row {
      align-items: flex-start;
      gap: 0;
      margin-right: calc(#{vw_sm(-40)} - 3rem);
    }

    &__img {
      width: calc((700 / 920) * 100%);
      margin-top: rem(260);

      .is-part {
        width: rem(335);
        width: calc(335 / 700 * 100%);
      }
    }

    &__description {
      margin-right: rem(-500);
    }

    &__headline {
      margin-bottom: rem(30);
      padding-right: 0;
      font-size: vw_sm(45);
    }
  }

  .home-headline-en {
    @include fz_min_sm(120);
    @include fz_min_sm(118);
  }

  .home-movie {
    margin-top: rem(140);

    &__inner {
      max-width: 100%;
    }

    &__content {
      margin: 0 -3rem;
    }

    &__headline {
      margin-left: vw_sm(-11);
      letter-spacing: -0.02em;
    }

    &__video {
      top: vw_sm(80);
      right: vw_sm(0);
      width: vw_sm(742);
    }

    &__description {
      max-width: rem(890);
      width: vw_sm(1000);
      margin: vw_sm(140) 0 0;
      padding: vw_sm(90) vw_sm(120);
    }
  }

  .home-design {
    margin-top: rem(140);
    margin-bottom: rem(140);
    max-width: 100%;

    &__row {
      margin-left: -3rem;
      margin-right: vw_sm(10);
      gap: calc((50 / 940) * 100%);
    }
  }

  .home-meeting {
    &__row {
      margin-right: -3rem;
      gap: 0;
      align-items: flex-start;
    }

    &__img {
      width: calc(743 / 950 * 100%);
      margin-top: rem(140);

      .is-part {
        &01 {
          width: rem(332);
          width: calc(332 / 743 * 100%);
        }

        &02 {
          width: rem(474);
          width: calc(474 / 743 * 100%);
        }
      }
    }

    &__description {
      padding-left: 0;
      padding-bottom: 0;
      justify-content: flex-start;
      position: relative;
      z-index: 2;
      margin-right: rem(-400);
    }
  }

  .home-web {
    &__inner {
      max-width: rem(1046);
    }

    &__product {
      margin-top: rem(-9);
    }
  }
}

@media screen and (max-height: 700px) and (orientation: landscape) {
  .home-mv {
    height: auto;
    padding-top: rem(115);
    padding-bottom: rem(100);

    @media screen and (min-width: 1025px) {
      max-height: auto;
    }

    &__inner {
      gap: rem(70);
    }

    &__headline {
      @include fz(54, 92);
      font-size: min(4.6rem, 5vw);
      letter-spacing: 0em;

      .txt {
        padding: 0 rem(3);
      }
    }
  }
}
