.effect-hover {
  --noiseOpacity: 0.15;
  position: relative;
  display: block;

  &__img-wrapper {
    position: relative;
    overflow: hidden;
  }

  .noise {
    opacity: var(--noiseOpacity);
    background-color: #000;
    will-change: opacity;
    animation-name: effectNoiseAnimationReverse;
    animation-delay: var(--effectNoiseDelayReverse);
    animation-duration: .3s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.6, 1);
    // backdrop-filter: blur(30px);
    // mix-blend-mode: difference;

    &-wrapper {
      display: grid;
      width: 100%;
      height: 100%;
      grid-template-columns: repeat(10, 1fr);
      position: absolute;
      top: 0;
      z-index: 1;
      transition: $TRANSITION_BASE;
    }
  }

  @include pc {
    &:hover {
      opacity: 1;

      .noise {
        opacity: 0;
        animation-name: effectNoiseAnimation;
        animation-fill-mode: forwards;
        animation-delay: var(--effectNoiseDelay);
        animation-timing-function: cubic-bezier(0.2, 0.8, 0.6, 1);

        &-wrapper {
          box-shadow: inset 0 0 10px #33333320;
        }
      }
    }
  }
}

@keyframes effectNoiseAnimationReverse {
  0% {
    opacity: var(--noiseOpacity);
  }

  100% {
    opacity: 0;
  }
}

@keyframes effectNoiseAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: var(--noiseOpacity);
  }
}