.service-nav {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  &__item {
    width: rem(184);
    max-width: 25%;
    padding-left: rem(17);
    text-align: left;
  }

  &__head {
    a {
      background: $COLOR_BLACK;
      color: $COLOR_WHITE;
      @include fz(14, 21);
      padding: rem(2) rem(0) rem(2) rem(26);
      font-weight: 700;
      display: flex;
      border-radius: rem(25);
      letter-spacing: 0.1em;
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: rem(15);
        width: rem(5);
        height: rem(8);
        content: "";
        z-index: 1;
        background: url("../img/common/ico_arrow_menu_white.svg") no-repeat left center / cover;
      }
    }
  }

  &__sub {
    margin-top: rem(10);
    padding-left: rem(15);

    li {
      line-height: 1;

      &:nth-child(n+2) {
        margin-top: rem(2);
      }
    }

    span {
      display: inline-flex;
      @include fz(14, 25);
      font-weight: 700;
      letter-spacing: 0.1em;
      letter-spacing: 0.075em;
      position: relative;

      &::before {
        position: absolute;
        z-index: 1;
        bottom: 0.1rem;
        left: 0;
        transform: scaleX(0);
        transform-origin: right;
        content: none;
        width: 100%;
        background: $COLOR_BLACK;
        height: 1px;
        transition: transform 0.3s ease;
      }

      @include pc {
        &:hover {
          &::before {
            transform: scaleY(1);
            transform-origin: left;
          }
        }
      }
    }
  }
}

@include pc {
  .service-nav {
    &__head {
      a {
        transition: $TRANSITION_BASE;
        border: 1px solid $COLOR_BLACK;

        &::after {
          transition: $TRANSITION_BASE;
        }

        &:hover {
          background: $COLOR_WHITE;
          color: $COLOR_BLACK;
          opacity: 1;

          &::after {
            background-image: url("../img/common/ico_arrow_menu_black.svg");
          }
        }

        @media screen and (max-width: 1100px) {
          .header-nav-sub & {
            border: 1px solid $COLOR_WHITE;

            &:hover {
              background: $COLOR_BLACK;
              color: $COLOR_WHITE;

              &::after {
                background-image: url("../img/common/ico_arrow_menu_white.svg");
              }
            }
          }
        }
      }
    }
  }
}

@include sp {
  .service-nav {
    &__list {
      justify-content: space-between;
      gap: vw(50);
    }

    &__item {
      width: calc(50% - #{vw(25)});
      max-width: calc(50% - #{vw(25)});
      padding-left: 0;
    }

    &__head {
      a {
        @include fz(24, 38);
        padding: vw(1) vw(0) vw(4) vw(40);
        border-radius: vw(45);

        &::after {
          width: rem(10);
          height: rem(16);
          left: rem(18);
        }
      }
    }

    &__sub {
      margin-top: vw(18);
      padding-left: vw(18);
      padding-bottom: vw(10);

      li {
        &:nth-child(n+2) {
          margin-top: vw(3);
        }
      }

      span {
        @include fz(24, 42);
      }
    }
  }
}

@include sp_lg {
  .service-nav {
    &__list {
      gap: vw(30);
    }

    &__item {
      width: calc(50% - #{vw(20)});
      max-width: calc(50% - #{vw(20)});
    }

    &__head {
      a {
        @include fz(16);
        padding: vw(3) vw(0) vw(5) vw(33);

        &::after {
          width: rem(7);
          height: rem(11.2);
        }
      }
    }

    &__sub {
      margin-top: vw(15);
      padding-left: vw(17);

      span {
        @include fz(16);
      }
    }
  }
}