.c-fv {
  position: relative;
  z-index: 2;
  margin-bottom: rem(30);
  transition-delay: 0.3s;

  &__inner {
    width: 75%;
    max-width: rem(1440);
    margin: 0 auto;
    padding: 0 rem(30);
  }

  &__img {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    display: block;
    transform: scale(calc(1 - 0.14136*(var(--scroll-progress) - 1) * -1));
    transform-origin: center center;
    transition: unset !important;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      scale: calc(1 + .2*(var(--scroll-progress) - 1)* -1);
    }
  }

  @include tablet {
    &__inner {
      width: 94%;
    }
  }

  @include sp {
    &__inner {
      width: 100%;
    }

    &__img {
      transform: scale(calc(1 - 0.096*(var(--scroll-progress) - 1) * -1));
    }
  }
}