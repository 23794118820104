.p-service {
  position: relative;

  .layer-main {
    position: relative;
    z-index: 2;
  }

  .fv-deco {
    position: absolute;
    top: rem(-105);
    width: rem(2903);
    left: 50%;
    transform: translateX(calc(-50% - #{rem(58)}));
    z-index: 2;
    pointer-events: none;
    transition-delay: 1s;

    &__bg {
      opacity: 0;
    }

    &__el {
      position: absolute;
      z-index: 2;

      &.el-l01 {
        width: 106.5478px;
        width: w_fvs(106.5478);
        top: 914px;
        top: h_fvs(914);
        left: 931px;
        left: w_fvs(931);
      }

      &.el-l02 {
        width: 174.2199px;
        width: w_fvs(174.2199);
        top: 464px;
        top: h_fvs(464);
        left: 954px;
        left: w_fvs(954);
      }

      &.el-l03 {
        width: 17.2745px;
        width: w_fvs(17.2745);
        top: 757px;
        top: h_fvs(757);
        left: 921px;
        left: w_fvs(921);
      }

      &.el-l04 {
        width: 106.5478px;
        width: w_fvs(106.5478);
        top: 474px;
        top: h_fvs(474);
        left: 852px;
        left: w_fvs(852);
      }

      &.el-l05 {
        width: 27.4422px;
        width: w_fvs(27.4422);
        top: 345px;
        top: h_fvs(345);
        left: 893px;
        left: w_fvs(893);
      }

      &.el-l06 {
        width: 129.6381px;
        width: w_fvs(129.6381);
        top: 715px;
        top: h_fvs(715);
        left: 742px;
        left: w_fvs(742);
      }

      &.el-l07 {
        width: 158.8294px;
        width: w_fvs(158.8294);
        top: 308px;
        top: h_fvs(308);
        left: 666px;
        left: w_fvs(666);
      }

      &.el-l08 {
        width: 123.7541px;
        width: w_fvs(123.7541);
        // top: 1060px;
        bottom: 12px;
        bottom: h_fvs(12);
        left: 603px;
        left: w_fvs(603);
      }

      &.el-l09 {
        width: 219.3968px;
        width: w_fvs(219.3968);
        top: 792px;
        top: h_fvs(792);
        left: 470px;
        left: w_fvs(470);
      }

      &.el-l10 {
        width: 400.1568px;
        width: w_fvs(400.1568);
        top: 437px;
        top: h_fvs(437);
        left: 471px;
        left: w_fvs(471);
      }

      &.el-l11 {
        --animate-duration: 3s;
        width: 152.3843px;
        width: w_fvs(152.3843);
        top: 249px;
        top: h_fvs(249);
        left: 498px;
        left: w_fvs(498);
      }

      &.el-l12 {
        width: 78.9368px;
        width: w_fvs(78.9368);
        top: 617px;
        top: h_fvs(617);
        left: 432px;
        left: w_fvs(432);
      }

      &.el-l13 {
        width: 400.157px;
        width: w_fvs(400.157);
        top: 602px;
        top: h_fvs(602);
        left: 0px;
        left: w_fvs(0);
      }

      &.el-l14 {
        width: 271.6834px;
        width: w_fvs(271.6834);
        top: 350px;
        top: h_fvs(350);
        left: 106px;
        left: w_fvs(106);
      }

      &.el-l15 {
        width: 13.2884px;
        width: w_fvs(13.2884);
        bottom: 0px;
        bottom: h_fvs(0);
        left: 794px;
        left: w_fvs(794);
      }

      &.el-r01 {
        width: 81.8745px;
        width: w_fvs(81.8745);
        top: 346px;
        top: h_fvs(346);
        right: 952px;
        right: w_fvs(952);
      }

      &.el-r02 {
        width: 13.2884px;
        width: w_fvs(13.2884);
        top: 466px;
        top: h_fvs(466);
        right: 1015px;
        right: w_fvs(1015);
      }

      &.el-r03 {
        width: 13.2884px;
        width: w_fvs(13.2884);
        top: 586px;
        top: h_fvs(586);
        right: 927px;
        right: w_fvs(927);
      }

      &.el-r04 {
        width: 17.2743px;
        width: w_fvs(17.2743);
        top: 752px;
        top: h_fvs(752);
        right: 904px;
        right: w_fvs(904);
      }

      &.el-r05 {
        width: 70.685px;
        width: w_fvs(70.685);
        top: 221px;
        top: h_fvs(221);
        right: 800px;
        right: w_fvs(800);
      }

      &.el-r06 {
        width: 17.2745px;
        width: w_fvs(17.2745);
        top: 304px;
        top: h_fvs(304);
        right: 788px;
        right: w_fvs(788);
      }

      &.el-r07 {
        width: 158.8293px;
        width: w_fvs(158.8293);
        top: 795px;
        top: h_fvs(795);
        right: 740px;
        right: w_fvs(740);
      }

      &.el-r08 {
        width: 169.1172px;
        width: w_fvs(169.1172);
        top: 332px;
        top: h_fvs(332);
        right: 595px;
        right: w_fvs(595);
      }

      &.el-r09 {
        width: 171.3224px;
        width: w_fvs(171.3224);
        top: 553px;
        top: h_fvs(553);
        right: 614px;
        right: w_fvs(614);
      }

      &.el-r10 {
        width: 106.5463px;
        width: w_fvs(106.5463);
        top: 840px;
        top: h_fvs(840);
        right: 595px;
        right: w_fvs(595);
      }

      &.el-r11 {
        width: 172.019px;
        width: w_fvs(172.019);
        bottom: 100px;
        bottom: h_fvs(100);
        right: 512px;
        right: w_fvs(512);
      }

      &.el-r12 {
        width: 400.1568px;
        width: w_fvs(400.1568);
        top: 0px;
        top: h_fvs(0);
        right: 153px;
        right: w_fvs(153);
      }

      &.el-r13 {
        width: 39.0933px;
        width: w_fvs(39.0933);
        top: 448px;
        top: h_fvs(448);
        right: 482px;
        right: w_fvs(482);
      }

      &.el-r14 {
        width: 17.2745px;
        width: w_fvs(17.2745);
        top: 586px;
        top: h_fvs(586);
        right: 518px;
        right: w_fvs(518);
      }

      &.el-r15 {
        width: 36.6545px;
        width: w_fvs(36.6545);
        top: 798px;
        top: h_fvs(798);
        right: 525px;
        right: w_fvs(525);
      }

      &.el-r16 {
        width: 17.2745px;
        width: w_fvs(17.2745);
        bottom: 40px;
        bottom: h_fvs(40);
        right: 511px;
        right: w_fvs(511);
      }

      &.el-r17 {
        width: 174.7609px;
        width: w_fvs(174.7609);
        top: 602px;
        top: h_fvs(602);
        right: 330px;
        right: w_fvs(330);
      }

      &.el-r18 {
        width: 208.5819px;
        width: w_fvs(208.5819);
        top: 212px;
        top: h_fvs(212);
        right: 0px;
        right: w_fvs(0);
      }

      &.el-r19 {
        width: 176.7552px;
        width: w_fvs(176.7552);
        top: 623px;
        top: h_fvs(623);
        right: 86px;
        right: w_fvs(86);
      }

      &.el-r20 {
        width: 203.4181px;
        width: w_fvs(203.4181);
        bottom: 172px;
        bottom: h_fvs(172);
        right: 140px;
        right: w_fvs(140);
      }
    }
  }

  .fv {
    position: relative;
    z-index: 3;

    &__inner {
      perspective: rem(1000);
    }

    &-img {
      max-width: rem(880);
      margin: 0 auto;
      transition-delay: 0.5s;
    }
  }

  .service-menu {
    padding-top: rem(160);

    &__inner {}

    &__list {
      display: flex;
      justify-content: center;
    }

    &__ico {
      height: rem(88);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      img {
        height: auto;
        max-height: 100%;
      }
    }

    &__desc {
      margin-top: rem(30);
    }

    &__item {
      width: rem(187);
      max-width: 25%;
      padding: 0 rem(10);

      @include pc {
        @include nthLoad(4);
      }

      &:nth-child(1) {
        .service-menu__ico img {
          max-width: rem(66.12);
        }
      }

      &:nth-child(2) {
        .service-menu__ico img {
          max-width: rem(113.4);
        }
      }

      &:nth-child(3) {
        .service-menu__ico img {
          max-width: rem(82.1);
        }
      }

      &:nth-child(4) {
        .service-menu__ico img {
          max-width: rem(110);
        }
      }
    }
  }

  .service-card {
    padding: rem(80) 0;

    &__inner {
      @media screen and (max-width: 1040px) {
        padding: 0;
      }
    }

    &__units {}

    &__desc {
      background: $COLOR_WHITE;
      width: rem(549);
      flex-shrink: 0;
      position: relative;
      z-index: 2;

      &__inner {
        width: rem(457);
        max-width: 100%;
        margin: 0 auto;
        padding: rem(65) rem(30) rem(80);
      }
    }

    &__title {
      @include fz(46, 60);
      font-weight: 700;
      margin-bottom: vw(10);
      letter-spacing: 0.03em;
    }

    &__copy {
      letter-spacing: 0.03em;
    }

    &__btn {
      margin-top: rem(45);

      a {
        @include fz(17, 29);
        letter-spacing: 0.03em;
      }
    }

    &__pic {
      &__inner {
        overflow: hidden;
      }
    }

    &__unit {
      padding: rem(80) 0;

      &__inner {
        display: flex;
        align-items: flex-start;
      }

      &:nth-child(2n+1) {
        .service-card__unit__inner {
          flex-direction: row-reverse;
        }
      }

      &:nth-child(1) {
        .service-card {
          &__desc {
            margin-top: rem(110);
          }

          &__pic {
            margin-left: rem(-227);
            margin-right: rem(-470);
          }
        }
      }

      &:nth-child(2) {
        .service-card {
          &__pic {
            margin-top: rem(154);
            margin-left: rem(-340);
            margin-right: rem(-46);
          }
        }
      }

      &:nth-child(3) {
        .service-card {
          &__desc {
            margin-top: rem(105);
          }

          &__pic {
            margin-left: rem(-134);
            margin-right: rem(-190);
          }
        }
      }

      &:nth-child(4) {
        .service-card {
          &__pic {
            margin-top: rem(185);
            margin-left: rem(-357);
            margin-right: rem(-340);
          }
        }
      }
    }
  }

  .service-flow {
    &__inner {
      max-width: rem(860);
      margin: 0 auto;
    }

    &__title {
      background: $COLOR_GRAY;
      @include fz(20, 30);
      font-weight: 700;
      letter-spacing: 0.03em;
      padding: rem(13) rem(30);
      border-radius: rem(56);
    }

    &__copy {
      padding: rem(30) rem(30) rem(50);
      letter-spacing: 0.03em;
    }

    &__deco {
      position: relative;

      li {
        position: absolute;
        z-index: 2;

        &:nth-child(1) {
          width: rem(175.659);
          left: rem(-230);
          top: rem(-170);
        }

        &:nth-child(2) {
          top: rem(-210);
          width: rem(342);
          right: rem(-383);
        }
      }
    }

    &__more {
      &-btn {
        width: rem(60);
        cursor: pointer;
        margin: 0 auto rem(45);
        padding: rem(10);

        img {
          transition: $TRANSITION_BASE;
        }

        &.is-active {
          img {
            transform: rotate(180deg);
          }
        }
      }

      &-bg {
        padding-top: rem(65);
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          left: -200%;
          transform: translateX(-50%);
          height: 100%;
          width: 120vw;
          z-index: 1;
          content: "";
          background: $COLOR_GRAY;
          opacity: 0;
          transition: 0.5s all ease 0.3s;
        }

        &.is-show {
          &::before {
            opacity: 1;
            left: 50%;
            transition: 0.5s all ease 0.1s;
          }
        }

        .service-flow__title {
          background: $COLOR_WHITE;
        }
      }

      &-ctn {
        padding: 0 0 rem(90);

        // &.is-active {
        //   .service-flow__more-bg::before {
        //     opacity: 1;
        //     left: 50%;
        //     transition: 0.5s all ease 0.1s;
        //   }
        // }
      }

      &-inner {
        position: relative;
        z-index: 2;
      }

      .service-flow__title {
        background: $COLOR_WHITE;
      }
    }

    &__item-last {
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      z-index: 2;

      .service-flow {
        &__img {
          width: rem(570);
          margin-right: rem(-162);
          flex-shrink: 0;
        }

        &__desc {
          padding-right: rem(20);
          flex-grow: 1;
          padding-top: rem(50);
        }

        &__title {
          background: $COLOR_WHITE;
          position: relative;
          z-index: 100;
        }

        &__copy {
          padding-top: 0;
        }
      }
    }
  }

  @include laptop {
    .service-card {
      &__unit {
        &:nth-child(1) {
          .service-card {
            &__pic {
              margin-left: rem(-237);
              margin-right: rem(-193);
              margin-right: calc(50% - 50vw);
            }
          }
        }

        &:nth-child(2) {
          .service-card {
            &__pic {
              margin-left: rem(-193);
              margin-left: calc(50% - 50vw);
              margin-right: rem(-193);
            }
          }
        }

        &:nth-child(3) {
          .service-card {
            &__pic {
              margin-right: rem(-193);
              margin-right: calc(50% - 50vw);
            }
          }
        }

        &:nth-child(4) {
          .service-card {
            &__pic {
              margin-left: rem(-193);
              margin-left: calc(50% - 50vw);
              margin-right: rem(-316);
            }
          }
        }
      }
    }

    .service-flow {
      &__deco {
        li {
          &:nth-child(1) {
            left: rem(-230);
            top: rem(-170);
          }
        }
      }
    }
  }

  @include laptop_sm {
    .service-flow {
      &__deco {
        li {
          &:nth-child(1) {
            left: rem(-120);
            top: rem(-130);
          }

          &:nth-child(2) {
            top: rem(-180);
            right: rem(-250);
          }
        }
      }
    }
  }

  @include tablet_lg {
    .bg-deco li:nth-child(2) {
      width: rem(3100);
      top: -35vw;
    }
  }

  @include tablet {
    .bg-deco li:nth-child(2) {
      width: rem(2600);
      top: rem(360);
    }

    .fv-deco {
      top: rem(0);
      width: rem(1787);
      width: vw_sm(2260);
      transform: translateX(calc(-50% - #{rem(45)}));
    }

    .fv {
      &-img {
        max-width: vw_sm(690);
      }
    }

    .service-menu {
      &__list {
        margin-left: rem(-10);
        margin-right: rem(-10);
      }

      &__item {
        width: rem(183);
        padding: 0 rem(8);
      }
    }

    .service-card {
      &__desc {
        max-width: 60%;

        &__inner {
          width: vw_sm(497);
          max-width: 100%;
          margin: 0 auto;
          padding: vw_sm(65) vw_sm(50) vw_sm(80);
        }
      }

      &__title {
        font-size: vw_sm(46);
      }

      &__unit {
        &:nth-child(1) {
          .service-card {
            &__desc {
              margin-top: vw_sm(110);
            }

            &__pic {
              margin-left: vw_sm(-227);
              margin-right: 0;
            }
          }
        }

        &:nth-child(2) {
          .service-card {
            &__pic {
              margin-top: vw_sm(154);
              margin-left: 0;
              margin-right: vw_sm(-136);
            }
          }
        }

        &:nth-child(3) {
          .service-card {
            &__desc {
              margin-top: vw_sm(105);
            }

            &__pic {
              margin-left: vw_sm(-134);
              margin-right: 0;
            }
          }
        }

        &:nth-child(4) {
          .service-card {
            &__pic {
              margin-top: vw_sm(185);
              margin-left: 0;
              margin-right: vw_sm(-340);
            }
          }
        }
      }
    }

    .service-flow {
      &__deco {
        li {
          &:nth-child(1) {
            width: rem(102.3611);
            top: rem(-50);
            left: vw_sm(-60);
          }

          &:nth-child(2) {
            width: rem(190);
            top: rem(-75);
            right: vw_sm(-60);
          }
        }
      }
    }
  }
}

.s-service {
  &-headline {
    text-align: center;
    position: relative;
    z-index: 2;
    padding-left: vw_pc(20);
    padding-right: vw_pc(20);

    &__text {
      display: inline-block;
      @include fz(176, 189);
      @include fz_min_pc(176);
      font-weight: 700;

      &.js-inview {
        opacity: 0;

        &.is-show {
          opacity: 1;
        }
      }
    }
  }

  &-mv {
    padding-top: rem(190);

    &__inner {
      margin-top: rem(-70);
      margin-top: vw_pc(-70);
    }
  }

  &-gallery {
    margin-right: calc(50% - 50vw);

    .splide {
      padding-bottom: rem(30);

      &__slide {
        width: rem(622);
        padding-right: rem(22);
        aspect-ratio: 600/400;
      }

      &__pagination {
        bottom: 0;
        text-align: left;
        justify-content: flex-start;
        padding: 0;

        &__page {
          width: rem(60);
          height: rem(20);
          border-radius: 0;
          margin: 0 rem(11) 0 0;
          transition: 0.3s;
          position: relative;
          background: transparent;
          opacity: 1;
          transition: $TRANSITION_BASE;

          &::after {
            position: absolute;
            content: "";
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            height: rem(4);
            background: #c6c6c6;
            transition: $TRANSITION_BASE;
          }

          &.is-active {
            transform: scale(1);

            &::after {
              background: #000 !important;
            }
          }

          @include pc {
            &:hover {
              &::after {
                background: rgba(#000, 0.6);
              }
            }
          }
        }
      }
    }
  }

  &-content {
    padding-bottom: rem(140);

    &__inner {
      max-width: rem(913);
      padding: 0 rem(10);
      margin: 0 auto;
    }

    .u-mr-ng {
      @include pc {
        margin-right: rem(-15);
      }
    }
  }

  &-block {
    margin-top: rem(90);

    &__row {
      display: flex;

      &.items-center {
        align-items: center;
      }
    }

    &__desc {
      @include pc {
        flex-grow: 1;
        @include fz(13, 22.75);
        padding-right: rem(50);
      }
    }

    &__img {
      width: rem(330);
      max-width: 45%;
      flex-shrink: 0;
    }
  }

  &-price {
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 rem(-25);
    }

    &__item {
      width: 50%;
      flex-shrink: 0;
      min-width: rem(465);
      margin: 0 auto;

      &__inner {
        padding: rem(80) rem(25) 0;
      }
    }

    &__hdg {
      display: flex;
      align-items: flex-end;
      margin-bottom: rem(20);

      .ico {
        flex-shrink: 0;
        min-height: rem(61.5);
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: rem(68);

        &-sz01 {
          img {
            width: rem(61.5);
          }
        }

        &-sz02 {
          img {
            width: rem(53.8182);
          }
        }

        &-sz03 {
          img {
            width: rem(55);
          }
        }

        &-sz04 {
          img {
            width: rem(53.0352);
          }
        }

        &-sz05 {
          img {
            width: rem(51.2);
          }
        }

        &-sz06 {
          img {
            width: rem(52);
          }
        }

        &-sz07 {
          img {
            width: rem(51);
          }
        }
      }

      .txt {
        @include fz(20, 35);
        font-weight: 700;
      }
    }

    &__data {
      @include fz(14, 19);

      dl {
        display: flex;
        align-items: flex-end;
        border-bottom: 1px solid #c4c4c4;
        padding: rem(10) rem(20);
      }

      dt {
        flex-grow: 1;
        padding-right: rem(10);
        font-weight: 400;
        color: $COLOR_BLACK3;
      }

      dd {
        @include fz(16, 19);
        font-weight: 700;
        flex-shrink: 0;
      }

      &--02 {
        @include fz(20);

        dt {
          font-weight: 700;
          color: $COLOR_BLACK;
        }

        dd {
          @include fz(20);
        }
      }
    }

    &__note {
      text-align: right;
      margin-top: rem(10);
      padding-right: rem(20);
      font-size: rem(11);
      font-weight: 400;
      color: $COLOR_BLACK3;
    }
  }

  &-visit {
    max-width: rem(680);
    margin: rem(60) auto 0;

    &__main {
      position: relative;
    }

    &__caption {
      position: absolute;
      z-index: 1;
      bottom: rem(30);
      left: 0;
      @include fz(23, 42);
      font-weight: 700;
      padding: rem(10) rem(30);
      min-width: rem(339);
      text-align: center;
      background: $COLOR_WHITE;
      border-radius: 0 rem(62) rem(62) 0;
      overflow: hidden;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $COLOR_BLACK;
        z-index: 1;
        content: "";
        will-change: clip-path;
        clip-path: inset(0 0 0 0);
        transition: clip-path 0.5s cubic-bezier(0.4, 0.4, 0, 1) 0.2s;
      }

      span {
        position: relative;
        z-index: 2;
      }

      &.is-show {
        &::before {
          clip-path: inset(0 0 0 100%);
        }
      }
    }

    &__btn {
      margin-top: rem(65);
      display: flex;
      justify-content: center;
    }
  }

  &-web-about {
    padding-top: rem(30);

    &__main {
      display: flex;
      align-items: flex-end;
      margin-right: calc(50% - 50vw + 0.5rem);
    }

    &__pht {
      order: 2;
      // width: calc(100% - #{rem(592)} + #{rem(100)});
      width: rem(940);
      margin-left: rem(-130);
      margin-bottom: rem(60);
      flex-shrink: 0;
    }

    &__desc {
      background: #ececec;
      width: rem(592);
      flex-shrink: 0;
      padding: rem(110) rem(90);
      position: relative;
      z-index: 2;

      h3 {
        @include fz(30, 52);
        font-weight: 700;
        margin-bottom: rem(28);
      }
    }

    &__list {
      margin-top: rem(80);
      display: flex;
      flex-wrap: wrap;
      gap: rem(80) rem(22);
    }

    &__item {
      width: calc(33.3333% - (#{rem(44)} / 3));
    }

    &__hdg {
      text-align: center;
      @include fz(16, 24);
      font-weight: 700;
    }

    &__ico {
      width: rem(125);
      margin: rem(25) auto;
    }

    &__copy {
      position: relative;
      font-weight: 400;
      height: rem(129);
      overflow: hidden;
      padding-bottom: rem(30);
      color: $COLOR_BLACK3;
      line-height: rem(21);

      @include pc {
        @include fz(13, 21);
      }
    }

    &__more {
      text-align: center;
      width: 100%;
      height: rem(68);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background: rgba(#fff, 0.8);
      background: linear-gradient(0deg, rgba(#fff, 1) 0%, rgba(#fff, 1) 35%, rgba(#fff, 1) 35%, rgba(#fff, 0) 100%);
      display: flex;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer;
      transition: $TRANSITION_BASE;

      .txt {
        @include fz(16, 24);
        font-weight: 700;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        color: $COLOR_BLACK;
      }

      .txt-01 {
        opacity: 1;
      }

      .txt-02 {
        opacity: 0;
      }

      .is-more & {
        background: transparent;

        .txt-01 {
          opacity: 0;
        }

        .txt-02 {
          opacity: 1;
        }
      }
    }
  }

  &-video {
    margin-top: rem(45);

    video {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }
  }

  &-features {
    padding-top: rem(20);

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: rem(40) rem(38.5);
    }

    &__item {
      width: calc((100% - #{rem(38.5)} * 2) / 3);
    }

    &__hdg {
      @include fz(16, 28);
      font-weight: 700;
      text-align: center;
      margin-bottom: rem(20);
    }
  }

  &-sns {
    padding: rem(20) rem(20) rem(40);

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    &__item {
      width: rem(211);
      max-width: 28%;
      @include nthLoad(3);
    }

    &__link {
      display: block;
    }
  }

  @include laptop() {
    &-web-about {
      &__pht {
        margin-left: rem(-356);
      }
    }
  }

  @include tablet_lg() {
    &-web-about {
      &__pht {
        margin-left: rem(-420);
      }
    }
  }

  @include tablet() {
    &.p-service .bg-deco {
      left: 0;

      li:nth-child(1) {
        width: 100vw;
      }
    }

    &-headline {
      padding-left: vw_pc(0);
      padding-right: vw_pc(0);

      &__text {
        @include fz_min_sm(93.51);
      }
    }

    &-mv {
      padding-top: rem(140);

      &__inner {
        margin-top: vw_sm(80);
      }
    }

    &-web-about {
      padding-top: rem(30);

      &__main {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-right: rem(-40);
      }

      &__pht {
        width: 100%;
        margin-left: 0;
        margin-bottom: 0;
        flex-shrink: 0;
      }

      &__desc {
        margin-top: rem(-104);
        margin-left: rem(-40);
      }
    }

    &-features {
      padding-top: rem(20);

      &__list {
        max-width: rem(584);
        margin: 0 auto;
        gap: rem(40) rem(40);
      }

      &__item {
        width: calc(50% - 2rem);
      }
    }

    &-sns {
      padding: rem(20) rem(20) rem(40);
    }
  }
}
