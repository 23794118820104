body.is-modal-open {
  // overflow: hidden;
}

.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: $Z_MODAL;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.85);
  transition: 0.3s;

  &__overlay {}

  &__inner {
    background: $COLOR_GRAY;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  &__body {
    max-width: 90%;
    max-height: 80vh;
    position: relative;
  }

  &__close {
    position: absolute;
    top: -0.8rem;
    right: 0;
    transform: translateY(-100%);
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
    transition: $TRANSITION_BASE;

    @include pc {
      &:hover {
        opacity: $OPACITY_HOVER;
      }
    }
  }

  &__content {
    width: 100%;
    max-height: 80vh;
    overflow: auto;

    &__inner {
      background: #fff;
    }
  }

  &.is-show {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}