@keyframes arrow-btn {

  0%,
  to {
    transform: translate(0, -50%);
    opacity: 1;
  }

  59% {
    opacity: 0;
  }

  49.9% {
    transform: translate(50%, -50%);
    opacity: 0;
  }

  50% {
    transform: translate(-50%, -50%);
    opacity: 0;
  }
}

@keyframes marquee {

  0%,
  100% {
    opacity: 1;
    transform: translateX(0%);
  }

  99.995% {
    opacity: 1;
    transform: translateX(-100%);
  }

  99.996% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes loader-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes scroll_gallery {
  0% {
    background-position-x: 0;
  }

  to {
    background-position-x: rem(-1890);
  }
}

@keyframes flash {

  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {

  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }
}

@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes slideRightUp {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  30% {
    opacity: 1;
    transform: translate(9rem, -9rem);
  }

  90% {
    opacity: 0;
    transform: translate(27rem, -27rem);
  }

  100% {
    opacity: 0;
    transform: translate(30rem, -30rem);
  }
}

@keyframes slideRightUpH {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  30% {
    opacity: 1;
    transform: translate(4.5rem, -4.5rem);
  }

  90% {
    opacity: 0;
    transform: translate(13.5rem, -13.5rem);
  }

  100% {
    opacity: 0;
    transform: translate(15rem, -15rem);
  }
}

@keyframes slideLeftDown {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  30% {
    opacity: 1;
    transform: translate(-9rem, 9rem);
  }

  90% {
    opacity: 0;
    transform: translate(-27rem, 27rem);
  }

  100% {
    opacity: 0;
    transform: translate(-30rem, 30rem);
  }
}

@keyframes slideLeftDownH {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  30% {
    opacity: 1;
    transform: translate(-4.5rem, 4.5rem);
  }

  90% {
    opacity: 0;
    transform: translate(-13.5rem, 13.5rem);
  }

  100% {
    opacity: 0;
    transform: translate(-15rem, 15rem);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}