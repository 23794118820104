// breakpoint -------------//

// 2K > 1920 > 1366 > 1200 > 1100 > 980 > 768

// >= 1921
@mixin wide2k() {
  @media screen and (min-width: ($WIDTH_PC+1)) {
    @content;
  }
}

// 1366 - 981
@mixin laptop() {
  @media screen and (min-width: ($WIDTH_TABLET+1)) and (max-width: $WIDTH_LAPTOP) {
    @content;
  }
}

// 1200 - 981
@mixin laptop_sm() {
  @media screen and (min-width: ($WIDTH_TABLET+1)) and (max-width: $WIDTH_LAPTOP_SM) {
    @content;
  }
}

// <= 1100
@mixin tablet_lg_max() {
  @media screen and (max-width: $WIDTH_TABLET_LG) {
    @content;
  }
}

// 1100 - 768
@mixin tablet_lg() {
  @media screen and (min-width: ($WIDTH_SP+1)) and (max-width: $WIDTH_TABLET_LG) {
    @content;
  }
}

// 980 - 768
@mixin tablet() {
  @media screen and (min-width: ($WIDTH_SP+1)) and (max-width: $WIDTH_TABLET) {
    @content;
  }
}

// >= 768
@mixin pc() {
  @media screen and (min-width: ($WIDTH_SP+1)) {
    @content;
  }
}

// < 768
@mixin sp() {
  @media screen and (max-width: $WIDTH_SP) {
    @content;
  }
}

@mixin sp_lg() {

  @media screen and (max-width: 767px) and (min-width: 480px),
  screen and (max-width: 767px) and (orientation: landscape) {
    @content;
  }
}

@mixin clearfix() {

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::after {
    clear: both;
  }
}

@mixin nthLoad($NUMBER_OF_ITEMS: 5) {
  @include pc() {
    @for $i from 0 through $NUMBER_OF_ITEMS {
      &:nth-child(#{$i+1}) {
        transition-delay: calc(var(--el-delay) + 0.15s * $i) !important;
      }
    }
  }
}

@mixin fz($FONT_SIZE, $LINE_HEIGHT: null) {
  font-size: calc($FONT_SIZE / 10)+rem;

  @if $LINE_HEIGHT !=null {
    line-height: calc($LINE_HEIGHT / $FONT_SIZE);
  }
}

@mixin fz_min_pc($FONT_SIZE, $LINE_HEIGHT: null) {
  $rem: calc($FONT_SIZE / 10)+rem;
  $vw: calc($FONT_SIZE / $DESIGN_WIDTH_PC) * 100vw;
  $font-size-value: min($rem, $vw);
  font-size: $rem;
  font-size: $font-size-value;

  @if $LINE_HEIGHT !=null {
    line-height: calc($LINE_HEIGHT / $FONT_SIZE);
  }
}

@mixin fz_min_md($FONT_SIZE, $LINE_HEIGHT: null) {
  $rem: calc($FONT_SIZE / 10)+rem;
  $vw: calc($FONT_SIZE / $DESIGN_WIDTH_LAPTOP) * 100vw;
  $font-size-value: min($rem, $vw);
  font-size: $rem;
  font-size: $font-size-value;

  @if $LINE_HEIGHT !=null {
    line-height: calc($LINE_HEIGHT / $FONT_SIZE);
  }
}

@mixin fz_min_sm($FONT_SIZE, $LINE_HEIGHT: null) {
  $rem: calc($FONT_SIZE / 10)+rem;
  $vw: calc($FONT_SIZE / $DESIGN_WIDTH_TABLET) * 100vw;
  $font-size-value: min($rem, $vw);
  font-size: $rem;
  font-size: $font-size-value;

  @if $LINE_HEIGHT !=null {
    line-height: calc($LINE_HEIGHT / $FONT_SIZE);
  }
}

@mixin fz_min_h($FONT_SIZE, $LINE_HEIGHT: null) {
  $rem: calc($FONT_SIZE / 10)+rem;
  $vw: calc($FONT_SIZE / 1070) * 100vw;
  $font-size-value: min($rem, $vw);
  font-size: $rem;
  font-size: $font-size-value;

  @if $LINE_HEIGHT !=null {
    line-height: calc($LINE_HEIGHT / $FONT_SIZE);
  }
}

@mixin fz_min_h_sp($FONT_SIZE, $LINE_HEIGHT: null) {
  $rem: calc($FONT_SIZE / 10)+rem;
  $vh: calc($FONT_SIZE / 1334) * 100svh;
  $font-size-value: min($rem, $vh);
  font-size: $rem;
  font-size: $font-size-value;

  @if $LINE_HEIGHT !=null {
    line-height: calc($LINE_HEIGHT / $FONT_SIZE);
  }
}

@mixin fz_min($FONT_SIZE, $LINE_HEIGHT: null) {
  $rem: calc($FONT_SIZE / 10)+rem;
  $vw: calc($FONT_SIZE / $DESIGN_WIDTH_SP) * 100vw;
  $font-size-value: min($rem, $vw);
  font-size: $rem;
  font-size: $font-size-value;

  @if $LINE_HEIGHT !=null {
    line-height: calc($LINE_HEIGHT / $FONT_SIZE);
  }
}

@function per_pc($design_width_psd) {
  $per: calc($design_width_psd / $DESIGN_WIDTH_PC * 100%);
  @return #{$per};
}

@function per_md($design_width_psd) {
  $per: calc($design_width_psd / $DESIGN_WIDTH_LAPTOP * 100%);
  @return #{$per};
}

@function per_sm($design_width_psd) {
  $per: calc($design_width_psd / $DESIGN_WIDTH_TABLET * 100%);
  @return #{$per};
}

@function per($design_width_psd) {
  $per: calc($design_width_psd / $DESIGN_WIDTH_SP * 100%);
  @return #{$per};
}

@function vw_pc($design_width_psd) {
  $vw: calc($design-width-psd / $DESIGN_WIDTH_PC) * 100;
  @return #{$vw}vw;
}

@function vw_md($design_width_psd) {
  $vw: calc($design-width-psd / $DESIGN_WIDTH_LAPTOP) * 100;
  @return #{$vw}vw;
}

@function vw_sm_1($design_width_psd) {
  $vw: calc($design-width-psd / $DESIGN_WIDTH_TABLET_LG) * 100;
  @return #{$vw}vw;
}

@function vw_sm($design_width_psd) {
  $vw: calc($design-width-psd / $DESIGN_WIDTH_TABLET) * 100;
  @return #{$vw}vw;
}

@function vw($design_width_psd) {
  $vw: calc($design-width-psd / $DESIGN_WIDTH_SP) * 100;
  @return #{$vw}vw;
}

@function rem($design_px) {
  $rem: calc($design_px / 10);
  @return $rem+rem;
}

// FOR DECO FV SERVICE
@function w_fvs($design_width_psd, $DESIGN_WIDTH_DECO: 2903) {
  $per: calc($design_width_psd / $DESIGN_WIDTH_DECO * 100%);
  @return #{$per};
}

@function h_fvs($design_height_psd, $DESIGN_HEIGHT_DECO: 1196) {
  $per: calc($design_height_psd / $DESIGN_HEIGHT_DECO * 100%);
  @return #{$per};
}

@function w_fvs_sp($design_width_psd, $DESIGN_WIDTH_DECO: 1093) {
  $per: calc($design_width_psd / $DESIGN_WIDTH_DECO * 100%);
  @return #{$per};
}

@function h_fvs_sp($design_height_psd, $DESIGN_HEIGHT_DECO: 872) {
  $per: calc($design_height_psd / $DESIGN_HEIGHT_DECO * 100%);
  @return #{$per};
}