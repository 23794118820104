// FONTS
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-Bold.woff2") format("woff2"),
    url("../fonts/DINPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --el-delay: 0s;
  --animate-delay: 0s;
  --animate-duration: 1.5s;
}

$COLOR_BLACK: #000000;
$COLOR_WHITE: #FFFFFF;
$COLOR_GRAY: #EFEFEF;
$COLOR_BLACK2: #211F20;
$COLOR_BLACK3: #333;
$COLOR_BASE: $COLOR_BLACK;

$DESIGN_WIDTH_PC: 1920;
$DESIGN_WIDTH_LAPTOP: 1366;
$DESIGN_WIDTH_LAPTOP_SM: 1200;
$DESIGN_WIDTH_TABLET_LG: 1100;
$DESIGN_WIDTH_TABLET: 980;
$DESIGN_WIDTH_SP: 750;
$CONTAINER_WIDTH: 980;
$WIDTH_SP: 767px;
$WIDTH_PC: 1920px;
$WIDTH_LAPTOP: 1366px;
$WIDTH_LAPTOP_SM: 1200px;
$WIDTH_TABLET_LG: 1100px;
$WIDTH_TABLET: 980px;
$WIDTH_INNER: 104rem;
$FZ_PC: 16;
$FZ_SP: 24;
$FONT_BASE: 10;
$LEADING: 1.5;
$OPACITY_HOVER: 0.75;
$TRANSITION_BASE: 0.3s cubic-bezier(0.66, 0, 0.34, 1);
$TRANSITION_FADEIN: 0.8s cubic-bezier(0.66, 0, 0.34, 1);
$TRANSITION_CB: cubic-bezier(0.65, 0.01, 0.3, 0.97);

// Z-index
$Z_PAGETOP: 90;
$Z_GNAV: 99;
$Z_HEADER: 100;
$Z_HEADER_MODAL: 101;
$Z_HDG: 102;
$Z_MODAL: 900;
$Z_LOADING: 1000;

// Font
$FONT_MINCHO: serif;
$FONT_GOTHIC: "Noto Sans JP",
"YuGothic",
"游ゴシック",
"Yu Gothic",
"游ゴシック Medium",
"Yu Gothic Medium",
"ヒラギノ⾓ゴ Pro",
"Hiragino Kaku Gothic Pro",
"MS PGothic",
sans-serif;
$FONT_EN: "DIN Pro",
sans-serif;
