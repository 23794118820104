.c-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  top: 0;
  left: 0;
  perspective: 1px;
  z-index: 10002;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  visibility: visible;
  transition: 0.5s;

  &__bg {
    background: $COLOR_WHITE;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "";
    pointer-events: all;
    transition: .2s;
    transition-delay: .8s;
  }

  &__content {
    padding-bottom: 5%;
    position: relative;
    z-index: 2;
  }

  &__logo {
    width: rem(195);
    width: rem(280);
    padding: 0;
    margin: 0;
    display: block;
    overflow: hidden;

    img {
      transition: 0.4s;
      transition-delay: 0.1s;
      display: block;
      transform: translate(0, 0);
    }
  }

  &__circle {
    margin: rem(40) auto 0;
    width: rem(40);
    height: rem(40);
    border: 4px solid rgba($COLOR_BLACK, 0.2);
    border-left: 4px solid #fff;
    border-radius: 50%;
    animation: loader-rotate 1s linear infinite;
    transition: .4s;
    transition-delay: 0s;
  }

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
    transition: $TRANSITION_BASE;
    transition-delay: 0.8s;

    .c-loading {
      &__logo {
        img {
          transform: translate(0, -105%) !important;
        }
      }

      &__circle {
        opacity: 0 !important;
        visibility: hidden !important;
      }

      &__bg {
        transform: translateY(-101%);
      }
    }
  }

  @include sp {
    &__logo {
      width: rem(400);
    }

    &__circle {
      margin: rem(60) auto 0;
      width: rem(60);
      height: rem(60);
      border-width: 3px;
      border-left-width: 3px;
    }
  }

  @include sp_lg {
    &__logo {
      width: rem(300);
    }

    &__circle {
      margin: rem(44) auto 0;
      width: rem(44);
      height: rem(44);
      border-width: 2px;
      border-left-width: 2px;
    }
  }
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeInSpinner {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}
