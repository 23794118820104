.cta {
  background: $COLOR_BLACK;
  color: $COLOR_WHITE;
  padding: rem(50) 0;

  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    flex-shrink: 0;
    @include fz(18, 30);
    font-weight: 700;
    width: rem(175);
    letter-spacing: 0.05em;
  }

  &__content {
    text-align: center;
    width: max-content;
  }

  &__tel {
    font-family: $FONT_EN;
    @include fz(25, 27);
    font-weight: 700;
    letter-spacing: 0.05em;
    margin-bottom: rem(17);
  }

  &__btn {
    a {
      @include fz(14, 21);
      font-weight: 700;
      letter-spacing: 0.03em;
      padding: rem(10) rem(20);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: rem(249);
      background: $COLOR_WHITE;
      color: $COLOR_BLACK;
      border-radius: rem(40);
    }
  }
}

@include laptop {
  .cta {
    background: $COLOR_BLACK;
    color: $COLOR_WHITE;
    padding: rem(50) 0;

    &__row {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__title {
      flex-shrink: 0;
      @include fz(18, 30);
      font-weight: 700;
      width: rem(175);
      letter-spacing: 0.05em;
    }

    &__content {
      text-align: center;
      width: max-content;
    }

    &__tel {
      font-family: $FONT_EN;
      @include fz(25, 27);
      font-weight: 700;
      letter-spacing: 0.05em;
      margin-bottom: rem(17);
    }

    &__btn {
      a {
        @include fz(14, 21);
        font-weight: 700;
        letter-spacing: 0.03em;
        padding: rem(10) rem(20);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: rem(249);
        background: $COLOR_WHITE;
        color: $COLOR_BLACK;
        border-radius: rem(40);
      }
    }
  }
}

@include sp {
  .cta {
    &__title {
      flex-shrink: 0;
      @include fz(28);
      width: rem(220);
    }

    &__tel {
      @include fz(38);
      margin-bottom: rem(25);
    }

    &__btn {
      a {
        @include fz(22, 42);
        padding: rem(10) rem(20);
        min-width: rem(380);
        border-radius: rem(80);
      }
    }
  }
}

@include sp_lg {
  .cta {
    &__title {
      @include fz(22);
      width: rem(180);
    }

    &__tel {
      @include fz(30);
      margin-bottom: rem(20);
    }

    &__btn {
      a {
        @include fz(18);
        min-width: rem(300);
      }
    }
  }
}
