@include sp {
  .p-service {
    .bg-deco {
      top: 0;
      left: 0;
      width: 100vw;

      li {
        &:nth-child(2) {
          width: 100vw;
          margin-left: 0;
          margin-top: rem(1580);
        }
      }
    }

    .fv-deco {
      top: rem(250);
      top: rem(200);
      width: rem(1093);
      left: rem(-47);
      transform: translateX(0);

      &__el {
        &.el-l01 {
          width: 75.8409px;
          width: w_fvs_sp(75.8409);
          top: 367px;
          top: h_fvs_sp(367);
          left: 272px;
          left: w_fvs_sp(272);
        }

        &.el-l02 {
          width: 124.0116px;
          width: w_fvs_sp(124.0116);
          top: 360px;
          top: h_fvs_sp(360);
          left: 345px;
          left: w_fvs_sp(345);
        }

        &.el-l03 {
          width: 12.2962px;
          width: w_fvs_sp(12.2962);
          top: 568px;
          top: h_fvs_sp(568);
          left: 322px;
          left: w_fvs_sp(322);
        }

        &.el-l04 {
          width: 75.8409px;
          width: w_fvs_sp(75.8409);
          top: 730px;
          top: h_fvs_sp(730);
          left: 272px;
          left: w_fvs_sp(272);
        }

        &.el-l05 {
          width: 19.5337px;
          width: w_fvs_sp(19.5337);
          top: 276px;
          top: h_fvs_sp(276);
          left: 300px;
          left: w_fvs_sp(300);
        }

        &.el-l06 {
          width: 92.2778px;
          width: w_fvs_sp(92.2778);
          top: 540px;
          top: h_fvs_sp(540);
          left: 195px;
          left: w_fvs_sp(195);
        }

        &.el-l07 {
          width: 113.0565px;
          width: w_fvs_sp(113.0565);
          top: 250px;
          top: h_fvs_sp(250);
          left: 140px;
          left: w_fvs_sp(140);
        }

        &.el-l08 {
          width: 88.0895px;
          width: w_fvs_sp(88.0895);
          bottom: 0px;
          bottom: h_fvs_sp(0);
          left: 96px;
          left: w_fvs_sp(96);
        }

        &.el-l09 {
          width: 156.169px;
          width: w_fvs_sp(156.169);
          top: 592px;
          top: h_fvs_sp(592);
          left: 0px;
          left: w_fvs_sp(0);
        }

        &.el-l10 {
          width: 284.8359px;
          width: w_fvs_sp(284.8359);
          top: 342px;
          top: h_fvs_sp(342);
          left: 1px;
          left: w_fvs_sp(1);
        }

        &.el-l11 {
          width: 108.4688px;
          width: w_fvs_sp(108.4688);
          top: 207px;
          top: h_fvs_sp(207);
          left: 21px;
          left: w_fvs_sp(21);
        }

        &.el-l12 {
          width: 27.827px;
          width: w_fvs_sp(27.827);
          top: 500px;
          top: h_fvs_sp(500);
          left: -100px;
          left: w_fvs_sp(-100);
          display: none;
        }

        &.el-l13 {
          width: 400.157px;
          width: w_fvs_sp(400.157);
          top: 602px;
          top: h_fvs_sp(602);
          left: -150px;
          left: w_fvs_sp(-150);
          display: none;
        }

        &.el-l14 {
          width: 271.6834px;
          width: w_fvs_sp(271.6834);
          top: 350px;
          top: h_fvs_sp(350);
          left: -200px;
          left: w_fvs_sp(-200);
          display: none;
        }

        &.el-l15 {
          width: 12.2962px;
          width: w_fvs_sp(12.2962);
          bottom: 0px;
          bottom: h_fvs_sp(0);
          left: 322px;
          left: w_fvs_sp(322);
          bottom: w_fvs_sp(0);
        }

        &.el-r01 {
          width: 58.2792px;
          width: w_fvs_sp(58.2792);
          top: 246px;
          top: h_fvs_sp(246);
          right: 570px;
          right: w_fvs_sp(570);
        }

        &.el-r02 {
          width: 9.4589px;
          width: w_fvs_sp(9.4589);
          top: 418px;
          top: h_fvs_sp(418);
          right: 553px;
          right: w_fvs_sp(553);
        }

        &.el-r03 {
          width: 9.4589px;
          width: w_fvs_sp(9.4589);
          top: 117px;
          top: h_fvs_sp(117);
          right: 350px;
          right: w_fvs_sp(350);
        }

        &.el-r04 {
          width: 12.2961px;
          width: w_fvs_sp(12.2961);
          top: 535px;
          top: h_fvs_sp(535);
          right: 535px;
          right: w_fvs_sp(535);
        }

        &.el-r05 {
          width: 50.3143px;
          width: w_fvs_sp(50.3143);
          top: 158px;
          top: h_fvs_sp(158);
          right: 461px;
          right: w_fvs_sp(461);
        }

        &.el-r06 {
          width: 12.2962px;
          width: w_fvs_sp(12.2962);
          top: 216px;
          top: h_fvs_sp(216);
          right: 452px;
          right: w_fvs_sp(452);
        }

        &.el-r07 {
          width: 113.0564px;
          width: w_fvs_sp(113.0564);
          top: 566px;
          top: h_fvs_sp(566);
          right: 418px;
          right: w_fvs_sp(418);
        }

        &.el-r08 {
          width: 120.3794px;
          width: w_fvs_sp(120.3794);
          top: 239px;
          top: h_fvs_sp(239);
          right: 317px;
          right: w_fvs_sp(317);
        }

        &.el-r09 {
          width: 121.9491px;
          width: w_fvs_sp(121.9491);
          top: 394px;
          top: h_fvs_sp(394);
          right: 329px;
          right: w_fvs_sp(329);
        }

        &.el-r10 {
          width: 75.8408px;
          width: w_fvs_sp(75.8408);
          top: 598px;
          top: h_fvs_sp(598);
          right: 316px;
          right: w_fvs_sp(316);
        }

        &.el-r11 {
          width: 122.445px;
          width: w_fvs_sp(122.445);
          bottom: 92px;
          bottom: h_fvs_sp(92);
          right: 256px;
          right: w_fvs_sp(256);
        }

        &.el-r12 {
          width: 284.8359px;
          width: w_fvs_sp(284.8359);
          top: 0px;
          top: h_fvs_sp(0);
          right: 0px;
          right: w_fvs_sp(0);
        }

        &.el-r13 {
          width: 27.827px;
          width: w_fvs_sp(27.827);
          top: 320px;
          top: h_fvs_sp(320);
          right: 237px;
          right: w_fvs_sp(237);
        }

        &.el-r14 {
          width: 12.2962px;
          width: w_fvs_sp(12.2962);
          top: 416px;
          top: h_fvs_sp(416);
          right: 260px;
          right: w_fvs_sp(260);
        }

        &.el-r15 {
          width: 26.0911px;
          width: w_fvs_sp(26.0911);
          top: 568px;
          top: h_fvs_sp(568);
          right: 266px;
          right: w_fvs_sp(266);
        }

        &.el-r16 {
          width: 12.2962px;
          width: w_fvs_sp(12.2962);
          bottom: 47px;
          bottom: h_fvs_sp(47);
          right: 257px;
          right: w_fvs_sp(257);
        }

        &.el-r17 {
          width: 124.3967px;
          width: w_fvs_sp(124.3967);
          top: 434px;
          top: h_fvs_sp(434);
          right: 130px;
          right: w_fvs_sp(130);
        }

        &.el-r18 {
          width: 208.5819px;
          width: w_fvs_sp(208.5819);
          top: 212px;
          top: h_fvs_sp(212);
          right: 0px;
          right: w_fvs_sp(0);
          display: none;
        }

        &.el-r19 {
          width: 176.7552px;
          width: w_fvs_sp(176.7552);
          top: 623px;
          top: h_fvs_sp(623);
          right: 50px;
          right: w_fvs_sp(50);
          display: none;
        }

        &.el-r20 {
          width: 122.445px;
          width: w_fvs_sp(122.445);
          bottom: 50px;
          bottom: h_fvs_sp(50);
          right: 22px;
          right: w_fvs_sp(22);
        }
      }
    }

    .fv {
      &-img {
        max-width: rem(539.3072);
        margin: rem(410) auto 0;
      }
    }

    .service-menu {
      padding-top: rem(100);

      &__list {
        flex-wrap: wrap;
      }

      &__ico {
        height: rem(140.84);
      }

      &__desc {
        margin-top: rem(50);
      }

      &__item {
        width: rem(326);
        max-width: 50%;
        padding: 0 rem(22);
        margin-top: rem(80);

        &:nth-child(1) {
          .service-menu__ico img {
            max-width: rem(105.7238);
          }
        }

        &:nth-child(2) {
          .service-menu__ico img {
            max-width: rem(181.299);
          }
        }

        &:nth-child(3) {
          .service-menu__ico img {
            max-width: rem(131.2388);
          }
        }

        &:nth-child(4) {
          .service-menu__ico img {
            max-width: rem(175.8871);
          }
        }
      }
    }

    .service-card {
      padding: rem(80) 0;

      &__desc {
        width: rem(616);

        &__inner {
          width: rem(480);
          padding: rem(80) rem(0) rem(90);
        }
      }

      &__title {
        @include fz(46, 60);
        margin-bottom: vw(20);
      }

      &__btn {
        margin-top: rem(45);

        a {
          @include fz(28, 41);
        }
      }

      &__unit {
        padding: rem(80) 0;

        &__inner {
          display: block;
        }

        &:nth-child(2n) {
          .service-card__desc {
            margin-left: auto;
          }
        }

        &:nth-child(1) {
          .service-card {
            &__desc {
              margin-top: rem(-172);
            }

            &__pic {
              margin-top: 0;
              margin-left: rem(87);
              margin-right: 0;
            }
          }
        }

        &:nth-child(2) {
          .service-card {
            &__desc {
              margin-top: rem(-136);
            }

            &__pic {
              margin-top: 0;
              padding-top: 0;
              margin-left: 0;
              margin-right: rem(60);
            }
          }
        }

        &:nth-child(3) {
          .service-card {
            &__desc {
              margin-top: rem(-333);
            }

            &__pic {
              margin-top: 0;
              margin-left: 0;
              margin-right: rem(-5);
            }
          }
        }

        &:nth-child(4) {
          .service-card {
            &__pic {
              margin-top: rem(0);
              margin-left: 0;
              margin-right: rem(80);
            }

            &__desc {
              margin-top: rem(-94);
            }
          }
        }
      }
    }

    .service-flow {
      padding-left: rem(10);
      padding-right: rem(20);

      &__inner {
        max-width: rem(870);
        margin: 0 auto;
      }

      &__title {
        @include fz(29, 42);
        padding: rem(20) rem(44);
        border-radius: rem(82);
      }

      &__copy {
        @include fz(22);
        padding: rem(30) rem(40) rem(80);
      }

      &__deco {
        li {
          &:nth-child(1) {
            width: rem(164.2774);
            left: rem(50);
            top: rem(-30);
          }

          &:nth-child(2) {
            width: rem(319.8406);
            top: rem(-75);
            right: rem(-52);
          }
        }
      }

      &__more {
        &-btn {
          display: none;
        }

        &-bg {
          padding-top: rem(330);
          padding-bottom: rem(235);
        }

        &-ctn {
          padding: 0 0 rem(60);
        }
      }

      &__item-last {
        display: block;
        margin-top: rem(-210);

        .service-flow {
          &__img {
            margin-right: rem(-50);
            margin-left: auto;
          }

          &__desc {
            padding-right: rem(0);
            padding-top: rem(60);
          }
        }
      }
    }
  }

  .s-service {
    &-headline {
      text-align: left;
      padding-left: rem(30);
      padding-right: 0;

      &__text {
        @include fz(68.59, 73);
        @include fz_min(68.59);

        &.txt-lg-sp {
          @include fz(78, 82);
          @include fz_min(78);
        }
      }
    }

    &-mv {
      &__inner {
        margin-top: vw(80);
      }
    }

    &-gallery {
      .splide {
        padding-bottom: rem(40);

        &__pagination {
          &__page {
            width: rem(86);
            height: rem(40);
            margin: 0 rem(15) 0 0;

            &::after {
              height: rem(6);
            }
          }
        }
      }
    }

    &-content {
      margin-top: rem(-30);
      padding-bottom: rem(200);
    }

    &-block {
      margin-top: rem(160);

      &__row {
        display: block;
      }

      &__img {
        width: rem(468);
        max-width: 80%;
        margin: rem(80) auto 0;
      }
    }

    &-price {
      &__row {
        margin: 0;
      }

      &__item {
        width: 100%;
        min-width: 0;

        &__inner {
          padding: rem(140) 0 0;
        }
      }

      &__hdg {
        margin-bottom: rem(30);
        gap: rem(15);

        .ico {
          min-height: 0;

          &-sz01 {
            img {
              width: rem(103);
            }
          }

          &-sz02 {
            img {
              width: rem(86.0609);
            }
          }

          &-sz03 {
            img {
              width: rem(87.9507);
            }
          }

          &-sz04 {
            img {
              width: rem(84.8087);
            }
          }

          &-sz05 {
            img {
              width: rem(86.5);
            }
          }

          &-sz06 {
            img {
              width: rem(82.5434);
            }
          }

          &-sz07 {
            img {
              width: rem(77.5);
            }
          }
        }

        .txt {
          @include fz(32, 42);
        }
      }

      &__data {
        @include fz(24, 32);

        dl {
          padding: rem(15) rem(30) rem(15) rem(22);
        }

        dd {
          @include fz(26, 32);
        }

        &--02 {
          @include fz(28);

          dd {
            @include fz(28);
          }
        }
      }

      &__note {
        margin-top: rem(20);
        padding-right: rem(30);
        font-size: rem(20);
      }
    }

    &-visit {
      max-width: rem(560);
      margin: rem(100) auto 0;

      &__hdg {}

      &__caption {
        bottom: rem(40);
        @include fz(30, 52);
        padding: rem(15) rem(50) rem(15) rem(30);
        min-width: rem(446);
        border-radius: 0 rem(82) rem(82) 0;
      }

      &__btn {
        margin-top: rem(80);
      }
    }

    &-web-about {
      padding-top: 0;

      &__main {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-right: rem(-40);
        margin-left: rem(-40);
      }

      &__pht {
        width: 100vw;
        margin-left: 0;
        margin-bottom: 0;
      }

      &__desc {
        width: rem(616);
        padding: rem(90) rem(70);
        margin-top: rem(-70);

        h3 {
          @include fz(46, 60);
          margin-bottom: rem(20);
        }

        p {
          letter-spacing: 0.03em;
        }
      }

      &__list {
        gap: rem(120);
        max-width: rem(556);
        margin: rem(120) auto 0;
      }

      &__item {
        width: 100%;
      }

      &__hdg {
        @include fz(30);
      }

      &__ico {
        display: block;
        width: rem(200);
        margin: rem(40) auto;
      }

      &__copy {
        height: rem(218);
        padding-bottom: rem(50);
        line-height: rem(42);
      }

      &__more {
        height: rem(134);

        .txt {
          @include fz(26, 50);
        }
      }
    }

    &-video {
      margin-top: 6.5rem;
    }

    &-features {
      padding: 2rem 5.5rem 0;

      &__list {
        gap: 8rem;
      }

      &__item {
        width: 100%;
      }

      &__hdg {
        font-size: 3rem;
        margin-bottom: 3rem;
      }
    }

    &-sns {
      padding: 2rem 0 6rem;

      &__list {
        justify-content: space-between;
      }

      &__item {
        max-width: 32%;
      }
    }
  }
}

@include sp_lg {
  .p-service {
    .bg-deco {
      max-height: rem(5700);
      overflow: hidden;

      li {
        &:nth-child(2) {
          width: 100vw;
          margin-left: 0;
          margin-top: rem(1000);
        }
      }
    }

    .fv-deco {
      top: rem(250);
      top: rem(100);
      left: rem(0);
      width: rem(960);
    }

    .fv {
      &-img {
        max-width: rem(500);
        margin: rem(340) auto 0;
      }
    }

    .service-menu {
      padding: rem(50) 7% 0;

      &__ico {
        height: rem(100);
      }

      &__desc {
        margin-top: rem(30);
      }

      &__item {
        width: calc(50% - #{vw(20)});
        max-width: calc(50% - #{vw(20)});
        padding: 0 rem(15);
        margin-top: rem(50);

        &:nth-child(1) {
          .service-menu__ico img {
            max-width: rem(70);
          }
        }

        &:nth-child(2) {
          .service-menu__ico img {
            max-width: rem(120);
          }
        }

        &:nth-child(3) {
          .service-menu__ico img {
            max-width: rem(86);
          }
        }

        &:nth-child(4) {
          .service-menu__ico img {
            max-width: rem(120);
          }
        }
      }
    }

    .service-card {
      padding: rem(40) 0;

      &__desc {
        width: rem(540);

        &__inner {
          width: rem(440);
          padding: rem(50) rem(0) rem(50);
        }
      }

      &__title {
        @include fz(36);
        margin-bottom: rem(16);
      }

      &__btn {
        margin-top: rem(30);

        a {
          @include fz(16);
        }
      }

      &__unit {
        padding: rem(60) 0;

        &:nth-child(1) {
          .service-card {
            &__desc {
              margin-top: rem(-130);
            }

            &__pic {
              margin-left: rem(200);
            }
          }
        }

        &:nth-child(2) {
          .service-card {
            &__desc {
              margin-top: rem(-110);
            }

            &__pic {
              margin-right: rem(200);
            }
          }
        }

        &:nth-child(3) {
          .service-card {
            &__desc {
              margin-top: rem(-160);
            }

            &__pic {
              margin-left: 12.5%;
              margin-right: 12.5%;
            }
          }
        }

        &:nth-child(4) {
          .service-card {
            &__pic {
              margin-right: rem(200);
            }

            &__desc {
              margin-top: rem(-100);
            }
          }
        }
      }
    }

    .service-flow {
      padding-top: rem(30);
      padding-left: rem(30);
      padding-right: rem(30);

      &__inner {
        max-width: rem(520);
      }

      &__title {
        @include fz(20);
        padding: rem(10) rem(30);
      }

      &__copy {
        @include fz(16);
        padding: rem(25) rem(30) rem(50);
      }

      &__deco {
        li {
          &:nth-child(1) {
            width: rem(120);
            left: rem(-110);
            top: rem(-80);
          }

          &:nth-child(2) {
            width: rem(230);
            top: rem(-100);
            right: rem(-110);
          }
        }
      }

      &__more {
        &-bg {
          padding-top: rem(190);
          padding-bottom: rem(200);
        }

        &-ctn {
          padding: 0 0 rem(40);
        }
      }

      &__item-last {
        margin-top: rem(-180);

        .service-flow {
          &__img {
            max-width: rem(520);
            margin-right: rem(0);
          }

          &__desc {
            padding-right: rem(0);
            padding-top: rem(60);
          }
        }
      }
    }
  }

  .s-service {
    &-headline {
      text-align: left;
      padding-left: rem(30);

      &__text {
        @include fz(47);

        &.txt-lg-sp {
          @include fz(52);
        }
      }
    }

    &-mv {
      padding-top: rem(140);

      &__inner {
        margin-top: vw(40);
      }
    }

    &-gallery {
      .splide {
        &__slide {
          width: rem(402);
          padding-right: rem(14);
        }

        &__pagination {
          &__page {
            width: rem(70);
            height: rem(5);
            margin: 0 rem(12) 0 0;
          }
        }
      }
    }

    &-content {
      padding-bottom: rem(140);
    }

    &-block {
      margin-top: rem(110);

      &__img {
        width: rem(330);
        margin: rem(60) auto 0;
      }
    }

    &-price {
      max-width: rem(480);
      margin: 0 auto;

      &__item {
        &__inner {
          padding: rem(110) 0 0;
        }
      }

      &__hdg {
        margin-bottom: rem(20);
        gap: rem(12);

        .ico {
          width: rem(50);
        }

        .txt {
          @include fz(22);
        }
      }

      &__data {
        @include fz(17);

        dl {
          padding: rem(12) rem(24) rem(12) rem(18);
        }

        dd {
          @include fz(19);
        }

        &--02 {
          @include fz(20);

          dd {
            @include fz(20);
          }
        }
      }

      &__note {
        margin-top: rem(10);
        padding-right: rem(24);
        font-size: rem(15);
      }
    }

    &-visit {
      max-width: rem(480);
      margin: rem(80) auto 0;

      &__caption {
        bottom: rem(30);
        @include fz(20);
        padding: rem(12) rem(30) rem(12) rem(20);
        min-width: rem(320);
        border-radius: 0 rem(44) rem(44) 0;
      }

      &__btn {
        margin-top: rem(60);
      }
    }

    &-web-about {
      &__desc {
        width: rem(540);
        padding: rem(70) rem(50);
        margin-top: rem(-70);

        h3 {
          @include fz(30);
          margin-bottom: rem(20);
        }

        p {
          @include fz(16);
        }
      }

      &__list {
        gap: rem(100);
        max-width: rem(440);
        margin: rem(100) auto 0;
      }

      &__hdg {
        @include fz(24);
      }

      &__ico {
        width: rem(140);
        margin: rem(24) auto;
      }

      &__copy {
        font-size: rem(16);
        height: rem(150);
        padding-bottom: rem(30);
        line-height: rem(30);
      }

      &__more {
        height: rem(90);

        .txt {
          @include fz(18, 30);
        }
      }
    }

    &-video {
      margin-top: rem(40);
    }

    &-features {
      padding: rem(20) 0 0;

      &__list {
        gap: rem(40) rem(40);
      }

      &__item {
        width: calc(50% - 2rem);
      }

      &__hdg {
        font-size: rem(20);
        margin-bottom: 2rem;
      }
    }

    &-sns {
      padding: rem(20) rem(20) rem(40);

      &__item {
        max-width: 28%;
      }
    }
  }
}
