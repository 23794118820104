html {
  font-size: 62.5%;

  @include sp() {
    font-size: 1.33333333vw; //10/750*100
  }
}

body {
  min-height: 100vh;
  color: $COLOR_BASE;
  font-family: $FONT_GOTHIC;
  @include fz(16, 28);
  font-weight: 500;
  overflow-wrap: break-word;
  overflow-x: hidden;
  letter-spacing: 0;

  &.is-fixed {
    overflow: hidden;
  }

  @include sp {
    @include fz(24, 42);
  }
}

.body-no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

#wrapper {
  position: relative;
  overflow: hidden;

  .bg-deco {
    position: absolute;
    top: -2px;
    left: 0;
    z-index: -1;
    width: rem(3170);
    pointer-events: none;

    @media screen and (min-width: 3170px) {
      width: 120%;
    }

    li {
      position: relative;

      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $COLOR_WHITE;
        opacity: 1;
        transition: 0.5s transform linear 0s, 0.1s opacity 0.5s;
      }

      &:nth-child(1) {
        width: rem(1449.7143);
      }

      &.is-show {
        &::after {
          transform: translate(0, 100%);
          opacity: 0;
        }
      }
    }

    @include laptop {
      width: vw_md(3170);
      left: vw_md(-280);
    }

    @include laptop_sm {
      left: vw_md(-120);
    }

    @include tablet_lg {
      width: vw_sm(3170);
      left: vw_sm(-430);
    }

    @include tablet {
      width: vw_sm(3170);
      left: vw_sm(-480);
    }

    @media screen and (max-width: 880px) {
      width: vw_sm(3170);
      left: vw_sm(0);

      li {
        &:nth-child(1) {
          width: 100vw;
        }
      }
    }
  }

  @include sp {
    .no-scroll & {
      overflow: hidden;
      height: 100dvh;
      height: calc(var(--vh, 1vh) * 100);
    }
  }
}

.inner {
  max-width: 104rem;
  margin: 0 auto;
  padding: 0 3rem;
}