.c-heading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: rem(200);
  margin-bottom: rem(55);
  --el-delay: 0.3s;

  &__headline {
    @include fz(68, 80);
    font-weight: 700;
  }

  &__sub {
    @include fz(20, 35);
    font-weight: 700;
    letter-spacing: 0.05em;
  }

  @include tablet {
    padding-top: vw_sm(180);
  }

  @include sp {
    padding-top: rem(260);
    padding-top: rem(210);
    margin-bottom: rem(55);

    &__headline {
      @include fz(68);
    }

    &__sub {
      @include fz(28);
    }
  }


  @include sp_lg {
    padding-top: rem(160);
    margin-bottom: rem(40);

    &__headline {
      @include fz(47);
    }

    &__sub {
      margin-top: rem(5);
      @include fz(19);
    }
  }
}

.c-heading-page-02 {
  font-family: $FONT_EN;
  display: flex;
  padding-top: rem(200);
  margin-bottom: rem(10);
  --el-delay: 0.3s;

  &__headline {
    @include fz(90, 90);
    font-weight: 700;
    letter-spacing: 0.1em;
  }

  @include tablet {
    padding-top: vw_sm(180);
  }

  @include sp {
    padding-top: rem(300);
    margin-bottom: rem(30);

    &__headline {
      @include fz(77);
    }
  }


  @include sp_lg {
    padding-top: rem(220);
    margin-bottom: rem(20);

    &__headline {
      @include fz(60);
    }
  }
}

.c-heading-lv2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: rem(55);

  &__headline {
    @include fz(46, 60);
    letter-spacing: 0.03em;
    font-weight: 700;
  }

  &__sub {
    @include fz(18, 31.5);
    font-weight: 400;
    letter-spacing: 0.03em;
  }

  @include sp {
    margin-bottom: rem(80);

    &__headline {
      @include fz(50);
    }

    &__sub {
      @include fz(26);
    }
  }

  @include sp_lg {
    margin-bottom: rem(60);

    &__headline {
      @include fz(36);
    }

    &__sub {
      @include fz(19);
    }
  }
}

.c-heading-lv3 {
  @include fz(30, 42);
  font-weight: 700;
  margin-bottom: rem(25);

  @include sp {
    @include fz(38, 52);
    margin-bottom: rem(40);
  }

  @include sp_lg {
    @include fz(26);
    margin-bottom: rem(25);
  }
}

.c-heading-lv4 {
  @include fz(17, 30);
  font-weight: 700;
  margin-bottom: rem(15);

  @include sp {
    @include fz(32, 42);
    margin-bottom: rem(40);
  }

  @include sp_lg {
    @include fz(22);
    margin-bottom: rem(30);
  }
}

.c-copy {
  @include fz(13, 22.75);
  font-weight: 400;
  color: $COLOR_BLACK3;
  text-align: justify;

  @include sp {
    @include fz(24, 42);
  }

  @include sp_lg {
    @include fz(16);
  }
}

.s-copy {
  @extend .c-copy;

  @include pc {
    @include fz(14);
  }
}

.c-link-inline {
  text-decoration: underline;

  @include pc {
    &:hover {
      text-decoration: none;
    }
  }
}
