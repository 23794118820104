html {
  // scroll-behavior: smooth;
  // scroll-padding-top: rem(100);

  // @include sp {
  //   scroll-padding-top: rem(60);
  // }
}

a {
  color: inherit;
  text-decoration: none;
  transition-duration: 0.3s;

  &:hover {
    text-decoration: none;

    @include pc() {
      opacity: $OPACITY_HOVER;
    }
  }

  &:focus {
    outline: none;
    text-decoration: none;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

figure {
  margin: 0;
  padding: 0;
  line-height: 0;

  img {
    width: 100%;
  }
}

input[type="submit"] {
  border: none;
  transition-duration: 0.2s;
}

table {
  border-spacing: inherit;
  border-collapse: collapse;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

@include pc {
  .sp {
    display: none !important;
  }

  a[href^=tel] {
    pointer-events: none;
    text-decoration: none;
    opacity: 1 !important;
    cursor: default !important;
  }
}

@include sp {
  .pc {
    display: none !important;
  }
}

.page-main {
  display: block;
  position: relative;
}

.js-anm-mask {
  position: relative;
  display: inline-flex;
  overflow: hidden;

  &::before {
    position: absolute;
    content: "";
    top: 5%;
    left: 0;
    width: $WIDTH_INNER;
    height: 92%;
    clip-path: inset(0 0 0 0);
    will-change: clip-path;
    z-index: 1;
    transition: 1s clip-path, 0.2s opacity 1s;

    @include sp {
      transition: 1s clip-path, 0.2s opacity 0.2s;
    }
  }

  &.is-show {
    &::before {
      clip-path: inset(0 0 0 100%);
      opacity: 0;
    }
  }
}

.el-delay {
  opacity: 0;
  visibility: hidden;
  transition: opacity $TRANSITION_CB 0.6s;

  .is-show & {
    opacity: 1;
    visibility: visible;
  }
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

html.lenis {
  &:not(.is-mobile) {
    &::-webkit-scrollbar {
      width: 1rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #333;
      border-radius: 1rem;
      cursor: grab;
    }
  }

  @media screen and (min-width: 1025px) {
    &::-webkit-scrollbar {
      width: 1rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #333;
      border-radius: 1rem;
      cursor: grab;
    }
  }
}

.inview-img {
  img {
    transform: scale(1.4, 1.4);
    transition: transform .6s cubic-bezier(.215, .61, .355, 1);
    transition-delay: .1s;
  }

  &.is-show img {
    transform: scale(1, 1);
  }
}

.fadeIn {
  opacity: 0;
  transition: $TRANSITION_FADEIN;

  &.is-show {
    opacity: 1;
  }
}

.anm01 {
  animation-name: flash;
}

.anm02 {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

.anm03 {
  animation-duration: calc(var(--animate-duration) * 0.75);
  animation-name: bounceIn;
}

.anm04 {
  animation-name: fadeOutDown;
}

.anm05 {
  animation-name: fadeOutUp;
}

.anm06 {
  --animate-duration: 2s;
  animation-name: fadeIn;
}

.anm07 {
  animation-name: rotate2;
  animation-duration: calc(var(--animate-duration) * 0.75);
  animation-timing-function: linear;
}

.anm08 {
  animation-timing-function: ease-in-out;
  animation-name: bounceOut;
}

.anm09 {
  animation-name: fadeOutLeft;
}

.anm10 {
  animation-name: slideRightUp;
  animation-timing-function: linear;
}

.anm11 {
  animation-name: slideLeftDown;
  animation-timing-function: linear;
}

.anm12 {
  animation-name: slideRightUpH;
  animation-timing-function: linear;
}

.anm13 {
  animation-name: slideLeftDownH;
  animation-timing-function: linear;
}

.anm14 {
  animation-name: zoomOut;
}

.anm15 {
  animation-name: zoomIn;
}

.anm01,
.anm02,
.anm03,
.anm04,
.anm05,
.anm06,
.anm07,
.anm08,
.anm09,
.anm10,
.anm11,
.anm12,
.anm13,
.anm14,
.anm15 {
  animation-delay: var(--animate-delay);

  &.delay-1 {
    --animate-delay: 0.4s;
  }

  &.delay-2 {
    --animate-delay: 0.8s;
  }

  &.delay-3 {
    --animate-delay: 1.2s;
  }

  &.js-inview {
    animation-duration: var(--animate-duration);
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
}

.js-spline-text {

  &.anm-textEn {
    overflow: hidden;
    white-space: nowrap;
    width: max-content;
    display: block;

    span {
      display: inline-block;
      transition: .8s cubic-bezier(.215, .61, .355, 1);
      transition-delay: .2s;
      transform: translate(0, 100%);

      .is-show & {
        transform: translate(0, 0);
      }
    }

    &:nth-child(1) {
      span {
        @for $i from 0 through 20 {
          &:nth-child(#{$i+1}) {
            transition-delay: calc(var(--el-delay) + 0.1s * $i) !important;
          }
        }
      }
    }

    &:nth-child(2) {
      span {
        @for $i from 0 through 20 {
          &:nth-child(#{$i+1}) {
            transition-delay: calc(var(--el-delay) + 0.2s + 0.1s * $i) !important;
          }
        }
      }
    }

    &:nth-child(3) {
      span {
        @for $i from 0 through 20 {
          &:nth-child(#{$i+1}) {
            transition-delay: calc(var(--el-delay) + 0.4s + 0.1s * $i) !important;
          }
        }
      }
    }
  }

  &.anm-text {
    overflow: hidden;
    white-space: nowrap;
    width: max-content;
    display: block;

    span {
      display: inline-block;
      transition: .8s cubic-bezier(.215, .61, .355, 1);
      transition: 0.3s ease .2s;
      opacity: 0;

      .is-show & {
        opacity: 1;
      }
    }

    &:nth-child(1) {
      span {
        @for $i from 0 through 20 {
          &:nth-child(#{$i+1}) {
            transition-delay: calc(var(--el-delay) + 0.15s + 0.1s * $i) !important;
          }
        }
      }
    }

    &:nth-child(2) {
      span {
        @for $i from 0 through 20 {
          &:nth-child(#{$i+1}) {
            transition-delay: calc(var(--el-delay) + 0.3s + 0.1s * $i) !important;
          }
        }
      }
    }

    &:nth-child(3) {
      span {
        @for $i from 0 through 20 {
          &:nth-child(#{$i+1}) {
            transition-delay: calc(var(--el-delay) + 0.45s + 0.1s * $i) !important;
          }
        }
      }
    }
  }
}