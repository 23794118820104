@include sp {
  .home-mv {
    padding-top: calc(#{vw(120)} + 6.44329897vw);

    &__inner {
      gap: rem(70);
      padding-left: 0;
      align-items: flex-start;
    }

    &__headline {
      @include fz(58, 92);

      .txt {
        padding: 0 rem(5);
      }
    }

    &__copy {
      @include fz(39, 52);
      padding-bottom: 0;
      letter-spacing: 0.1em;
      writing-mode: vertical-rl;
      white-space: nowrap;
      gap: rem(28);

      p {
        margin-bottom: rem(60);
        margin-bottom: vw_pc(45);
        padding: rem(30) rem(15);

        &::before {
          transform: skewX(0);
          transform: skewY(-15deg);
          clip-path: inset(0 0 100% 0);
        }
      }

      .js-spline-text.anm-textEn span {
        transform: translate(0, 0);
        opacity: 0;
      }

      &.is-show {
        p::before {
          clip-path: inset(0 0 0 0);
        }

        .js-spline-text.anm-textEn span {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
    }

    &__running-text {
      ul {
        li {
          @include fz(190);
        }
      }
    }
  }

  .home-intro {
    padding: rem(140) rem(30) rem(180);

    &__inner {
      max-width: 100%;
    }

    &__headline {
      @include fz(40, 70);
      margin-bottom: rem(50);
      margin-right: rem(-40);
    }

    &__copy {
      @include fz(24, 42);
      text-align: left;

      p {
        max-width: 100%;
      }
    }
  }

  .home-product {
    &__description {
      width: 100%;
      max-width: 100%;
      padding: rem(40) rem(50) rem(50);
      margin-top: 0;
    }

    &__title {
      @include fz(31, 54);
      margin-bottom: rem(10);
    }

    &__copy {
      @include fz(22, 38.5);
    }

    .splide-pagination {
      bottom: rem(20);
      @include fz(20, 24);
      @include fz(24, 24);

      span {
        padding: 0 rem(12);
      }
    }

    .splide__arrow {
      width: rem(60);
      height: rem(60);

      &::after {
        background-size: rem(34) rem(16);
      }

      &--prev {
        transform: translate(-12rem, 0);
      }

      &--next {
        transform: translate(12rem, 0);
      }
    }
  }

  .home-service {
    padding: rem(100) rem(30) rem(180);

    &__inner {
      max-width: 100%;
    }

    &__headline {
      @include fz(100, 120);
    }

    &__title {
      @include fz(27, 47.5);
      margin-bottom: rem(60);
    }

    &__copy {
      @include fz(24, 42);
    }

    &__list {
      margin-top: rem(100);
      margin-left: rem(0);
    }

    &__btn {
      margin: rem(100) 0 0;
    }
  }

  .home-vision {
    &__inner {
      max-width: 100%;
    }

    &__row {
      flex-direction: column;
      gap: rem(100);
    }

    &__img {
      width: 100%;

      .is-part {
        width: rem(330);
        width: calc(330 / 690 * 100%);
      }
    }

    &__description {
      padding: 0 rem(30);
    }

    &__headline {
      @include fz(44);
      margin-bottom: rem(50);
      margin-right: rem(-30);
    }

    &__copy {
      @include fz(24, 42);
      max-width: 100%;
    }

    &__btn {
      display: flex;
      justify-content: center;
      margin-top: rem(100);
    }
  }

  .home-headline-en {
    @include fz_min(90);
  }

  .home-movie {
    margin-top: rem(180);

    &__inner {
      margin: 0 -3rem;
    }

    &__headline {
      margin-left: rem(-8);
      margin-right: rem(-20);
    }

    &__video {
      top: rem(76);
      right: rem(-1);
      width: rem(638);
      aspect-ratio: 638/485;
    }

    &__description {
      width: rem(630);
      padding: rem(50) rem(30);
      margin: rem(180) 0 0;
    }

    &__title {
      @include fz(40, 60);
      margin-bottom: rem(40);
    }

    &__copy {
      @include fz(24, 42);
    }
  }

  .home-design {
    max-width: 100%;
    margin: rem(180) auto;

    &__row {
      display: flex;
      flex-direction: column;
      gap: rem(100);
    }

    &__description {
      padding: 0 rem(30);
    }

    &__copy {
      max-width: 100%;
      @include fz(24, 42);
    }
  }

  .home-meeting {
    &__inner {
      max-width: 100%;
    }

    &__row {
      display: flex;
      flex-direction: column;
      gap: rem(100);
    }

    &__img {
      width: calc(100% + #{rem(32)});
      margin-right: rem(-32);

      .is-part {
        &01 {
          width: rem(323);
          width: calc(323 / 722 * 100%);
        }

        &02 {
          width: rem(461);
          width: calc(461 / 722 * 100%);
        }
      }
    }

    &__description {
      padding: 0 rem(30);

      &__inner {
        width: 100%;
      }
    }

    &__title {
      @include fz(40, 60);
      margin-bottom: rem(50);
    }

    &__copy {
      max-width: 100%;
      @include fz(24, 42);
    }
  }

  .home-web {
    padding: rem(180) 0;

    &__inner {
      max-width: rem(1050);
    }

    &__product {
      margin-top: rem(-3);
    }

    &__lead {
      max-width: 100%;
      margin: rem(100) rem(30) 0;
      @include fz(24, 42);
    }

    &__btn {
      margin-top: rem(100);
      text-align: center;
    }

    &__list {
      padding-top: rem(180);
      max-width: rem(750);
      margin: 0 rem(-30);
      row-gap: rem(100);
      column-gap: rem(4);
    }

    &__item {
      width: calc(50% - 0.2rem);
    }

    &-product {
      &__thumbnail {
        picture {
          aspect-ratio: 374/374;
        }
      }

      &__copy {
        @include fz(22, 38.5);
        padding: 0 rem(26);
      }

      &__btn {
        margin-top: rem(40);

        .c-btn {
          min-width: 100%;
          @include fz(22, 34);
          padding: rem(15) rem(36) rem(15) rem(45);

          &__txt {
            padding-right: rem(50);

            &::after {
              width: rem(47);
              height: rem(12);
            }
          }
        }
      }
    }
  }
}

@include sp_lg {
  .home-mv {
    padding-top: calc(#{vw(100)} + 2vw);
    height: auto;

    &__inner {
      gap: rem(50);
      padding-bottom: vw(40);
    }

    &__headline {
      @include fz_min_h_sp(64);

      .txt {
        padding: 0 rem(5);
      }
    }

    &__copy {
      @include fz_min_h_sp(42);
      gap: rem(20);

      p {
        margin-bottom: 0;
        padding: rem(25) rem(12) rem(15);
      }
    }

    &__running-text {
      ul {
        li {
          @include fz(100);
        }
      }
    }
  }

  .home-intro {
    padding: rem(100) rem(20) rem(140);

    &__headline {
      @include fz(27);
      margin-bottom: rem(25);
      margin-right: rem(-30);
    }

    &__copy {
      @include fz(16);
    }
  }

  .home-product {
    &__description {
      padding: rem(25) rem(30) rem(30);
    }

    &__title {
      @include fz(22);
    }

    &__copy {
      @include fz(16);
    }

    &__item {
      width: rem(400);
    }

    .swiper-pagination {
      font-size: rem(18);
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: rem(54);
      height: rem(54);
    }
  }

  .home-service {
    padding: rem(100) rem(20) rem(140);

    &__headline {
      @include fz(60);
    }

    &__title {
      @include fz(20);
      margin-bottom: rem(30);
    }

    &__copy {
      @include fz(16);
    }

    &__list {
      margin: rem(50) 7% 0;
    }

    &__btn {
      margin: rem(50) 0 0;
    }
  }

  .home-vision {
    &__row {
      gap: rem(50);
    }

    &__img {
      max-width: 94%;
    }

    &__description {
      padding: 0 rem(20);
    }

    &__headline {
      @include fz(27);
      margin-bottom: rem(25);
    }

    &__copy {
      @include fz(16);
    }

    &__btn {
      margin-top: rem(50);
    }
  }

  .home-headline-en {
    @include fz_min(90);
    letter-spacing: 0;
  }

  .home-movie {
    margin-top: rem(140);

    &__inner {
      margin: 0 -3rem;
    }

    &__headline {
      margin-left: rem(-8);
      margin-right: rem(-20);
    }

    &__video {
      top: rem(80);
    }

    &__description {
      width: rem(620);
      margin: rem(140) 0 0;
      padding: rem(40) rem(30);
    }

    &__title {
      @include fz(27);
      margin-bottom: rem(25);
    }

    &__copy {
      @include fz(16);
    }
  }

  .home-design {
    margin: rem(140) auto;

    &__row {
      gap: rem(50);
    }

    &__img {
      max-width: rem(360);
    }

    &__description {
      padding: 0 rem(20);
    }

    &__copy {
      @include fz(16);
    }
  }

  .home-meeting {
    &__row {
      gap: rem(50);
    }

    &__description {
      padding: 0 rem(20);
    }

    &__img {
      max-width: 94%;
      margin: 0 auto;
    }

    &__title {
      @include fz(27);
      margin-bottom: rem(25);
    }

    &__copy {
      @include fz(16);
    }
  }

  .home-web {
    padding: rem(140) 0;

    &__inner {
      max-width: rem(1000);
    }

    &__product {
      margin-top: rem(-6);

      li {
        height: rem(120);
      }
    }

    &__lead {
      margin: rem(50) rem(20) 0;
      @include fz(16);
    }

    &__btn {
      margin-top: rem(50);
    }

    &__list {
      padding-top: rem(140);
      max-width: calc(100% + 2rem);
      margin: 0 -1rem;
      row-gap: rem(60);
      column-gap: rem(20);
    }

    &__item {
      width: calc(50% - 1rem);
    }

    &-product {
      &__thumbnail {
        margin-bottom: rem(25);
      }

      &__copy {
        @include fz(16);
        padding: 0 rem(10);
      }

      &__btn {
        margin-top: rem(25);

        .c-btn {
          @include fz(18);
          padding: rem(15) rem(35) rem(15) rem(40);

          &__txt {
            padding-right: rem(55);

            &::after {
              width: rem(40);
              height: rem(10.5);
            }
          }
        }
      }
    }
  }
}
